/*
Template: Techex - Information & Technology HTML Template
Author: RRDevs
Author URI: https://themeforest.net/user/rrdevs
Description: Information & Technology HTML Template
Version: 3.0
*/
@import url("https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&amp;display=swap");
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html {
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

html, body, div, span, img,
ol, ul, li, a, p, h1, h2, h3,
h4, h5, h6, option, legend, label,
table, th, td, tr, article, aside,
caption, figure, footer, header, hgroup,
mark, nav, section, time, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

img {
  width: 100%;
}

a:hover, a:focus, a:active {
  text-decoration: none;
  outline: none !important;
  border: 0;
}

input:focus {
  outline: none;
}

input::-moz-focus-inner {
  border: 0;
  display: none;
}

input::-webkit-focus-inner {
  border: 0;
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

::-webkit-input-placeholder {
  color: #666;
  opacity: 1; /* Firefox */
}

::-moz-placeholder {
  color: #666;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  color: #666;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  color: #666;
  opacity: 1; /* Firefox */
}

::placeholder {
  color: #666;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #666;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #666;
}

table {
  border-collapse: collapse;
}

html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

ul, li {
  margin: 0;
  padding: 0;
}

figure {
  margin: 0px;
}

form {
  position: relative;
}

.form-control:focus {
  outline: none !important;
  border: none !important;
  -webkit-box-shadow: 0 0 0;
          box-shadow: 0 0 0;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  line-height: inherit;
}

h1:hover a, h2:hover a, h3:hover a, h4:hover a, h5:hover a, h6:hover a {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
}

hr {
  margin: 0;
  padding: 0;
  border-color: #000;
}

ul {
  list-style-type: none;
}

li {
  list-style: none;
  position: relative;
}

a,
button {
  position: relative;
}
a:hover, a:focus,
button:hover,
button:focus {
  outline: 0;
  text-decoration: none;
}

input, textarea, button {
  border: 0px;
}

.container {
  width: 100%;
}
@media (min-width: 1300px) {
  .container {
    max-width: 1200px;
  }
}

/* ------------------------------------------------------------------
    Template All Variables Defined - Value Change with Your's Need
 -------------------------------------------------------------------- */
@font-face {
  font-family: "Circular Std";
  src: url("../fonts/CircularStd-MediumItalic.eot");
  src: local("Circular Std Medium Italic"), local("CircularStd-MediumItalic"), url("../fonts/CircularStd-MediumItalicd41d.html?#iefix") format("embedded-open_type"), url("../fonts/CircularStd-MediumItalic.woff2") format("woff2"), url("../fonts/CircularStd-MediumItalic.woff") format("woff"), url("../fonts/CircularStd-MediumItalic.html") format("true_type");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Circular Std";
  src: url("../fonts/CircularStd-Black.eot");
  src: local("Circular Std Black"), local("CircularStd-Black"), url("../fonts/CircularStd-Blackd41d.html?#iefix") format("embedded-opentype"), url("../fonts/CircularStd-Black.woff2") format("woff2"), url("../fonts/CircularStd-Black.woff") format("woff"), url("../fonts/CircularStd-Black.html") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Circular Std";
  src: url("../fonts/CircularStd-Medium.html");
  src: local("Circular Std Medium"), local("CircularStd-Medium"), url("../fonts/CircularStd-Mediumd41d.html?#iefix") format("embedded-opentype"), url("../fonts/CircularStd-Medium.woff2") format("woff2"), url("../fonts/CircularStd-Medium-2.html") format("woff"), url("../fonts/CircularStd-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Circular Std";
  src: url("../fonts/CircularStd-Bold.eot");
  src: local("Circular Std Bold"), local("CircularStd-Bold"), url("../fonts/CircularStd-Boldd41d.eot?#iefix") format("embedded-opentype"), url("../fonts/CircularStd-Bold.woff2") format("woff2"), url("../fonts/CircularStd-Bold.woff") format("woff"), url("../fonts/CircularStd-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Circular Std";
  src: url("../fonts/CircularStd-BlackItalic.eot");
  src: local("Circular Std Black Italic"), local("CircularStd-BlackItalic"), url("../fonts/CircularStd-BlackItalicd41d.eot?#iefix") format("embedded-opentype"), url("../fonts/CircularStd-BlackItalic.woff2") format("woff2"), url("../fonts/CircularStd-BlackItalic.html") format("woff"), url("../fonts/CircularStd-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Circular Std Book";
  src: url("../fonts/CircularStd-BookItalic.eot");
  src: local("Circular Std Book Italic"), local("CircularStd-BookItalic"), url("../fonts/CircularStd-BookItalicd41d.html?#iefix") format("embedded-opentype"), url("../fonts/CircularStd-BookItalic.woff2") format("woff2"), url("../fonts/CircularStd-BookItalic.woff") format("woff"), url("../fonts/CircularStd-BookItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Circular Std";
  src: url("../fonts/CircularStd-BoldItalic.html");
  src: local("Circular Std Bold Italic"), local("CircularStd-BoldItalic"), url("../fonts/CircularStd-BoldItalicd41d.html?#iefix") format("embedded-opentype"), url("../fonts/CircularStd-BoldItalic.woff2") format("woff2"), url("../fonts/CircularStd-BoldItalic.woff") format("woff"), url("../fonts/CircularStd-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Circular Std Book";
  src: url("../fonts/CircularStd-Book.eot");
  src: local("Circular Std Book"), local("CircularStd-Book"), url("../fonts/CircularStd-Bookd41d.eot?#iefix") format("embedded-opentype"), url("../fonts/CircularStd-Book.woff2") format("woff2"), url("../fonts/CircularStd-Book.woff") format("woff"), url("../fonts/CircularStd-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Nunito-Black";
  src: local("Nunito-Black"), local("Nunito-Black"), url("../fonts/Nunito-Black.woff2") format("woff2"), url("../fonts/Nunito-Black.woff") format("woff"), url("../fonts/Nunito-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Nunito-Bold";
  src: local("Nunito-Bold"), local("Nunito-Bold"), url("../fonts/Nunito-Bold.woff2") format("woff2"), url("../fonts/Nunito-Bold.html") format("woff"), url("../fonts/Nunito-Bold-2.html") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Nunito-SemiBold";
  src: local("Nunito-SemiBold"), local("Nunito-SemiBold"), url("../fonts/Nunito-SemiBold.woff2") format("woff2"), url("../fonts/Nunito-SemiBold.woff") format("woff"), url("../fonts/Nunito-SemiBold.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Nunito-Regular";
  src: local("Nunito-Regular"), local("Nunito-Regular"), url("../fonts/Nunito-Regular.woff2") format("woff2"), url("../fonts/Nunito-Regular.woff") format("woff"), url("../fonts/Nunito-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Nunito-Medium";
  src: local("Nunito-Medium"), local("Nunito-Medium"), url("../fonts/Nunito-Medium.html") format("woff2"), url("../fonts/Nunito-Medium.woff") format("woff"), url("../fonts/Nunito-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
/* --------------------------------------------
    Template Default Fonts & Fonts Styles
 ---------------------------------------------- */
body {
  font-family: "Circular Std", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  color: #696969;
  background-color: #fff;
  -webkit-transition: all 0.4s ease-in-out !important;
  transition: all 0.4s ease-in-out !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Circular Std", sans-serif;
  margin: 0px;
  font-weight: 700;
  color: #211e3b;
}

h1 {
  font-size: 42px;
  line-height: 55px;
  letter-spacing: -3px;
}
@media (max-width: 767px) {
  h1 {
    font-size: 2rem;
    line-height: 3rem;
  }
}
h1.fs-lg {
  font-size: 150px;
  line-height: 80%;
}
@media (min-width: 767px) and (max-width: 991px) {
  h1.fs-lg {
    font-size: 42px;
  }
}

h2 {
  font-size: 36px;
  line-height: 130%;
  letter-spacing: -3px;
}

h3 {
  font-size: 22px;
  line-height: 30px;
}

h4 {
  font-size: 20px;
  line-height: 28px;
}

h5 {
  font-size: 18px;
  line-height: 26px;
}

h6 {
  font-size: 16px;
  line-height: 24px;
}

a {
  text-decoration: none;
  font-weight: 600;
  outline: none !important;
  cursor: pointer;
  font-size: 17px;
  line-height: 32px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  color: #211e3b;
}

p {
  margin: 0px;
}

.coverbg {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover !important;
}

.mx-auto {
  margin: 0 auto;
}

.align-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.align-center:before, .align-center:after {
  content: none;
}

.align-center-h {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.align-center-h:before, .align-center-h:after {
  content: none;
}

.align-center-v {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.align-center-v:before, .align-center-v:after {
  content: none;
}

.align-center-v-b {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.align-center-v-b:before, .align-center-v-b:after {
  content: none;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.justify-content-center:before, .justify-content-center:after {
  content: none;
}

.eq-height {
  display: -webkit-box;
  display: -moz-flexbox;
  display: -ms-flexbox;
  display: -o-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}
.eq-height:before, .eq-height:after {
  content: none;
}

.body_7 .container {
  max-width: 1320px;
  margin: auto;
}

/* ----------------------------------
    Template Helps Classes - Styles
 ------------------------------------ */
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white a {
  color: #fff !important;
}

.mlm-30 {
  margin-left: -30px;
}

/* padding left */
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-65 {
  padding-left: 65px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-75 {
  padding-left: 75px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-115 {
  padding-left: 115px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-125 {
  padding-left: 125px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-150 {
  padding-left: 150px;
}

/* padding right */
.pr-5 {
  padding-right: 5px;
}

.pr-5i {
  padding-right: 5px !important;
}
@media (max-width: 767px) {
  .pr-5i {
    padding-right: 15px !important;
  }
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-75 {
  padding-right: 75px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-115 {
  padding-right: 115px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-125 {
  padding-right: 125px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-150 {
  padding-right: 150px;
}

/* padding top */
.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-150 {
  padding-top: 150px;
}

/* padding bottom */
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-150 {
  padding-bottom: 150px;
}

/* margin left */
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-115 {
  margin-left: 115px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-125 {
  margin-left: 125px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-150 {
  margin-left: 150px;
}

/* margin right */
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-75 {
  margin-right: 75px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-115 {
  margin-right: 115px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-125 {
  margin-right: 125px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-150 {
  margin-right: 150px;
}

/* margin top */
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-150 {
  margin-top: 150px;
}

/* margin bottom */
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mmb10 {
  margin-bottom: -10px;
}

.mtm-30 {
  margin-top: -30px;
}

.mbm-30 {
  margin-bottom: -30px;
}

.mtm-40 {
  margin-bottom: -40px;
}

@media (max-width: 767px) {
  .mpt-50 {
    padding-top: 50px !important;
  }
}

/* background */
.bg-cover {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
}

.bg-center {
  background-position: center;
}

.bg-contain {
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  background-position: left;
}

.bg-right {
  background-repeat: no-repeat;
  background-position: right;
}

.bg-left {
  background-repeat: no-repeat;
  background-position: left;
}

.bg-bottom {
  background-repeat: no-repeat;
  background-position: bottom;
}

.bg-top {
  background-repeat: no-repeat;
  background-position: top;
}

.bg-100 {
  background-size: 100% 100%;
}

.mmb-100 {
  margin-bottom: -100px;
}

.mmb-80 {
  margin-bottom: -80px;
}

.lr-9 {
  margin-left: -9px;
  margin-right: -9px;
}
.lr-9 [class*=col] {
  padding-left: 9px;
  padding-right: 9px;
}

.triangle {
  width: 0;
  height: 0;
}
.triangle.triangle-top-left {
  border-top: 100px solid #32557f;
  border-right: 100px solid transparent;
}
.triangle.triangle-top-right {
  border-top: 100px solid #32557f;
  border-left: 100px solid transparent;
}
.triangle.triangle-bottom-left {
  border-bottom: 100px solid #32557f;
  border-right: 100px solid transparent;
}
.triangle.triangle-bottom-right {
  border-bottom: 100px solid #32557f;
  border-left: 100px solid transparent;
}

.bg-overlay {
  position: relative;
  z-index: 1;
}
.bg-overlay::before {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background-color: #000;
  z-index: -1;
}

.fix {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.shape {
  position: absolute;
}
@media (max-width: 1191px) {
  .shape {
    display: none;
    opacity: 0;
  }
}

.navbar-brand {
  padding: 0;
  margin-right: 0;
}

#scrollUp {
  background-color: #8A038C;
  border-radius: 50%;
  bottom: 20px;
  color: #ffffff;
  font-size: 22px;
  height: 40px;
  line-height: 44px;
  right: 20px;
  text-align: center;
  width: 40px;
  -webkit-box-shadow: 0px 5px 15px 0px rgba(1, 29, 80, 0.1);
          box-shadow: 0px 5px 15px 0px rgba(1, 29, 80, 0.1);
  font-weight: 400;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
#scrollUp:hover {
  background-color: #211e3b;
}

@media only screen and (max-width: 991px) {
  #scrollUp {
    font-size: 15px;
    height: 30px;
    line-height: 34px;
    right: 30px;
    width: 30px;
  }
}
button {
  border: none;
}

.clear-fix {
  overflow: hidden;
}

section {
  position: relative;
  z-index: 1;
}
section.pt-235 {
  padding-top: 225px;
}

@-webkit-keyframes sticky {
  0% {
    opacity: 0;
    margin-top: -120px;
  }
  50% {
    opacity: 0;
    margin-top: -60px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes sticky {
  0% {
    opacity: 0;
    margin-top: -120px;
  }
  50% {
    opacity: 0;
    margin-top: -60px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
.equal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.equal > [class*=col-] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media (max-width: 414px) {
  .d-none-mobile {
    display: none !important;
  }
}

.overlay {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 40%;
  opacity: 0;
  visibility: hidden;
  cursor: url("../img/icon/close-icon.svg") 15 15, auto;
}
.overlay.active {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background-color: #000;
  z-index: 99;
  opacity: 0.5;
  visibility: visible;
}

.dark-bg {
  background-color: #000;
}
.dark-bg h1, .dark-bg h2, .dark-bg h3, .dark-bg h4, .dark-bg h5, .dark-bg h6, .dark-bg a {
  color: #FFF;
}
.dark-bg p {
  color: #cacaca;
}

.no-padding .col-12 {
  padding: 0;
}

img.alignright {
  float: right;
  margin: 0 0 1em 1em;
}

img.alignleft {
  float: left;
  margin: 0 1em 1em 0;
}

img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

a img.alignright {
  float: right;
  margin: 0 0 1em 1em;
}

a img.alignleft {
  float: left;
  margin: 0 1em 1em 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@-webkit-keyframes left-to-right {
  0% {
    background-position: left center;
  }
  100% {
    background-position: right center;
  }
}

@keyframes left-to-right {
  0% {
    background-position: left center;
  }
  100% {
    background-position: right center;
  }
}
@-webkit-keyframes right-to-left {
  0% {
    background-position: right center;
  }
  100% {
    background-position: left center;
  }
}
@keyframes right-to-left {
  0% {
    background-position: right center;
  }
  100% {
    background-position: left center;
  }
}
@-webkit-keyframes left-to-center {
  0% {
    background-position: left center;
  }
  100% {
    background-position: center center;
  }
}
@keyframes left-to-center {
  0% {
    background-position: left center;
  }
  100% {
    background-position: center center;
  }
}
@-webkit-keyframes right-to-center {
  0% {
    background-position: right center;
  }
  100% {
    background-position: center center;
  }
}
@keyframes right-to-center {
  0% {
    background-position: right center;
  }
  100% {
    background-position: center center;
  }
}
@-webkit-keyframes top-to-bottom {
  0% {
    background-position: center top;
  }
  100% {
    background-position: center bottom;
  }
}
@keyframes top-to-bottom {
  0% {
    background-position: center top;
  }
  100% {
    background-position: center bottom;
  }
}
@-webkit-keyframes top-to-center {
  0% {
    background-position: center top;
  }
  100% {
    background-position: center center;
  }
}
@keyframes top-to-center {
  0% {
    background-position: center top;
  }
  100% {
    background-position: center center;
  }
}
@-webkit-keyframes bottom-to-top {
  0% {
    background-position: center bottom;
  }
  100% {
    background-position: center top;
  }
}
@keyframes bottom-to-top {
  0% {
    background-position: center bottom;
  }
  100% {
    background-position: center top;
  }
}
@-webkit-keyframes bottom-to-center {
  0% {
    background-position: center bottom;
  }
  100% {
    background-position: center center;
  }
}
@keyframes bottom-to-center {
  0% {
    background-position: center bottom;
  }
  100% {
    background-position: center center;
  }
}
@-webkit-keyframes top-left-to-bottom-right {
  0% {
    background-position: left top;
  }
  100% {
    background-position: right bottom;
  }
}
@keyframes top-left-to-bottom-right {
  0% {
    background-position: left top;
  }
  100% {
    background-position: right bottom;
  }
}
@-webkit-keyframes top-left-to-center {
  0% {
    background-position: left top;
  }
  100% {
    background-position: center center;
  }
}
@keyframes top-left-to-center {
  0% {
    background-position: left top;
  }
  100% {
    background-position: center center;
  }
}
@-webkit-keyframes top-right-to-bottom-left {
  0% {
    background-position: right top;
  }
  100% {
    background-position: left bottom;
  }
}
@keyframes top-right-to-bottom-left {
  0% {
    background-position: right top;
  }
  100% {
    background-position: left bottom;
  }
}
@-webkit-keyframes top-right-to-center {
  0% {
    background-position: right top;
  }
  100% {
    background-position: center center;
  }
}
@keyframes top-right-to-center {
  0% {
    background-position: right top;
  }
  100% {
    background-position: center center;
  }
}
@-webkit-keyframes bottom-left-to-top-right {
  0% {
    background-position: left bottom;
  }
  100% {
    background-position: right top;
  }
}
@keyframes bottom-left-to-top-right {
  0% {
    background-position: left bottom;
  }
  100% {
    background-position: right top;
  }
}
@-webkit-keyframes bottom-left-to-center {
  0% {
    background-position: left bottom;
  }
  100% {
    background-position: center center;
  }
}
@keyframes bottom-left-to-center {
  0% {
    background-position: left bottom;
  }
  100% {
    background-position: center center;
  }
}
@-webkit-keyframes bottom-right-to-top-left {
  0% {
    background-position: right bottom;
  }
  100% {
    background-position: left top;
  }
}
@keyframes bottom-right-to-top-left {
  0% {
    background-position: right bottom;
  }
  100% {
    background-position: left top;
  }
}
@-webkit-keyframes bottom-right-to-center {
  0% {
    background-position: right bottom;
  }
  100% {
    background-position: center center;
  }
}
@keyframes bottom-right-to-center {
  0% {
    background-position: right bottom;
  }
  100% {
    background-position: center center;
  }
}
@-webkit-keyframes zoomIn {
  0% {
    background-position: right center;
  }
  100% {
    background-position: left center;
  }
}
@keyframes zoomIn {
  0% {
    background-position: right center;
  }
  100% {
    background-position: left center;
  }
}
.animation_left-to-right {
  background-position: left center;
}
.active .animation_left-to-right {
  -webkit-animation: left-to-right 5s ease 0s 1 normal forwards running;
          animation: left-to-right 5s ease 0s 1 normal forwards running;
}

.animation_right-to-left {
  background-position: right center;
}
.active .animation_right-to-left {
  -webkit-animation: right-to-left 5s ease 0s 1 normal forwards running;
          animation: right-to-left 5s ease 0s 1 normal forwards running;
}

.animation_left-to-center {
  background-position: left center;
}
.active .animation_left-to-center {
  -webkit-animation: left-to-center 5s ease 0s 1 normal forwards running;
          animation: left-to-center 5s ease 0s 1 normal forwards running;
}

.animation_right-to-center {
  background-position: right center;
}
.active .animation_right-to-center {
  -webkit-animation: right-to-center 5s ease 0s 1 normal forwards running;
          animation: right-to-center 5s ease 0s 1 normal forwards running;
}

.animation_top-to-bottom {
  background-position: center top;
}
.active .animation_top-to-bottom {
  -webkit-animation: top-to-bottom 5s ease 0s 1 normal forwards running;
          animation: top-to-bottom 5s ease 0s 1 normal forwards running;
}

.animation_top-to-center {
  background-position: center top;
}
.active .animation_top-to-center {
  -webkit-animation: top-to-center 5s ease 0s 1 normal forwards running;
          animation: top-to-center 5s ease 0s 1 normal forwards running;
}

.animation_bottom-to-top {
  background-position: center bottom;
}
.active .animation_bottom-to-top {
  -webkit-animation: bottom-to-top 5s ease 0s 1 normal forwards running;
          animation: bottom-to-top 5s ease 0s 1 normal forwards running;
}

.animation_bottom-to-center {
  background-position: center bottom;
}
.active .animation_bottom-to-center {
  -webkit-animation: bottom-to-center 5s ease 0s 1 normal forwards running;
          animation: bottom-to-center 5s ease 0s 1 normal forwards running;
}

.animation_top-left-to-bottom-right {
  background-position: left top;
}
.active .animation_top-left-to-bottom-right {
  -webkit-animation: top-left-to-bottom-right 5s ease 0s 1 normal forwards running;
          animation: top-left-to-bottom-right 5s ease 0s 1 normal forwards running;
}

.animation_top-left-to-center {
  background-position: left top;
}
.active .animation_top-left-to-center {
  -webkit-animation: top-left-to-center 5s ease 0s 1 normal forwards running;
          animation: top-left-to-center 5s ease 0s 1 normal forwards running;
}

.animation_top-right-to-bottom-left {
  background-position: right top;
}
.active .animation_top-right-to-bottom-left {
  -webkit-animation: top-right-to-bottom-left 5s ease 0s 1 normal forwards running;
          animation: top-right-to-bottom-left 5s ease 0s 1 normal forwards running;
}

.animation_top-right-to-center {
  background-position: right top;
}
.active .animation_top-right-to-center {
  -webkit-animation: top-right-to-center 5s ease 0s 1 normal forwards running;
          animation: top-right-to-center 5s ease 0s 1 normal forwards running;
}

.animation_bottom-left-to-top-right {
  background-position: left bottom;
}
.active .animation_bottom-left-to-top-right {
  -webkit-animation: bottom-left-to-top-right 5s ease 0s 1 normal forwards running;
          animation: bottom-left-to-top-right 5s ease 0s 1 normal forwards running;
}

.animation_bottom-left-to-center {
  background-position: left bottom;
}
.active .animation_bottom-left-to-center {
  -webkit-animation: bottom-left-to-center 5s ease 0s 1 normal forwards running;
          animation: bottom-left-to-center 5s ease 0s 1 normal forwards running;
}

.animation_bottom-right-to-top-left {
  background-position: right bottom;
}
.active .animation_bottom-right-to-top-left {
  -webkit-animation: bottom-right-to-top-left 5s ease 0s 1 normal forwards running;
          animation: bottom-right-to-top-left 5s ease 0s 1 normal forwards running;
}

.animation_bottom-right-to-center {
  background-position: right bottom;
}
.active .animation_bottom-right-to-center {
  -webkit-animation: bottom-right-to-center 5s ease 0s 1 normal forwards running;
          animation: bottom-right-to-center 5s ease 0s 1 normal forwards running;
}

/* Alignments
--------------------------------------------- */
.alignleft {
  /*rtl:ignore*/
  float: left;
  /*rtl:ignore*/
  margin-right: 1.5em;
  margin-bottom: 1.5em;
}

.alignright {
  /*rtl:ignore*/
  float: right;
  /*rtl:ignore*/
  margin-left: 1.5em;
  margin-bottom: 1.5em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5em;
}

@-webkit-keyframes playVideo {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
  }
  40% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  80% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes playVideo {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
  }
  40% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  80% {
    -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@-webkit-keyframes playVideo1 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(8, 106, 215, 0.35);
    box-shadow: 0 0 0 0 rgba(8, 106, 215, 0.35);
  }
  40% {
    -webkit-box-shadow: 0 0 0 30px rgba(8, 106, 215, 0.2);
    box-shadow: 0 0 0 30px rgba(8, 106, 215, 0.2);
  }
  80% {
    -webkit-box-shadow: 0 0 0 30px rgba(8, 106, 215, 0.2);
    box-shadow: 0 0 0 30px rgba(8, 106, 215, 0.2);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(8, 106, 215, 0.35);
    box-shadow: 0 0 0 0 rgba(8, 106, 215, 0.35);
  }
}
@keyframes playVideo1 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(8, 106, 215, 0.35);
    box-shadow: 0 0 0 0 rgba(8, 106, 215, 0.35);
  }
  40% {
    -webkit-box-shadow: 0 0 0 30px rgba(8, 106, 215, 0.2);
    box-shadow: 0 0 0 30px rgba(8, 106, 215, 0.2);
  }
  80% {
    -webkit-box-shadow: 0 0 0 30px rgba(8, 106, 215, 0.2);
    box-shadow: 0 0 0 30px rgba(8, 106, 215, 0.2);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(8, 106, 215, 0.35);
    box-shadow: 0 0 0 0 rgba(8, 106, 215, 0.35);
  }
}
@-webkit-keyframes dimond {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes dimond {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes scale5 {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
}

@-webkit-keyframes scale5 {
  0%, 100% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
  }
}
@keyframes bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
@-webkit-keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-webkit-keyframes bounceUp {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes bounceUp {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes moveleftbounce {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(40px);
  }
  100% {
    transform: translateX(0px);
  }
}

@-webkit-keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(40px);
    transform: translateX(40px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes moverightbounce {
  0% {
    margin-left: 0px;
  }
  50% {
    margin-left: 30px;
  }
  100% {
    margin-left: 0px;
  }
}
@keyframes moverightbounce {
  0% {
    margin-left: 0px;
  }
  50% {
    margin-left: 30px;
  }
  100% {
    margin-left: 0px;
  }
}
.rotateme {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes guraguri {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes guraguri {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@keyframes move_wave {
  0% {
    -webkit-transform: translateX(0) translateZ(0) scaleY(1);
            transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    -webkit-transform: translateX(-25%) translateZ(0) scaleY(0.55);
            transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }
  100% {
    -webkit-transform: translateX(-50%) translateZ(0) scaleY(1);
            transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}
@-webkit-keyframes move_wave {
  0% {
    -webkit-transform: translateX(0) translateZ(0) scaleY(1);
            transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    -webkit-transform: translateX(-25%) translateZ(0) scaleY(0.55);
            transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }
  100% {
    -webkit-transform: translateX(-50%) translateZ(0) scaleY(1);
            transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}
@-webkit-keyframes shakeMe {
  0%, 20%, 60%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  80% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
  }
}
@keyframes shakeMe {
  0%, 20%, 60%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  80% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
  }
}
@-webkit-keyframes shake {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  70% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@keyframes shake {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  70% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
/* ----------------------------------
  All Aniamtion Styles
 ------------------------------------ */
/* ------------------------------------------------------------------
   Template Default Button, Input Styles - Value Change with Your's Need
 -------------------------------------------------------------------- */
.btnd, .video-btn, .theme-btn {
  line-height: 1;
  display: inline-block;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  text-transform: uppercase;
}

.theme-btn {
  color: #FFF;
  padding: 15px 40px;
  border-radius: 5px;
  font-size: 14px;
  background-color: #8A038C;
  border: 1px solid #8A038C;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 200px;
  display: inline-block;
}
.theme-btn.theme-2 {
  background-color: #ff5e14;
  border: 1px solid #ff5e14;
  color: #fff;
  border-radius: 50px;
  padding: 22px 40px;
}
.theme-btn.theme-2:hover {
  background-color: #211e3b;
  border: 1px solid #211e3b;
}
.theme-btn.theme-2.white {
  background: #fff;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
  color: #211e3b;
  border-color: #fff;
}
.theme-btn.theme-2.white:hover {
  background-color: #211e3b;
  color: #fff;
  border-color: #211e3b;
}
.theme-btn.style-1 {
  background: -webkit-gradient(linear, left top, right top, from(#FB5155), to(#6129AC));
  background: linear-gradient(90deg, #FB5155 0%, #6129AC 100%);
  -webkit-box-shadow: 0px 4px 20px rgba(66, 18, 180, 0.15);
          box-shadow: 0px 4px 20px rgba(66, 18, 180, 0.15);
  border-radius: 4px;
  padding: 15px 30px;
  border: 0px !important;
}
.theme-btn.style-1:hover {
  color: #0E1871;
  background: -webkit-gradient(linear, left top, right top, from(#DED1FA), to(#FBD0D8));
  background: linear-gradient(90deg, #DED1FA 0%, #FBD0D8 100%);
}
@media (max-width: 767px) {
  .theme-btn {
    padding: 17px 30px;
    font-size: 13px;
  }
}
@media (max-width: 580px) {
  .theme-btn {
    padding: 16px 26px;
  }
}
@media (max-width: 480px) {
  .theme-btn {
    padding: 15px 25px;
  }
}
.theme-btn i {
  margin-left: 10px;
}
.theme-btn.no-fil {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: inherit;
          box-shadow: inherit;
  margin-left: 15px;
}
@media (max-width: 500px) {
  .theme-btn.no-fil {
    margin-left: 0;
  }
}
.theme-btn.no-fil:hover {
  background-color: #8A038C;
  border: 1px solid #8A038C;
}
.theme-btn.black {
  background-color: #000;
  border-color: #000;
  -webkit-box-shadow: inherit;
          box-shadow: inherit;
  color: #FFF;
}
.theme-btn.black:hover {
  background-color: #8A038C;
  border: 1px solid #8A038C;
}
.theme-btn.minimal-btn {
  color: #211e3b;
  border: 1px solid #b0cff2;
  background: transparent;
  -webkit-box-shadow: inherit;
          box-shadow: inherit;
}
.theme-btn.minimal-btn:hover {
  background-color: #8A038C;
  border: 1px solid #8A038C;
  -webkit-box-shadow: inherit;
          box-shadow: inherit;
  color: #FFF;
}
.theme-btn.off-white {
  background-color: #efeff1;
  color: #211e3b;
  border: 1px solid #efeff1;
}
.theme-btn:hover {
  background-color: #000;
  border: 1px solid #000;
  -webkit-box-shadow: inherit;
          box-shadow: inherit;
  color: #FFF;
}

.theme-btn-sm {
  font-size: 14px;
  overflow: hidden;
  font-weight: 700;
  line-height: 18px;
  font-style: normal;
  position: relative;
  padding: 10px 18px;
  display: inline-block;
  color: #FFF !important;
  text-transform: uppercase;
  border-radius: 500px;
  background: #840AA6;
}
.theme-btn-sm:before {
  left: 0;
  width: 0%;
  bottom: 0;
  content: "";
  opacity: 0;
  z-index: -1;
  height: 100%;
  overflow: hidden;
  position: absolute;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #000;
  border-radius: 500px;
}
.theme-btn-sm:hover {
  cursor: text;
}
.theme-btn-sm:hover:-moz-any-link {
  color: #FFF;
  cursor: pointer;
  background: transparent;
}
.theme-btn-sm:hover:any-link {
  color: #FFF;
  cursor: pointer;
  background: transparent;
}
.theme-btn-sm:hover:-moz-any-link:before {
  opacity: 1;
  width: 100%;
  visibility: visible;
}
.theme-btn-sm:hover:any-link:before {
  opacity: 1;
  width: 100%;
  visibility: visible;
}

.video-btn {
  font-size: 16px;
  font-weight: 700;
  color: #8A038C;
}
.video-btn i {
  margin-right: 10px;
}

.video-play-btn .play-video {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: #fff;
  color: #8A038C;
  line-height: 100px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  text-align: center;
  -webkit-animation: playVideo infinite 3s;
          animation: playVideo infinite 3s;
  display: inline-block;
  margin: 0px auto;
  font-size: 18px;
}
@media (max-width: 767px) {
  .video-play-btn .play-video {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
}
.video-play-btn .play-video:hover {
  color: #8A038C;
  -webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
          box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.video-play-btn .play-text {
  font-weight: 700;
}

.cat-btn {
  font-size: 16px;
  line-height: 1;
  padding: 20px 30px;
  font-weight: 700;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: transparent;
  display: inline-block;
  margin-right: 10px;
  margin-top: 30px;
}
.cat-btn i {
  margin-right: 10px;
}
.cat-btn.color {
  background-color: #00baa3;
  border-color: #00baa3;
  color: #fff;
}
.cat-btn:hover {
  background-color: #00baa3;
  border-color: #00baa3;
  border-style: solid;
  border-width: 2px;
}

.preloader {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999999999;
}

.preloader .animation-preloader {
  z-index: 1000;
}

.preloader .animation-preloader .spinner {
  -webkit-animation: spinner 1s infinite linear;
  animation: spinner 1s infinite linear;
  border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-top-color: #000000;
  height: 9em;
  margin: 0 auto 3.5em auto;
  width: 9em;
}

.preloader .animation-preloader .txt-loading {
  font: 700 5em "Circular Std", sans-serif, sans-serif;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 500px) {
  .preloader .animation-preloader .txt-loading {
    font-size: 3em;
  }
}

.preloader .animation-preloader .txt-loading .letters-loading {
  color: #8A038C;
  position: relative;
}

.preloader .animation-preloader .txt-loading .letters-loading:before {
  -webkit-animation: letters-loading 4s infinite;
  animation: letters-loading 4s infinite;
  color: #8A038C;
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  font-family: "Circular Std", sans-serif;
  position: absolute;
  top: -3px;
  -webkit-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
}

.preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.preloader.dark .animation-preloader .spinner {
  border-color: #8A038C;
  border-top-color: #fff;
}

.preloader.dark .animation-preloader .txt-loading .letters-loading {
  color: #8A038C;
}

.preloader.dark .animation-preloader .txt-loading .letters-loading:before {
  color: #8A038C;
}

.preloader p {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 8px;
  color: #8A038C;
}

.preloader .loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  z-index: 1;
  pointer-events: none;
}

.preloader .loader .row {
  height: 100%;
}

.preloader .loader .loader-section {
  padding: 0px;
}

.preloader .loader .loader-section .bg {
  background-color: #FFF;
  height: 100%;
  left: 0;
  width: 100%;
  -webkit-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
}

.preloader .loader.dark_bg .loader-section .bg {
  background: #efc94c;
}

.preloader.loaded .animation-preloader {
  opacity: 0;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}

.preloader.loaded .loader-section .bg {
  width: 0;
  -webkit-transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
  transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
}

@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}
@keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}
@-webkit-keyframes letters-loading {
  0%, 75%, 100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }
  25%, 50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}
@keyframes letters-loading {
  0%, 75%, 100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }
  25%, 50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}
/* ----------------------------------
    Template Section Styles
 ------------------------------------*/
header {
  width: 100%;
  z-index: 9999;
}
header.header-1 {
  position: absolute;
  top: 0;
  left: 0;
}
header.header-1 .logo {
  padding: 32px 0px;
}
header.header-1 .logo-2 {
  padding: 32px 0px;
}
header.header-1 .main-menu ul {
  line-height: 1;
}
header.header-1 .main-menu ul > li {
  display: inline-block;
  line-height: 1;
}
header.header-1 .main-menu ul > li > a {
  display: inline-block;
  font-size: 16px;
  text-transform: capitalize;
  line-height: 1;
  padding: 25px 20px;
  overflow: hidden;
}
header.header-1 .main-menu ul > li > a i {
  margin-left: 5px;
}
header.header-1 .main-menu ul > li:hover > a {
  color: #fff;
}
header.header-1 .main-menu ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
header.header-1 .main-menu ul > li > ul {
  position: absolute;
  top: 100%;
  width: 230px;
  background-color: white;
  color: #8A038C;
  left: 0;
  z-index: 999;
  -webkit-box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
          box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  padding: 10px 0px;
}
header.header-1 .main-menu ul > li > ul li {
  display: block;
}
header.header-1 .main-menu ul > li > ul li a {
  display: block;
  color: #000;
  padding: 12px 25px;
}
header.header-1 .main-menu ul > li > ul li a:hover {
  color: #fff;
}
header.header-1 .main-menu ul > li > ul ul {
  left: 100%;
  top: 0;
}
@media (max-width: 1199px) {
  header.header-1 .theme-btn {
    padding: 17px 32px;
    font-size: 12px;
  }
}
@media (max-width: 991px) {
  header.header-1 #hamburger {
    color: #8A038C;
  }
}
header .logo a {
  display: block;
}
header .logo-2 {
  display: none;
}
header.header-2 {
  position: relative;
}
header.header-2 .main-menu ul {
  line-height: 1;
}
header.header-2 .main-menu ul > li {
  display: inline-block;
  line-height: 1;
}
header.header-2 .main-menu ul > li > a {
  display: inline-block;
  font-size: 16px;
  text-transform: capitalize;
  line-height: 1;
  padding: 40px 20px;
  overflow: hidden;
  color: #fff;
}
header.header-2 .main-menu ul > li > a i {
  margin-left: 5px;
}
header.header-2 .main-menu ul > li:hover > a {
  color: #8A038C;
}
header.header-2 .main-menu ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
header.header-2 .main-menu ul > li > ul {
  position: absolute;
  top: 100%;
  width: 230px;
  background-color: #8A038C;
  left: 0;
  z-index: 999;
  -webkit-box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
          box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  padding: 10px 0px;
}
header.header-2 .main-menu ul > li > ul li {
  display: block;
}
header.header-2 .main-menu ul > li > ul li a {
  display: block;
  color: #fff;
  padding: 12px 25px;
}
header.header-2 .main-menu ul > li > ul li a:hover {
  color: #fff;
}
header.header-2 .main-menu ul > li > ul ul {
  left: 100%;
  top: 0;
}
header.header-2 .top-header {
  background-color: rgba(8, 106, 215, 0.1);
  position: relative;
  z-index: 1;
  padding: 6px 50px;
}
@media (min-width: 1600px) {
  header.header-2 .top-header {
    padding: 6px 255px 6px 255px;
  }
}
@media (max-width: 1199px) {
  header.header-2 .top-header {
    padding: 6px 30px;
  }
}
@media (max-width: 991px) {
  header.header-2 .top-header {
    background: #8A038C;
    padding: 6px 15px;
  }
}
header.header-2 .top-header::before {
  position: absolute;
  content: "";
  height: 100%;
  background: #8A038C;
  width: 714px;
  -webkit-clip-path: polygon(0 0, 97% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 97% 0, 100% 100%, 0% 100%);
  top: 0;
  left: 0;
}
@media (max-width: 991px) {
  header.header-2 .top-header::before {
    display: none;
  }
}
header.header-2 .top-header .header-cta ul {
  margin: 0;
  padding: 0;
}
header.header-2 .top-header .header-cta ul li {
  display: inline-block;
  padding-right: 25px;
  position: relative;
  margin-right: 25px;
}
header.header-2 .top-header .header-cta ul li::before {
  position: absolute;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.3);
  width: 2px;
  height: 20px;
  right: 0;
}
header.header-2 .top-header .header-cta ul li:last-child::before {
  display: none;
}
header.header-2 .top-header .header-cta ul li a {
  display: inline-block;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
}
header.header-2 .top-header .header-cta ul li a i {
  margin-right: 5px;
}
header.header-2 .top-header .header-right-cta {
  color: #5397e3;
}
@media (max-width: 991px) {
  header.header-2 .top-header .header-right-cta {
    color: #fff;
  }
}
header.header-2 .top-header .header-right-cta .social-profile a {
  color: #696969;
  margin-left: 15px;
  font-size: 15px;
}
header.header-2 .top-header .header-right-cta .social-profile a:hover {
  color: #8A038C;
}
@media (max-width: 991px) {
  header.header-2 .top-header .header-right-cta .social-profile a {
    color: #fff;
  }
}
@media (max-width: 375px) {
  header.header-2 .header-btn-cta {
    display: none;
  }
}
header.header-2 .main-header-wraper {
  background-color: #211e3b;
  position: relative;
  padding: 0px 50px;
}
@media (min-width: 1600px) {
  header.header-2 .main-header-wraper {
    padding: 0px 255px;
  }
}
@media (max-width: 1199px) {
  header.header-2 .main-header-wraper {
    padding: 15px 30px;
  }
}
@media (max-width: 991px) {
  header.header-2 .main-header-wraper {
    padding: 15px;
  }
}
@media (max-width: 1191px) {
  header.header-2 .main-header-wraper .theme-btn {
    padding: 15px 30px;
    font-size: 13px;
  }
}
@media (max-width: 500px) {
  header.header-2 .main-header-wraper .theme-btn {
    padding: 14px 25px;
    font-size: 12px;
  }
}
header.header-3 .top-header {
  background-color: #e6f0fb;
  padding: 14px 0px;
}
@media (max-width: 767px) {
  header.header-3 .top-header {
    padding: 10px 0px;
  }
}
@media (max-width: 767px) {
  header.header-3 .top-header .welcome-text {
    font-size: 13px;
  }
}
header.header-3 .top-header .welcome-text a {
  text-decoration: underline;
  color: #8A038C;
  margin-left: 5px;
}
@media (max-width: 767px) {
  header.header-3 .top-header .welcome-text a {
    font-size: 13px;
  }
}
header.header-3 .mobile-nav-bar {
  display: none;
}
@media (max-width: 1340px) {
  header.header-3 .mobile-nav-bar {
    display: block;
  }
}
header.header-3 #hamburger {
  display: block;
  color: #8A038C;
}
header.header-3 .menu-top-bar {
  position: relative;
  padding: 20px 0px 15px;
  overflow: hidden;
}
header.header-3 .side-toggle-menu {
  top: 0;
  right: 0;
  width: 8%;
  position: absolute;
  background-color: #211e3b;
  color: #fff;
  height: 100%;
  font-size: 32px;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
header.header-3 .side-toggle-menu i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 32%;
}
header.header-3 .side-toggle-menu:hover {
  background-color: #8A038C;
  color: #fff;
  cursor: pointer;
}
header.header-3 .contact-info-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row: auto;
  width: 92%;
  padding-right: 30px;
}
header.header-3 .single-menu-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 30px;
  position: relative;
}
@media (max-width: 1600px) {
  header.header-3 .single-menu-box {
    padding-left: 15px;
  }
}
@media (max-width: 1400px) {
  header.header-3 .single-menu-box {
    padding-left: 30px;
  }
}
header.header-3 .single-menu-box::before {
  position: absolute;
  width: 1px;
  height: 400%;
  top: 0;
  left: 0;
  content: "";
  background-color: #e8e8eb;
  -webkit-transform: translateY(-40%);
          transform: translateY(-40%);
}
header.header-3 .single-menu-box .icon {
  float: left;
  overflow: hidden;
  font-size: 42px;
  line-height: 1;
  color: #8A038C;
  margin-right: 20px;
}
@media (max-width: 1600px) {
  header.header-3 .single-menu-box .icon {
    font-size: 32px;
    margin-right: 10px;
  }
}
@media (max-width: 1400px) {
  header.header-3 .single-menu-box .icon {
    font-size: 32px;
    margin-right: 20px;
  }
}
header.header-3 .single-menu-box .content {
  overflow: hidden;
}
header.header-3 .single-menu-box .content h3 {
  line-height: 1;
}
header.header-3 .single-menu-box .content span {
  font-size: 16px;
  line-height: 1;
}
header.header-3 .middle-header-wraper {
  padding: 0px 70px;
}
@media (max-width: 1600px) {
  header.header-3 .middle-header-wraper {
    padding: 0px 30px;
  }
}
@media (max-width: 1400px) {
  header.header-3 .middle-header-wraper {
    padding: 0px 15px;
  }
}
@media (max-width: 1340px) {
  header.header-3 .middle-header-wraper {
    padding: 15px;
  }
}
@media (max-width: 767px) {
  header.header-3 .middle-header-wraper {
    padding: 15px 0;
  }
}
@media (max-width: 1340px) {
  header.header-3 .header-menu-wrap {
    display: none;
  }
}
header.header-3 .main-menu-wrapper {
  background-color: #8A038C;
  margin-bottom: -40px;
  z-index: 999;
  position: relative;
}
header.header-3 .main-menu ul {
  line-height: 1;
}
header.header-3 .main-menu ul > li {
  display: inline-block;
  line-height: 1;
}
header.header-3 .main-menu ul > li > a {
  display: inline-block;
  font-size: 16px;
  text-transform: capitalize;
  line-height: 1;
  padding: 30px;
  overflow: hidden;
  color: #fff;
}
header.header-3 .main-menu ul > li > a i {
  margin-left: 5px;
}
header.header-3 .main-menu ul > li:hover > a {
  color: #000;
}
header.header-3 .main-menu ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
header.header-3 .main-menu ul > li > ul {
  position: absolute;
  top: 100%;
  width: 230px;
  background-color: #8A038C;
  left: 0;
  z-index: 999;
  -webkit-box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
          box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  padding: 10px 0px;
}
header.header-3 .main-menu ul > li > ul li {
  display: block;
}
header.header-3 .main-menu ul > li > ul li a {
  display: block;
  color: #fff;
  padding: 12px 25px;
}
header.header-3 .main-menu ul > li > ul li a:hover {
  color: #000;
}
header.header-3 .main-menu ul > li > ul ul {
  left: 100%;
  top: 0;
}
@media (max-width: 1600px) {
  header.header-3 .theme-btn {
    padding: 18px 32px;
  }
}
@media (max-width: 1400px) {
  header.header-3 .theme-btn {
    padding: 16px 30px;
    font-size: 13px;
  }
}
@media (max-width: 1400px) {
  header.header-3 .theme-btn {
    padding: 16px 22px;
    font-size: 12px;
  }
}
header.header-4 {
  position: absolute;
  left: 0;
  width: 100%;
  top: 40px;
}
@media (max-width: 991px) {
  header.header-4 {
    top: 20px;
  }
}
@media (max-width: 1800px) {
  header.header-4 .container {
    border: 1px solid #37344e;
    padding: 15px 30px;
  }
}
header.header-4 .top-header {
  position: relative;
  z-index: 1;
}
@media (min-width: 1800px) {
  header.header-4 .top-header {
    border: 1px solid #37344e;
    margin: 0px 275px;
    padding: 10px 0px;
  }
}
header.header-4 .top-header .header-cta ul {
  margin: 0;
  padding: 0;
}
header.header-4 .top-header .header-cta ul li {
  display: inline-block;
  padding-right: 25px;
  position: relative;
  margin-right: 25px;
}
header.header-4 .top-header .header-cta ul li::before {
  position: absolute;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.3);
  width: 2px;
  height: 20px;
  right: 0;
}
header.header-4 .top-header .header-cta ul li:last-child::before {
  display: none;
}
header.header-4 .top-header .header-cta ul li a {
  display: inline-block;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
}
header.header-4 .top-header .header-cta ul li a i {
  margin-right: 5px;
}
header.header-4 .top-header .header-right-cta {
  color: #5397e3;
}
@media (max-width: 991px) {
  header.header-4 .top-header .header-right-cta {
    color: #fff;
  }
}
header.header-4 .top-header .header-right-cta .social-profile a {
  color: #696969;
  margin-left: 15px;
  font-size: 15px;
}
header.header-4 .top-header .header-right-cta .social-profile a:hover {
  color: #8A038C;
}
@media (max-width: 991px) {
  header.header-4 .top-header .header-right-cta .social-profile a {
    color: #fff;
  }
}
@media (max-width: 375px) {
  header.header-4 .header-btn-cta {
    display: none;
  }
}
@media (min-width: 1800px) {
  header.header-4 .main-header-wraper {
    border: 1px solid #37344e;
    margin: 0px 275px;
    border-top: 0px;
  }
}
@media (max-width: 1191px) {
  header.header-4 .main-header-wraper .theme-btn {
    padding: 15px 30px;
    font-size: 13px;
  }
}
@media (max-width: 500px) {
  header.header-4 .main-header-wraper .theme-btn {
    padding: 14px 25px;
    font-size: 12px;
  }
}
header.header-4 .main-menu ul {
  line-height: 1;
}
header.header-4 .main-menu ul > li {
  display: inline-block;
  line-height: 1;
}
header.header-4 .main-menu ul > li > a {
  display: inline-block;
  font-size: 16px;
  text-transform: capitalize;
  line-height: 1;
  padding: 40px 20px;
  overflow: hidden;
  color: #fff;
}
header.header-4 .main-menu ul > li > a i {
  margin-left: 5px;
}
header.header-4 .main-menu ul > li:hover > a {
  color: #8A038C;
}
header.header-4 .main-menu ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
header.header-4 .main-menu ul > li > ul {
  position: absolute;
  top: 100%;
  width: 230px;
  background-color: #8A038C;
  left: 0;
  z-index: 999;
  -webkit-box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
          box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  padding: 10px 0px;
}
header.header-4 .main-menu ul > li > ul li {
  display: block;
}
header.header-4 .main-menu ul > li > ul li a {
  display: block;
  color: #fff;
  padding: 12px 25px;
}
header.header-4 .main-menu ul > li > ul li a:hover {
  color: #fff;
}
header.header-4 .main-menu ul > li > ul ul {
  left: 100%;
  top: 0;
}
header.header-4 .lan-select select {
  color: #fff;
  line-height: 1.2;
}
header.header-4 .lan-select select option {
  color: #211e3b;
}
header.header-5.stop .top-header {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}
header.header-5.stop .main-header-wraper {
  top: 0;
  background: #FFF !important;
}
header.header-5 .header-search {
  position: relative;
  margin-right: 30px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
@media (max-width: 575px) {
  header.header-5 .header-search {
    margin-right: 15px;
  }
}
header.header-5 .header-search .search-toggle {
  cursor: pointer;
  color: #211e3b;
}
header.header-5 .header-search .search-toggle:hover {
  color: #8A038C;
}
header.header-5 .header-search .search-toggle span {
  font-size: 20px;
  font-weight: 500;
}
header.header-5 .header-search .search-box {
  width: 0;
  left: 50%;
  border: 0;
  opacity: 0;
  z-index: 1;
  padding: 10px 20px;
  border-radius: 4px;
  visibility: hidden;
  position: absolute;
  background: #edf5ff;
  top: calc(100% + 30px);
  -webkit-filter: drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.16));
          filter: drop-shadow(0px 24px 24px rgba(0, 0, 0, 0.16));
  -webkit-transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1);
  transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
@media (min-width: 1200px) {
  header.header-5 .header-search .search-box {
    top: calc(100% + 45px);
  }
}
@media (max-width: 480px) {
  header.header-5 .header-search .search-box {
    left: -285%;
    width: 280px;
  }
}
header.header-5 .header-search .search-box:before {
  left: 0;
  right: 0;
  top: -5px;
  z-index: -1;
  content: "";
  width: 12px;
  height: 12px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  border-radius: 2px;
  background: #edf5ff;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
@media (max-width: 480px) {
  header.header-5 .header-search .search-box:before {
    right: -132px;
  }
}
header.header-5 .header-search .search-box .form-group {
  margin: 0;
  padding: 0;
  position: relative;
}
header.header-5 .header-search .search-box .form-group:before {
  left: 0;
  width: 0;
  right: 0;
  bottom: 0;
  content: "";
  height: 1px;
  margin-left: auto;
  background: #25415d;
  margin-right: auto;
  position: absolute;
  -webkit-transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1);
  transition: all 0.5s cubic-bezier(0.18, 0.89, 0.32, 1);
}
header.header-5 .header-search .search-box .form-group .search-input {
  width: 100%;
  border: 0 !important;
  font-size: 16px !important;
  padding: 10px 0 !important;
  line-height: 22px !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  padding-right: 22px !important;
  color: #211e3b !important;
  background: transparent !important;
  border-radius: 0 !important;
}
header.header-5 .header-search .search-box .form-group .search-input::-webkit-input-placeholder {
  color: #211e3b;
  font-size: 16px;
  font-weight: 400;
}
header.header-5 .header-search .search-box .form-group .search-input::-moz-placeholder {
  color: #211e3b;
  font-size: 16px;
  font-weight: 400;
}
header.header-5 .header-search .search-box .form-group .search-input:-ms-input-placeholder {
  color: #211e3b;
  font-size: 16px;
  font-weight: 400;
}
header.header-5 .header-search .search-box .form-group .search-input:-moz-placeholder {
  color: #211e3b;
  font-size: 16px;
  font-weight: 400;
}
header.header-5 .header-search .search-box .form-group .search-submit {
  right: 0;
  top: auto;
  border: 0;
  bottom: auto;
  font-size: 16px;
  color: #211e3b;
  position: absolute;
  background: transparent;
  -webkit-transition: all all 0.3s ease ease-in-out;
  transition: all all 0.3s ease ease-in-out;
}
header.header-5 .header-search .search-box .form-group .search-submit:hover {
  color: #F47102;
}
header.header-5 .header-search.show .search-toggle {
  color: #8A038C;
}
header.header-5 .header-search.show .search-box {
  opacity: 1;
  width: 530px;
  visibility: visible;
}
@media (max-width: 767px) {
  header.header-5 .header-search.show .search-box {
    width: 450px;
  }
}
@media (max-width: 575px) {
  header.header-5 .header-search.show .search-box {
    width: 400px;
  }
}
@media (max-width: 575px) {
  header.header-5 .header-search.show .search-box {
    width: 380px;
  }
}
@media (max-width: 480px) {
  header.header-5 .header-search.show .search-box {
    width: 280px;
  }
}
header.header-5 .header-search.show .search-box .form-group:before {
  width: 100%;
}
header.header-5 #hamburger {
  font-size: 44px;
  color: #8A038C;
}
@media (max-width: 575px) {
  header.header-5 #hamburger {
    font-size: 35px;
  }
}
header.header-5 .main-menu ul {
  line-height: 1;
}
header.header-5 .main-menu ul > li {
  line-height: 1;
  display: inline-block;
}
header.header-5 .main-menu ul > li > a {
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  overflow: hidden;
  padding: 40.5px 24px;
  display: inline-block;
  color: #211e3b;
  text-transform: capitalize;
}
header.header-5 .main-menu ul > li > a i {
  margin-left: 5px;
  vertical-align: middle;
}
header.header-5 .main-menu ul > li:hover > a {
  color: #8A038C;
}
header.header-5 .main-menu ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
header.header-5 .main-menu ul > li > ul {
  left: 0;
  top: 100%;
  width: 230px;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  padding: 10px 0px;
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
  background-color: #FFF;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
          box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
}
header.header-5 .main-menu ul > li > ul li {
  display: block;
}
header.header-5 .main-menu ul > li > ul li a {
  display: block;
  padding: 12px 25px;
  color: #211e3b;
}
header.header-5 .main-menu ul > li > ul li a:hover {
  color: #8A038C;
}
header.header-5 .main-menu ul > li > ul ul {
  left: 100%;
  top: 0;
}
header.header-5 .top-header {
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 1030;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: fixed !important;
  z-index: 1;
  padding: 6px 50px;
  position: relative;
  background-color: #211e3b;
}
@media (min-width: 1600px) {
  header.header-5 .top-header {
    padding: 6px 191.5px;
  }
}
@media (max-width: 1199px) {
  header.header-5 .top-header {
    display: none !important;
  }
}
header.header-5 .top-header .container-fluid {
  padding: 0;
}
header.header-5 .top-header .header-cta ul {
  margin: 0;
  padding: 0;
  margin-right: -20px;
}
header.header-5 .top-header .header-cta ul li {
  margin-right: 20px;
  display: inline-block;
}
header.header-5 .top-header .header-cta ul li a {
  color: #FFF;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  display: inline-block;
}
header.header-5 .top-header .header-cta ul li a i {
  margin-right: 10px;
  color: #8A038C;
}
header.header-5 .top-header .header-right-cta {
  color: #8A038C;
}
header.header-5 .top-header .header-right-cta .social-profile {
  margin-right: 25px;
}
header.header-5 .top-header .header-right-cta .social-profile a {
  color: #FFF;
  font-size: 14px;
  margin-left: 18px;
}
header.header-5 .top-header .header-right-cta .social-profile a:hover {
  color: #8A038C;
}
header.header-5 .top-header .lan-select select {
  color: #FFF;
  font-size: 15px;
  font-weight: 500;
  margin-left: 25px;
}
@media (max-width: 480px) {
  header.header-5 .header-btn-cta .theme-btn {
    display: none;
  }
}
header.header-5 .main-header-wraper {
  left: 0;
  top: 50px;
  width: 100%;
  z-index: 1030;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: fixed !important;
  padding: 0px 50px;
  position: relative;
  background-color: #FFF;
  border: 1px solid #E6F0FB;
}
@media (min-width: 1600px) {
  header.header-5 .main-header-wraper {
    padding: 0px 191.5px;
  }
}
@media (max-width: 1199px) {
  header.header-5 .main-header-wraper {
    top: 0px !important;
    padding: 15px 30px;
  }
}
@media (max-width: 991px) {
  header.header-5 .main-header-wraper {
    padding: 15px;
  }
}
header.header-5 .main-header-wraper .theme-btn {
  z-index: 1;
  font-weight: 500;
  position: relative;
  padding: 17.5px 27px;
  border-radius: 4px;
}
@media (max-width: 575px) {
  header.header-5 .main-header-wraper .theme-btn {
    padding: 13px 18px !important;
  }
}
header.header-5 .main-header-wraper .theme-btn.black:before {
  background-color: #8DC63F;
}
header.header-5 .main-header-wraper .theme-btn:before {
  left: 0;
  width: 0%;
  bottom: 0;
  content: "";
  opacity: 0;
  z-index: -1;
  height: 100%;
  overflow: hidden;
  position: absolute;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #000;
  border-radius: 4px;
}
header.header-5 .main-header-wraper .theme-btn:hover {
  background: transparent;
}
header.header-5 .main-header-wraper .theme-btn:hover:before {
  opacity: 1;
  width: 100%;
  visibility: visible;
}
@media (max-width: 1191px) {
  header.header-5 .main-header-wraper .theme-btn {
    padding: 15px 25px;
    font-size: 13px;
  }
}
@media (max-width: 500px) {
  header.header-5 .main-header-wraper .theme-btn {
    padding: 14px 20px;
    font-size: 12px;
  }
}
header.header-6 {
  position: absolute;
  padding: 10px;
  top: 0%;
  left: 0%;
}
header.header-6 .main-menu ul {
  line-height: 1;
}
header.header-6 .main-menu ul > li {
  line-height: 1;
  display: inline-block;
}
header.header-6 .main-menu ul > li.active > a {
  position: relative;
  background: linear-gradient(89.88deg, #FB5155 0.08%, #6129AC 99.87%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
header.header-6 .main-menu ul > li.active > a::after {
  z-index: -1;
  content: "";
  position: absolute;
  clear: both;
  width: 34px;
  height: 30px;
  background: #F4F4FC;
  border-radius: 4px;
  top: calc(20% + 7px);
  left: 5px;
}
header.header-6 .main-menu ul > li > a {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 100%;
  overflow: hidden;
  padding: 30px 24px;
  display: inline-block;
  color: #0E1871;
  text-transform: capitalize;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
header.header-6 .main-menu ul > li > a i {
  margin-left: 5px;
  vertical-align: middle;
}
header.header-6 .main-menu ul > li:hover > a {
  background: linear-gradient(89.88deg, #FB5155 0.08%, #6129AC 99.87%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
header.header-6 .main-menu ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
header.header-6 .main-menu ul > li > ul {
  left: 0;
  top: 100%;
  width: 230px;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  padding: 10px 0px;
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
  background-color: #FFF;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
          box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
}
header.header-6 .main-menu ul > li > ul li {
  display: block;
}
header.header-6 .main-menu ul > li > ul li a {
  display: block;
  padding: 12px 25px;
  color: #211e3b;
}
header.header-6 .main-menu ul > li > ul li a:hover {
  position: relative;
  background: linear-gradient(89.88deg, #FB5155 0.08%, #6129AC 99.87%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
header.header-6 .main-menu ul > li > ul ul {
  left: 100%;
  top: 0;
}
header.header-6 .header-btn-cta .theme-btn {
  border: none !important;
}
@media (max-width: 480px) {
  header.header-6 .header-btn-cta .theme-btn {
    display: none;
  }
}
header.header-6 #hamburger {
  position: relative;
  background: linear-gradient(89.88deg, #FB5155 0.08%, #6129AC 99.87%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  margin-left: 20px;
}
header.stop .top-header {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.lan-select {
  font-weight: 500;
  float: right;
}
.lan-select select {
  border: 0px;
  background: transparent;
  color: #211e3b;
  cursor: pointer;
  font-size: 16px;
}
@media (max-width: 991px) {
  .lan-select select {
    color: #fff;
  }
}
.lan-select select option {
  color: #211e3b;
  border: 0px;
}

.top-bar {
  padding: 0px 25px;
  background: #211e3b;
  position: relative;
  overflow: hidden;
}
.top-bar.style-3 {
  padding: 13px 0px;
  display: block;
}
@media (min-width: 1600px) {
  .top-bar.style-3 .container {
    max-width: 1445px;
  }
}
.top-bar .contact-data ul li {
  font-size: 14px;
  line-height: 1;
  color: #8f9aba;
  display: inline-block;
  margin-right: 35px;
}
.top-bar .contact-data ul li i {
  color: #fff;
  margin-right: 5px;
}
.top-bar .social_link a {
  color: #8d98ba;
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  line-height: 1;
}
.top-bar .social_link a:hover {
  color: #fff;
}
.top-bar .lan-select-menu {
  padding: 16px 30px;
  display: inline-block;
  position: relative;
}
.top-bar .lan-select-menu::before {
  position: absolute;
  width: 200%;
  height: 100%;
  content: "";
  background: #000b2c;
  top: 0;
  left: 0;
}
.top-bar .lan-select-menu form {
  font-size: 14px;
}
.top-bar .lan-select-menu form select {
  color: #fff;
  border: 0px;
  background: transparent;
}
.top-bar .lan-select-menu form select option {
  color: #FFF;
  background: #000b2c;
  border: 0px;
}

.page-banner-wrap {
  background-color: #211e3b;
  padding-bottom: 160px;
  padding-top: 140px;
  position: relative;
  z-index: 1;
  background-position: 450px;
}
@media (max-width: 1400px) {
  .page-banner-wrap {
    padding-top: 100px;
    padding-bottom: 80px;
    background-position: 0;
  }
}
@media (max-width: 767px) {
  .page-banner-wrap {
    padding-top: 60px;
    padding-bottom: 40px;
  }
}
.page-banner-wrap::before {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  background: #211e3b;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.8;
}
.page-banner-wrap .banner-text {
  position: absolute;
  top: 70%;
  -webkit-transform: translateY(-50%) rotate(-90deg) translateX(23%);
          transform: translateY(-50%) rotate(-90deg) translateX(23%);
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 4px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.2);
  font-size: 120px;
  font-family: "Circular Std", sans-serif;
  font-weight: 900;
  letter-spacing: -0.03em;
  left: 110px;
  z-index: 2;
  opacity: 0.15;
  text-transform: uppercase;
}
@media (max-width: 1199px) {
  .page-banner-wrap .banner-text {
    display: none;
  }
}
.page-banner-wrap h1 {
  font-size: 80px;
  text-transform: capitalize;
}
@media (max-width: 1199px) {
  .page-banner-wrap h1 {
    font-size: 60px;
  }
}
@media (max-width: 767px) {
  .page-banner-wrap h1 {
    font-size: 40px;
  }
}
.page-banner-wrap .breadcrumb {
  margin-top: 30px;
  background: transparent;
  padding: 0;
}
@media (max-width: 1199px) {
  .page-banner-wrap .breadcrumb {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .page-banner-wrap .breadcrumb {
    float: left;
  }
}
.page-banner-wrap .breadcrumb li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  line-height: 1;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .page-banner-wrap .breadcrumb li {
    font-size: 14px !important;
  }
}
.page-banner-wrap .breadcrumb li a {
  color: #fff;
  font-size: 20px;
  font-weight: 400 !important;
  line-height: 1;
}
@media (max-width: 767px) {
  .page-banner-wrap .breadcrumb li a {
    font-size: 14px;
  }
}
.page-banner-wrap .breadcrumb li a:hover {
  color: #8A038C;
}
.page-banner-wrap .breadcrumb li + li::before {
  color: #c5c2c2;
  content: "\f105";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
}

.mobile-nav {
  position: fixed;
  right: -380px;
  top: 0;
  width: 320px;
  height: 100vh;
  scroll-behavior: smooth;
  overflow-y: scroll;
  -webkit-transition: right 0.5s ease-in;
  transition: right 0.5s ease-in;
  background-color: #8A038C;
  padding: 20px;
  z-index: 9999;
  -webkit-box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
          box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
  /* Vertical Menu Style */
}
.mobile-nav.show {
  right: 0px;
}
.mobile-nav .close-nav {
  border: 0;
  background: transparent;
  color: #fff;
  border-radius: 30px;
  font-size: 20px;
  position: absolute;
  left: 20px;
  top: 10px;
}
.mobile-nav .sidebar-nav {
  margin-top: 30px;
}
.mobile-nav .sidebar-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: transparent;
}
.mobile-nav .sidebar-nav .metismenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.mobile-nav .sidebar-nav .metismenu > li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.mobile-nav .sidebar-nav .metismenu a {
  position: relative;
  display: block;
  padding: 10px 15px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  text-decoration: none;
  color: #fff;
  outline-width: 0;
  text-transform: capitalize;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.mobile-nav .sidebar-nav .metismenu ul a {
  padding: 10px 15px 10px 35px;
  position: relative;
}
.mobile-nav .sidebar-nav .metismenu ul a:hover {
  padding-left: 40px;
}
.mobile-nav .sidebar-nav .metismenu ul a:hover::before {
  background-color: #fff;
}
.mobile-nav .sidebar-nav .metismenu ul a::before {
  position: absolute;
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #fff;
  content: "";
  top: 50%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.mobile-nav .sidebar-nav .metismenu ul ul {
  list-style-type: circle !important;
}
.mobile-nav .sidebar-nav .metismenu ul ul a {
  padding: 10px 15px 10px 45px;
}
.mobile-nav .sidebar-nav .metismenu a:hover,
.mobile-nav .sidebar-nav .metismenu a:focus,
.sidebar-nav .metismenu a:active {
  text-decoration: none;
  color: #f8f9fa;
  background: #8A038C;
}

#hamburger {
  padding-left: 15px;
  color: #fff;
  font-size: 36px;
  line-height: 1;
  text-align: right;
  display: none;
}
#hamburger i {
  cursor: pointer;
}
@media (max-width: 1199px) {
  #hamburger {
    display: block;
  }
}

.action-bar {
  margin-top: 30px;
  padding-left: 20px;
}
.action-bar a {
  color: #fff;
  display: block;
  margin-top: 12px;
}
.action-bar a i {
  margin-right: 10px;
}
.action-bar a.theme-btn {
  text-align: center;
}

/* ---------------------------------
    Offset Canvas Menu Style 
------------------------------------*/
.offset-menu {
  position: fixed;
  height: 100vh;
  top: 0;
  z-index: 9999999;
  opacity: 0;
  visibility: hidden;
  right: -30%;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  background-color: #211e3b;
  -webkit-box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
          box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
}
.offset-menu.show {
  opacity: 1;
  visibility: visible;
  right: 0;
}
.offset-menu .offset-menu-wrapper {
  width: 360px;
  padding: 80px 30px;
}
.offset-menu .theme-btn {
  padding: 20px 45px;
  font-weight: 500;
  margin-top: 40px;
}
.offset-menu .offset-menu-section {
  margin-top: 40px;
}
.offset-menu .offset-menu-section h3, .offset-menu .offset-menu-section h4, .offset-menu .offset-menu-section h5 {
  line-height: 1;
  margin-bottom: 20px;
}
.offset-menu .offset-menu-section a {
  margin-bottom: 10px;
}
.offset-menu .offset-menu-section ul li {
  margin-bottom: 5px;
}
.offset-menu .offset-menu-section ul li span {
  margin-right: 10px;
}
.offset-menu #offset-menu-close-btn {
  position: fixed;
  right: 30px;
  font-size: 28px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  color: #fff;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  z-index: 9;
  top: 20px;
}
.offset-menu #offset-menu-close-btn:hover {
  color: #ff5e14;
}
.offset-menu .offset-menu-footer {
  margin-top: 35px;
}
.offset-menu .offset-menu-social-icon a {
  font-size: 16px;
  background: #211e3b;
  display: inline-block;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-right: 5px;
}
.offset-menu .offset-menu-social-icon a:hover {
  background: #8A038C;
}
.offset-menu img {
  display: inline-block;
  background: #fff;
  padding: 10px 20px;
}

.header-gutter {
  top: 145px;
  min-height: 145px;
}
@media (max-width: 1199px) {
  .header-gutter {
    top: 82px;
    min-height: 82px;
  }
}
@media (max-width: 1191px) {
  .header-gutter {
    top: 76px;
    min-height: 76px;
  }
}
@media (max-width: 575px) {
  .header-gutter {
    top: 71px;
    min-height: 71px;
  }
}
@media (max-width: 500px) {
  .header-gutter {
    top: 70px;
    min-height: 70px;
  }
}

/* ----------------------------------
   Hero Section - Styles
 ------------------------------------ */
.hero-1 .single-slide {
  padding: 330px 0px 225px;
  z-index: 1;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .hero-1 .single-slide {
    padding: 230px 0px 175px;
  }
}
@media (max-width: 991px) {
  .hero-1 .single-slide {
    padding: 180px 0px 120px;
  }
}
@media (max-width: 767px) {
  .hero-1 .single-slide {
    padding: 150px 0px 100px;
  }
}
.hero-1 .single-slide::before {
  position: absolute;
  left: 0;
  top: 0;
  background-image: url("../img/slide_bg_circle.png");
  width: 60%;
  height: 100%;
  content: "";
  z-index: -1;
  opacity: 0.5;
  background-repeat: no-repeat;
}
@media (max-width: 1600px) {
  .hero-1 .single-slide::before {
    width: 100%;
  }
}
.hero-1 .single-slide .hero-contents {
  position: relative;
  z-index: 2;
}
.hero-1 .single-slide .hero-contents h1 {
  font-weight: 900;
}
@media (max-width: 1199px) {
  .hero-1 .single-slide .hero-contents h1 {
    font-size: 100px;
  }
}
@media (max-width: 991px) {
  .hero-1 .single-slide .hero-contents h1 {
    font-size: 80px;
  }
}
@media (max-width: 767px) {
  .hero-1 .single-slide .hero-contents h1 {
    font-size: 60px;
  }
}
.hero-1 .single-slide .hero-contents h2 {
  font-size: 100px;
  line-height: 1;
  font-weight: 900;
  margin-bottom: 20px;
}
@media (max-width: 1199px) {
  .hero-1 .single-slide .hero-contents h2 {
    font-size: 80px;
  }
}
@media (max-width: 991px) {
  .hero-1 .single-slide .hero-contents h2 {
    font-size: 70px;
  }
}
@media (max-width: 767px) {
  .hero-1 .single-slide .hero-contents h2 {
    font-size: 50px;
  }
}
.hero-1 .single-slide .hero-contents p {
  color: #211e3b;
  font-weight: 500;
  font-size: 24px;
  margin-top: 20px;
}
@media (max-width: 767px) {
  .hero-1 .single-slide .hero-contents p {
    font-size: 20px;
  }
}
.hero-1 .single-slide .hero-contents a {
  margin-top: 35px;
  margin-right: 20px;
}
@media (max-width: 767px) {
  .hero-1 .single-slide .hero-contents a {
    margin-top: 20px;
  }
}
.hero-1 .single-slide .slide-top-img {
  width: 771px;
  height: 788px;
  position: absolute;
  z-index: -1;
  background-color: #eee;
  right: 0;
  top: 0;
  content: "";
  -webkit-clip-path: polygon(25% 0, 100% 0%, 101% 70%, 66% 80%, 0% 17%);
          clip-path: polygon(25% 0, 100% 0%, 101% 70%, 66% 80%, 0% 17%);
  background-position: -125px;
}
@media (max-width: 1400px) {
  .hero-1 .single-slide .slide-top-img {
    width: 634px;
    height: 652px;
    right: -83px;
    background-position: -90px;
  }
}
.hero-1 .single-slide .slide-bottom-img {
  width: 972px;
  height: 486px;
  position: absolute;
  z-index: -1;
  background-color: #eee;
  right: 30px;
  bottom: 0;
  content: "";
  -webkit-clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
          clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
}
@media (max-width: 1400px) {
  .hero-1 .single-slide .slide-bottom-img {
    width: 672px;
    height: 386px;
    right: 0;
  }
}
.hero-1 .single-slide .bg-overlay::before {
  background: #211e3b;
  opacity: 0.1;
}

.hero-2 {
  position: relative;
  padding-left: 270px;
  background-color: #211e3b;
}
@media (max-width: 1600px) {
  .hero-2 {
    padding-left: 0;
  }
}
.hero-2 .hero-text {
  position: absolute;
  top: 70%;
  -webkit-transform: translateY(-50%) rotate(-90deg) translateX(23%);
          transform: translateY(-50%) rotate(-90deg) translateX(23%);
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 4px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.2);
  font-size: 215px;
  font-family: "Circular Std";
  font-weight: 900;
  letter-spacing: -0.03em;
  left: -15px;
  z-index: 2;
  opacity: 0.15;
}
@media (max-width: 1600px) {
  .hero-2 .hero-text {
    display: none;
  }
}
.hero-2 .single-slide {
  padding: 250px 0px;
  z-index: 1;
  position: relative;
  padding-left: 90px;
}
@media (max-width: 1600px) {
  .hero-2 .single-slide {
    padding: 180px 0px;
    padding-left: 80px;
  }
}
@media (max-width: 1199px) {
  .hero-2 .single-slide {
    padding-left: 30px;
  }
}
@media (max-width: 991px) {
  .hero-2 .single-slide {
    padding: 160px 0px;
  }
}
@media (max-width: 767px) {
  .hero-2 .single-slide {
    padding: 130px 0px;
  }
}
@media (max-width: 500px) {
  .hero-2 .single-slide {
    padding: 100px 0px;
  }
}
.hero-2 .single-slide::before, .hero-2 .single-slide::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(#211e3b), to(rgba(33, 30, 59, 0)));
  background-image: linear-gradient(90deg, #211e3b 0%, rgba(33, 30, 59, 0) 100%);
  background-image: -ms-linear-gradient(90deg, #211e3b 0%, rgba(33, 30, 59, 0) 100%);
  z-index: -1;
}
.hero-2 .single-slide .hero-contents {
  position: relative;
}
.hero-2 .single-slide .hero-contents h1 {
  color: #fff;
  font-size: 90px;
  font-weight: 900;
  line-height: 110%;
  margin-top: -10px;
  text-transform: capitalize;
}
@media (max-width: 1600px) {
  .hero-2 .single-slide .hero-contents h1 {
    font-size: 70px;
  }
}
@media (max-width: 767px) {
  .hero-2 .single-slide .hero-contents h1 {
    font-size: 50px;
  }
}
@media (max-width: 500px) {
  .hero-2 .single-slide .hero-contents h1 {
    font-size: 42px;
  }
}
@media (max-width: 375px) {
  .hero-2 .single-slide .hero-contents h1 {
    font-size: 40px;
  }
}
.hero-2 .single-slide .hero-contents p {
  color: #fff;
  font-weight: 500;
  font-size: 24px;
  margin-top: 20px;
}
@media (max-width: 767px) {
  .hero-2 .single-slide .hero-contents p {
    font-size: 22px;
  }
}
.hero-2 .single-slide .hero-contents a {
  margin-top: 35px;
  margin-right: 20px;
  color: #fff;
}
@media (max-width: 375px) {
  .hero-2 .single-slide .hero-contents a {
    margin-top: 20px;
    margin-right: 0;
  }
}
.hero-2 .owl-theme .owl-dots {
  display: none;
  margin-top: 0;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.hero-2 .owl-theme .owl-dots .owl-dot {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  text-align: center;
  padding: 15px;
  position: relative;
}
.hero-2 .owl-theme .owl-dots .owl-dot span {
  background-color: #fff;
  display: block;
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
}
.hero-2 .owl-theme .owl-dots .owl-dot::before {
  position: absolute;
  left: 0;
  top: 0;
  background: transparent;
  border-width: 3px;
  border-color: #fff;
  border-style: solid;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  content: "";
}

.hero-3 {
  position: relative;
}
.hero-3 .single-slide {
  padding: 200px 0px 165px;
  z-index: 1;
  position: relative;
}
@media (max-width: 1300px) {
  .hero-3 .single-slide {
    padding: 150px 0px 125px;
  }
}
@media (max-width: 991px) {
  .hero-3 .single-slide {
    padding: 100px 0px;
  }
}
.hero-3 .single-slide::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0.85;
  background-color: #060404;
}
.hero-3 .single-slide .hero-contents {
  position: relative;
}
.hero-3 .single-slide .hero-contents h1 {
  color: #fff;
  font-size: 70px;
  font-weight: 900;
  line-height: 110%;
  margin-top: -10px;
}
@media (max-width: 991px) {
  .hero-3 .single-slide .hero-contents h1 {
    font-size: 90px;
  }
}
@media (max-width: 767px) {
  .hero-3 .single-slide .hero-contents h1 {
    font-size: 80px;
  }
}
.hero-3 .single-slide .hero-contents p {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 30px;
  position: relative;
  padding-left: 40px;
  display: inline-block;
}
@media (max-width: 991px) {
  .hero-3 .single-slide .hero-contents p {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .hero-3 .single-slide .hero-contents p::before, .hero-3 .single-slide .hero-contents p::after {
    display: none !important;
  }
}
.hero-3 .single-slide .hero-contents p::before {
  border-radius: 50%;
  width: 9px;
  height: 9px;
  background-color: #fff;
  content: "";
  left: 6px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
  display: inline-block;
}
.hero-3 .single-slide .hero-contents p::after {
  border-radius: 50%;
  width: 21px;
  height: 21px;
  background-color: transparent;
  content: "";
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
  display: inline-block;
  border: 2px solid #fff;
}
.hero-3 .single-slide .hero-contents a {
  margin-top: 35px;
  margin-right: 20px;
  color: #fff;
}
@media (max-width: 991px) {
  .hero-3 .single-slide .hero-contents a {
    margin-top: 25px;
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  .hero-3 .single-slide .hero-contents a {
    margin-right: 5px;
  }
}
.hero-3 .owl-theme .owl-dots {
  margin-top: 0;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: inline-block;
}
.hero-3 .owl-theme .owl-dots .owl-dot {
  display: block;
  width: 100%;
  text-align: center;
}
.hero-3 .owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: #fff;
  position: relative;
  display: block;
  margin: 0;
}
.hero-3 .owl-theme .owl-dots .owl-dot span::before {
  position: absolute;
  left: 0;
  top: 0;
  background: transparent;
  border-width: 3px;
  border-color: #fff;
  border-style: solid;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  content: "";
}

.hero-4 {
  position: relative;
}
.hero-4 .single-slide {
  padding: 255px 0px 110px;
  z-index: 1;
  position: relative;
  background-color: #211e3b;
  overflow: hidden;
}
.hero-4 .single-slide::before {
  border-radius: 50%;
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(47, 43, 81, 0.94902)), color-stop(67%, rgba(38, 34, 66, 0.55)), to(rgba(33, 30, 59, 0.14902)));
  background-image: linear-gradient(90deg, rgba(47, 43, 81, 0.94902) 0%, rgba(38, 34, 66, 0.55) 67%, rgba(33, 30, 59, 0.14902) 100%);
  position: absolute;
  left: 272px;
  top: 189px;
  width: 477px;
  height: 477px;
  content: "";
  z-index: -1;
}
@media (max-width: 1199px) {
  .hero-4 .single-slide::before {
    left: 50px;
    top: 80px;
  }
}
@media (max-width: 991px) {
  .hero-4 .single-slide::before {
    left: 20px;
    top: 80px;
  }
}
@media (max-width: 991px) {
  .hero-4 .single-slide {
    padding: 150px 0px 100px;
  }
}
@media (max-width: 767px) {
  .hero-4 .single-slide {
    padding: 120px 0px 100px;
  }
}
.hero-4 .single-slide .hero-contents {
  position: relative;
}
.hero-4 .single-slide .hero-contents::before {
  width: 85px;
  height: 85px;
  content: "";
  left: -150px;
  top: -100px;
  position: absolute;
  display: inline-block;
  background-image: url("../img/home4/star.png");
  background-repeat: no-repeat;
}
@media (max-width: 991px) {
  .hero-4 .single-slide .hero-contents::before {
    left: 90%;
    top: 20px;
  }
}
.hero-4 .single-slide .hero-contents h1 {
  color: #fff;
  font-size: 75px;
  font-weight: 900;
  line-height: 105%;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (max-width: 1199px) {
  .hero-4 .single-slide .hero-contents h1 {
    font-size: 60px;
  }
}
@media (max-width: 767px) {
  .hero-4 .single-slide .hero-contents h1 {
    font-size: 50px;
  }
}
.hero-4 .single-slide .hero-contents span {
  position: relative;
  color: #fff;
  padding-left: 60px;
  text-transform: uppercase;
}
.hero-4 .single-slide .hero-contents span::before {
  width: 40px;
  height: 2px;
  background-color: #fff;
  content: "";
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
  display: inline-block;
}
.hero-4 .single-slide .hero-contents p {
  color: #fff;
  position: relative;
  display: inline-block;
}
@media (max-width: 991px) {
  .hero-4 .single-slide .hero-contents p {
    font-size: 18px;
  }
}
.hero-4 .single-slide .hero-contents a {
  margin-top: 35px;
  margin-right: 20px;
  color: #fff;
}
@media (max-width: 991px) {
  .hero-4 .single-slide .hero-contents a {
    margin-top: 25px;
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  .hero-4 .single-slide .hero-contents a {
    margin-right: 5px;
  }
}
.hero-4 .single-slide .hero-banner {
  position: relative;
}
.hero-4 .single-slide .hero-banner .dot {
  position: absolute;
  width: 145px;
  height: 146px;
  content: "";
  right: -80px;
  top: -80px;
  z-index: -1;
  background-image: url("../img/home4/dot4.png");
  background-repeat: no-repeat;
}
.hero-4 .single-slide .hero-banner::before {
  border-width: 1px;
  border-color: rgb(255, 255, 255);
  border-style: solid;
  opacity: 0.102;
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  right: -20px;
  bottom: -20px;
  z-index: -1;
  border-top-left-radius: 50%;
}
.hero-4 .single-slide .hero-banner::after {
  border-width: 1px;
  border-color: rgb(255, 255, 255);
  border-style: solid;
  border-radius: 50%;
  opacity: 0.102;
  position: absolute;
  width: 249px;
  height: 249px;
  content: "";
  right: -120px;
  bottom: -80px;
  z-index: -1;
}

.hero-6 {
  position: relative;
}
.hero-6 .banner-content {
  position: relative;
}
@media (max-width: 500px) {
  .hero-6 .banner-content {
    text-align: center !important;
  }
}
.hero-6 .banner-content h2 {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 80px;
  color: #211e3b;
}
@media (max-width: 768px) {
  .hero-6 .banner-content h2 {
    font-size: 50px;
    line-height: 60px;
  }
}
@media (max-width: 500px) {
  .hero-6 .banner-content h2 {
    text-align: center !important;
    font-size: 40px;
    line-height: 40px;
  }
}
.hero-6 .banner-content span {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 128px;
  line-height: 162px;
  text-align: center;
  text-transform: uppercase;
  -webkit-text-stroke: 1px rgba(33, 30, 59, 0.08);
  color: rgba(0, 0, 0, 0);
  position: absolute;
  top: -20%;
}
@media (max-width: 768px) {
  .hero-6 .banner-content span {
    font-size: 70px;
    line-height: 100px;
  }
}
@media (max-width: 500px) {
  .hero-6 .banner-content span {
    font-size: 60px;
    left: 15px;
    line-height: 70px;
  }
}
.hero-6 .banner-content a {
  z-index: 6;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.hero-6 .banner-content a::after {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  clear: both;
  content: "";
  display: block;
  position: absolute;
  top: 0%;
  left: -51%;
  width: 50%;
  height: 100%;
  background-color: rgba(0, 11, 44, 0.3333333333) !important;
}
.hero-6 .banner-content a::before {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  clear: both;
  content: "";
  display: block;
  position: absolute;
  top: 0%;
  right: -51%;
  width: 50%;
  height: 100%;
  background-color: rgba(0, 11, 44, 0.3333333333) !important;
}
.hero-6 .banner-content a:hover::before {
  right: 0%;
}
.hero-6 .banner-content a:hover::after {
  left: 0%;
}
@media (max-width: 500px) {
  .hero-6 .banner-content a {
    text-align: center !important;
  }
}
.hero-6 .dots-element {
  position: absolute;
  top: 10%;
  left: 10%;
  -webkit-animation: movebounce 4s ease-in-out infinite;
          animation: movebounce 4s ease-in-out infinite;
}
@media (max-width: 1300px) {
  .hero-6 .dots-element {
    display: none;
  }
}
.hero-6 .banner-element {
  position: absolute;
  bottom: 0%;
  left: 0%;
}
@media (max-width: 1300px) {
  .hero-6 .banner-element {
    display: none !important;
  }
}
.hero-6 .banner-element-2 {
  position: absolute;
  bottom: 0%;
  right: 0%;
  z-index: -1;
}

.hero-7 {
  padding: 150px 0px 130px 0px !important;
  position: relative;
  z-index: 1;
}
@media (max-width: 1080px) {
  .hero-7 {
    padding: 150px 0px 100px 0px !important;
  }
}
.hero-7 .banner-content h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #0E1871;
  font-family: "Syne", sans-serif;
}
.hero-7 .banner-content h2 {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 80px;
  color: #0E1871;
}
@media (max-width: 768px) {
  .hero-7 .banner-content h2 {
    font-size: 50px;
    line-height: 60px;
  }
}
@media (max-width: 500px) {
  .hero-7 .banner-content h2 {
    text-align: center !important;
    font-size: 40px;
    line-height: 40px;
  }
}
.hero-7 .banner-content h2 span {
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 80px;
  font-family: "Syne", sans-serif;
  position: relative;
  background: -webkit-gradient(linear, left top, right top, from(#6320B2), color-stop(25.46%, #E64C62));
  background: linear-gradient(90deg, #6320B2 0%, #E64C62 25.46%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
.hero-7 .banner-content p {
  color: #696969;
}
.hero-7 .banner-content .theme-btn {
  border: none;
  padding: 18px 36px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  border: 2px solid linear-gradient(90deg, #FB5155 0%, #6129AC 100%);
}
.hero-7 .banner-content .theme-btn:last-child {
  background: transparent;
  border: 2px solid rgba(70, 19, 178, 0.15);
  border-radius: 4px;
  color: #0E1871;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.hero-7 .banner-content .theme-btn:last-child:hover {
  background: -webkit-gradient(linear, left top, right top, from(#DED1FA), to(#FBD0D8));
  background: linear-gradient(90deg, #DED1FA 0%, #FBD0D8 100%);
}
.hero-7 .shape-element {
  position: absolute;
  z-index: -1;
  top: -20% !important;
  left: 0%;
}
@media (max-width: 1080px) {
  .hero-7 .shape-element {
    display: none;
  }
}
.hero-7 .shape-element-2 {
  position: absolute;
  z-index: -1;
  bottom: 2%;
  right: 0%;
  -webkit-animation: movebounce 4s ease-in-out infinite;
          animation: movebounce 4s ease-in-out infinite;
}
@media (max-width: 1080px) {
  .hero-7 .shape-element-2 {
    display: none;
  }
}
.hero-7 .shape-element-3 {
  position: absolute;
  z-index: -1;
  top: 10%;
  right: 0%;
}
@media (max-width: 1080px) {
  .hero-7 .shape-element-3 {
    display: none;
  }
}
.hero-7 .shape-element-4 {
  position: absolute;
  z-index: -1;
  bottom: -50%;
  left: 0%;
}
@media (max-width: 1080px) {
  .hero-7 .shape-element-4 {
    display: none;
  }
}

.section-title {
  position: relative;
  z-index: 1;
}
.section-title span {
  position: absolute;
  font-weight: 900;
  font-size: 120px;
  line-height: 1;
  color: #211e3b;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  opacity: 0.05;
  z-index: -1;
  top: -40px;
  text-transform: uppercase;
  margin: 0px auto;
}
@media (max-width: 991px) {
  .section-title span {
    font-size: 90px;
    top: -30px;
  }
}
@media (max-width: 767px) {
  .section-title span {
    font-size: 80px;
    top: -20px;
  }
}
@media (max-width: 500px) {
  .section-title span {
    font-size: 60px;
  }
}
.section-title p {
  color: #8A038C;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 15px;
  line-height: 1;
  text-transform: uppercase;
}
.section-title h1 {
  line-height: 50px;
  font-weight: 900;
  text-transform: capitalize;
}
@media (max-width: 500px) {
  .section-title.section__title_3 {
    text-align: center !important;
  }
}
.section-title.section__title_3 h6 {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.section-title.section__title_3 h6 img {
  margin-right: 15px;
}
.section-title.section__title_3 h2 {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  text-transform: capitalize;
  color: #211e3b;
}
@media (max-width: 768px) {
  .section-title.section__title_3 h2 {
    font-size: 32px;
    line-height: 40px;
  }
}

.section__title_4 h6 {
  background: -webkit-gradient(linear, left top, right top, from(#FA5156), to(#632AAC));
  background: linear-gradient(90deg, #FA5156 0%, #632AAC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .section__title_4 h6 {
    margin-bottom: 15px;
  }
}
.section__title_4 h6 img {
  margin-right: 10px;
}
.section__title_4 h2 {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  color: #0E1871;
}
@media (max-width: 768px) {
  .section__title_4 h2 {
    font-size: 40px;
    line-height: 50px;
  }
}
@media (max-width: 568px) {
  .section__title_4 h2 {
    font-size: 35px;
    line-height: 45px;
  }
}
.section__title_4 h2 span {
  position: relative !important;
  font-size: 42px;
  line-height: 50px;
  background: -webkit-gradient(linear, left top, right top, color-stop(75.47%, #E54C62), color-stop(96.12%, #642AAB));
  background: linear-gradient(90deg, #E54C62 75.47%, #642AAB 96.12%);
  font-weight: 700;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
@media (max-width: 768px) {
  .section__title_4 h2 span {
    font-size: 40px;
    line-height: 50px;
  }
}
@media (max-width: 568px) {
  .section__title_4 h2 span {
    font-size: 35px;
    line-height: 45px;
  }
}

.section-title-2 {
  position: relative;
  z-index: 2;
  margin-bottom: 40px;
}
.section-title-2 span {
  font-size: 180px;
  line-height: 1;
  color: rgba(0, 22, 89, 0);
  font-weight: 600;
  -webkit-text-stroke: 1px #efefef;
  position: absolute;
  top: -38%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  opacity: 0.9;
  z-index: -1;
}
.section-title-2 p {
  color: #ff5e14;
}
.section-title-2 h1 {
  color: #211e3b;
}
.section-title-2 .icon {
  border-radius: 50%;
  background-color: #fff;
  -webkit-box-shadow: 0px 30px 60px 0px rgba(0, 0, 0, 0.08);
          box-shadow: 0px 30px 60px 0px rgba(0, 0, 0, 0.08);
  width: 120px;
  height: 120px;
  line-height: 120px;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  position: relative;
  margin-bottom: 50px;
}
.section-title-2 .icon::before {
  border-style: solid;
  border-width: 4px;
  border-radius: 50%;
  width: 180px;
  height: 180px;
  border-color: #eaeaea;
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: -1;
}

.section-padding {
  padding: 130px 0px;
}
@media (max-width: 991px) {
  .section-padding {
    padding: 100px 0px;
  }
}
@media (max-width: 767px) {
  .section-padding {
    padding: 80px 0px;
  }
}

.section-bottom {
  padding-bottom: 120px;
}
@media (max-width: 991px) {
  .section-bottom {
    padding-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .section-bottom {
    padding-bottom: 80px;
  }
}

.section-padding-3 {
  padding: 120px 0px;
}
@media (max-width: 991px) {
  .section-padding-3 {
    padding: 100px 0px;
  }
}
@media (max-width: 767px) {
  .section-padding-3 {
    padding: 50px 0px;
  }
}

.section-padding-two {
  padding: 140px 0px;
}
@media (max-width: 1400px) {
  .section-padding-two {
    padding: 120px 0px;
  }
}
@media (max-width: 991px) {
  .section-padding-two {
    padding: 100px 0px;
  }
}
@media (max-width: 767px) {
  .section-padding-two {
    padding: 80px 0px;
  }
}

.section-bg {
  background-color: #211e3b;
}

.section-bg-2 {
  background-color: #eef4fc;
}

.theme-bg {
  background-color: #8A038C;
}

/* ----------------------------------
	About Us Section Styles
 ------------------------------------ */
.about-us-img {
  border-radius: 10px;
  background-color: #eee;
  height: 475px;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .about-us-img {
    height: 300px;
  }
}

.dot-circle {
  position: absolute;
  left: 45px;
  top: 0;
  background-image: url("../img/dot-circle.png");
  width: 200px;
  height: 200px;
  content: "";
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: -1;
}

.triangle-bottom-right {
  border-bottom: 200px solid #8A038C;
  border-left: 200px solid transparent;
  border-radius: 0px 0px 0px 0px;
  position: absolute;
  right: 0;
  bottom: -15px;
  content: "";
  width: 0;
  height: 0;
  z-index: -1;
}

.about-right-img {
  position: relative;
}
.about-right-img .triangle-bottom-right {
  margin-right: -15px;
}

.about-icon-box {
  padding: 20px 40px;
  border-radius: 7px;
  border: 2px solid #eef4fc;
  margin-top: 40px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.about-icon-box.style-2 {
  padding: 0;
  border: 0;
  padding-right: 40px;
  margin-top: 30px;
}
.about-icon-box.style-2 .icon {
  background-color: #e6f0fb;
  border-radius: 7px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  display: inline-block;
  font-size: 32px;
}
.about-icon-box.style-2 h3 {
  margin-top: -3px;
}
.about-icon-box.style-2 p {
  margin-top: 5px;
}
.about-icon-box:hover {
  border-color: #8A038C;
}
.about-icon-box .icon {
  font-size: 40px;
  line-height: 1;
  color: #8A038C;
  float: left;
  overflow: hidden;
  margin-right: 25px;
}
.about-icon-box .content {
  overflow: auto;
}
.about-thum {
  position: relative;
}
.about-thum::before, .about-thum::after {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  content: "";
  border-radius: 50%;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: -1;
}
.about-thum::before {
  width: 359px;
  height: 359px;
  background-color: #8A038C;
}
@media (max-width: 767px) {
  .about-thum::before {
    display: none;
  }
}
.about-thum::after {
  width: 415px;
  height: 415px;
  background-color: rgba(8, 106, 215, 0.1);
}
@media (max-width: 767px) {
  .about-thum::after {
    display: none;
  }
}
.about-thum .item img {
  border-radius: 10px;
}
.about-thum .bottom-image {
  text-align: left;
  margin-top: -53px;
}

.about-check-list {
  margin-top: 40px;
}
@media (max-width: 375px) {
  .about-check-list {
    display: block !important;
  }
}
.about-check-list .banner {
  border-radius: 7px;
  background-color: rgb(191, 191, 191);
  width: 200px;
  height: 180px;
  position: relative;
  margin-right: 30px;
}

@media (max-width: 991px) {
  .checked-list {
    margin-left: 30px;
  }
}
@media (max-width: 767px) {
  .checked-list {
    margin-left: 0;
  }
}
.checked-list li {
  color: #211e3b;
  margin-top: 8px;
  padding-left: 40px;
  font-weight: 700;
}
.checked-list li::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\f058";
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  font-size: 25px;
  color: #8A038C;
}

.brand-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  margin-top: 60px;
}

.brand-grid > *:nth-child(1) {
  -ms-grid-row: 1; /* Internet Explorer 10 */
  -ms-grid-column: 1; /* Internet Explorer 10 */
  grid-row: 1; /* Standard property */
  grid-column: 1; /* Standard property */
}

.brand-grid > *:nth-child(2) {
  -ms-grid-row: 1; /* Internet Explorer 10 */
  -ms-grid-column: 2; /* Internet Explorer 10 */
  grid-row: 1; /* Standard property */
  grid-column: 2; /* Standard property */
}

.brand-grid > *:nth-child(3) {
  -ms-grid-row: 1; /* Internet Explorer 10 */
  -ms-grid-column: 3; /* Internet Explorer 10 */
  grid-row: 1; /* Standard property */
  grid-column: 3; /* Standard property */
}

.brand-grid > *:nth-child(4) {
  -ms-grid-row: 1; /* Internet Explorer 10 */
  -ms-grid-column: 4; /* Internet Explorer 10 */
  grid-row: 1; /* Standard property */
  grid-column: 4; /* Standard property */
}

.brand-grid > *:nth-child(5) {
  -ms-grid-row: 1; /* Internet Explorer 10 */
  -ms-grid-column: 5; /* Internet Explorer 10 */
  grid-row: 1; /* Standard property */
  grid-column: 5; /* Standard property */
}
.brand-grid .single-brand-logo {
  border: 1px solid #eee;
  padding: 50px;
  text-align: center;
}

.circle-shape {
  position: absolute;
  left: -180px;
  top: 25px;
  z-index: -1;
}
.circle-shape.style-2 {
  left: auto;
  right: -280px;
}

.icon-box-item {
  padding: 40px 30px 30px;
  border: 1px solid #e6f0fb;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-top: 30px;
}
.icon-box-item:hover {
  background-color: #e6f0fb;
}
.icon-box-item .icon {
  font-size: 60px;
  line-height: 1;
  color: #8A038C;
  margin-bottom: 10px;
}
.icon-box-item h4 {
  font-size: 22px;
}
.clip-path img {
  -webkit-clip-path: polygon(24% 0, 100% 0, 100% 100%, 50% 100%, 0 100%, 0 21%);
  clip-path: polygon(24% 0, 100% 0, 100% 100%, 50% 100%, 0 100%, 0 21%);
}

.about-wrapper {
  position: relative;
  padding-bottom: 120px;
}
@media (max-width: 991px) {
  .about-wrapper {
    padding: 100px 0px;
  }
}
@media (max-width: 767px) {
  .about-wrapper {
    padding: 50px 0px;
  }
}
.about-wrapper .about-content p {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 17px;
  line-height: 32px;
  text-transform: capitalize;
  color: #696969;
  margin-bottom: 30px;
}
.about-wrapper .about-content .text {
  position: relative;
}
.about-wrapper .about-content .text p {
  margin-left: 30px;
  margin-bottom: 0;
}
.about-wrapper .about-content .text::after {
  clear: both;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  bottom: 0;
  left: 0;
  position: absolute;
  content: "\f058";
  font-family: "Font Awesome 5 Pro";
  color: #8A038C;
  font-weight: 600;
}
.about-wrapper .img-element {
  position: absolute;
  top: 0%;
  left: -40%;
}
.about-wrapper .dots-element {
  position: absolute;
  right: 10%;
  bottom: 10%;
  -webkit-animation: movebounce 4s ease-in-out infinite;
          animation: movebounce 4s ease-in-out infinite;
}
@media (max-width: 1300px) {
  .about-wrapper .dots-element {
    display: none !important;
  }
}
.about-wrapper.about__7 .section__title_4 h2 {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 50px;
}
.about-wrapper.about__7 .section__title_4 h2 span {
  font-weight: 700;
}
.about-wrapper.about__7 .about-content p {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 30px;
  padding: 0 !important;
  margin-bottom: 15px !important;
  color: #5F637A;
}
.about-wrapper.about__7 .about-content ul > li {
  padding-left: 30px;
}
.about-wrapper.about__7 .about-content ul > li:before {
  bottom: calc(0% + 10px);
  left: 0;
  z-index: -1;
  width: 16px;
  height: 13px;
  content: "";
  position: absolute;
  background-image: url(../img/home7/arrow_trun.svg);
}
.about-wrapper.about__7 .row:nth-child(2n+1) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.features-wrapper .features-banner {
  height: 471px;
  background-color: #eee;
  border-radius: 7px;
  width: 100%;
}
.features-wrapper .feature-cta {
  margin-top: 30px;
  border-radius: 7px;
  padding: 40px 60px;
  background-color: #8A038C;
}
.features-wrapper .feature-cta p {
  font-weight: 500;
  font-size: 20px;
}

.single-features-item {
  border-radius: 7px;
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(200, 226, 255, 0.45);
          box-shadow: 0px 10px 60px 0px rgba(200, 226, 255, 0.45);
  overflow: hidden;
  margin-top: 30px;
  padding: 30px 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 991px) {
  .single-features-item {
    padding: 25px 20px;
  }
}
.single-features-item:hover h3 {
  color: #8A038C;
}
.single-features-item .icon {
  font-size: 60px;
  line-height: 1;
  color: #8A038C;
  float: left;
  overflow: hidden;
  margin-right: 25px;
}
@media (max-width: 991px) {
  .single-features-item .icon {
    margin-right: 20px;
  }
}
.single-features-item .content {
  overflow: auto;
}
.single-features-item .content h3 {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.features-2 {
  background-color: #eef4fc;
  z-index: 1;
  position: relative;
  overflow: hidden;
}
.features-2 .feature-bg {
  position: absolute;
  right: 0;
  bottom: -20px;
  content: "";
  z-index: -1;
  width: 100%;
  height: 220px;
}

.icon-box {
  padding: 40px 30px 35px;
  border-radius: 7px;
  background-color: #fff;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  text-align: center;
  margin-top: 30px;
}
.icon-box:hover {
  background-color: #8A038C;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(8, 106, 215, 0.49);
          box-shadow: 0px 10px 60px 0px rgba(8, 106, 215, 0.49);
}
.icon-box:hover .icon {
  color: #fff;
}
.icon-box:hover h4 {
  color: #fff;
}
.icon-box .icon {
  font-size: 60px;
  color: #8A038C;
  margin-bottom: 15px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.icon-box h4 {
  color: #211e3b;
  text-transform: capitalize;
  font-size: 18px;
  margin-top: 10px;
}

.work-process-wrapper {
  background-color: #eef4fc;
}

.feature_single__items {
  border-radius: 10px;
  padding: 40px 25px;
  position: relative;
  background: #fff;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.feature_single__items .shape_element {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.feature_single__items .icon {
  margin: auto;
  position: relative;
}
.feature_single__items .icon:before {
  bottom: -10%;
  right: 35%;
  z-index: -1;
  width: 60px;
  height: 7px;
  content: "";
  position: absolute;
  background: linear-gradient(89.92deg, #6C2FAA 1.73%, #F14F5C 99.93%);
  -webkit-filter: blur(10px);
          filter: blur(10px);
}
.feature_single__items .icon img {
  -o-object-fit: cover;
     object-fit: cover;
}
.feature_single__items .content h3 {
  margin-top: 30px;
  margin-bottom: 20px;
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  color: #0E1871;
}
.feature_single__items .content p {
  color: #5F637A;
}
.feature_single__items .content .theme-btn {
  border: 0px;
  font-family: "Syne", sans-serif;
  opacity: 0;
  background: rgba(255, 255, 255, 0.09);
  border-radius: 49px;
  padding: 12px 25px;
}
.feature_single__items .content .theme-btn:hover {
  background: -webkit-gradient(linear, left top, right top, from(#DED1FA), to(#FBD0D8));
  background: linear-gradient(90deg, #DED1FA 0%, #FBD0D8 100%);
  color: #0E1871;
}
.feature_single__items.active, .feature_single__items:hover {
  background: linear-gradient(164.56deg, #FB5155 -8.14%, #6129AC 128.56%);
}
.feature_single__items.active .shape_element, .feature_single__items:hover .shape_element {
  opacity: 1;
}
.feature_single__items.active .icon::after, .feature_single__items:hover .icon::after {
  opacity: 0;
  visibility: hidden;
}
.feature_single__items.active .content h3, .feature_single__items:hover .content h3 {
  color: #FFF;
}
.feature_single__items.active .content p, .feature_single__items:hover .content p {
  color: #FFF;
}
.feature_single__items.active .content .theme-btn, .feature_single__items:hover .content .theme-btn {
  opacity: 1;
}

/* -------------------------------
   company__wrapper
---------------------------------- */
.company__wrapper {
  position: relative;
  z-index: 1;
}
.company__wrapper .company_logo.logo-1, .company__wrapper .company_logo.logo-3 {
  text-align: center !important;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto auto auto;
  grid-template-columns: auto auto auto auto;
}
@media (max-width: 460px) {
  .company__wrapper .company_logo.logo-1, .company__wrapper .company_logo.logo-3 {
    -ms-grid-columns: auto auto auto;
    grid-template-columns: auto auto auto;
  }
}
.company__wrapper .company_logo.logo-4 {
  text-align: center !important;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto auto;
  grid-template-columns: auto auto auto;
}
.company__wrapper .company_logo .items_logo {
  margin: 30px 0px;
}
.company__wrapper .company_logo .items_logo img {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.company__wrapper .company_logo .items_logo img:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.company__wrapper::after {
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: -1;
  content: "";
  clear: both;
  width: 100%;
  height: 100%;
  background-image: url(../img/home7/com_01.svg);
  background-repeat: no-repeat;
}

/* ----------------------------------
  Service Section Styles
 ------------------------------------ */
.single-service-item {
  text-align: center;
  padding: 50px 30px;
  border-width: 2px;
  border-color: #312e49;
  border-style: solid;
  border-radius: 7px;
  margin-top: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.single-service-item:hover {
  border: 2px solid #2e2a50;
  background-color: #2e2a50;
}
.single-service-item .icon {
  margin-bottom: 30px;
}
.single-service-item h4 {
  color: #fff;
}
.single-service-item p {
  margin-top: 10px;
  color: #9a95c0;
}
.single-service-item a {
  margin-top: 20px;
  color: #9a95c0;
  text-transform: uppercase;
  font-size: 13px;
  display: block;
  line-height: 1;
}
.single-service-item a i {
  margin-left: 10px;
}

.single-service-box {
  border-radius: 7px;
  background-color: #fff;
  text-align: center;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  padding: 20px;
  overflow: hidden;
  margin-top: 30px;
}
.single-service-box:hover .content-overlay {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.single-service-box:hover .icon img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.single-service-box .icon {
  border-radius: 7px;
  height: 230px;
  width: 100%;
  overflow: hidden;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-color: #eee;
}
.single-service-box .icon img {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.single-service-box h4 {
  font-size: 22px;
}
.single-service-box p {
  margin-top: 5px;
}
.single-service-box .read-link {
  color: #8A038C;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  margin-top: 5px;
  display: block;
}
.single-service-box .read-link i {
  margin-left: 10px;
}
.single-service-box .content-visible {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  margin-top: 20px;
}
.single-service-box .content-overlay {
  padding: 20px 30px;
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform: translateY(30%);
          transform: translateY(30%);
}
.single-service-box .content-overlay h4 {
  margin-bottom: 5px;
}

.single-service-card {
  margin-top: 30px;
}
.single-service-card:hover .icon {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
.single-service-card .service-thumb {
  height: 435px;
  background-color: #8A038C;
  position: relative;
}
@media (max-width: 991px) {
  .single-service-card .service-thumb {
    height: 300px;
  }
}
.single-service-card .content {
  width: 80%;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 7px;
  margin-top: -120px;
  position: relative;
  z-index: 9;
  padding: 40px 30px;
  padding-bottom: 30px;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(200, 226, 255, 0.45);
          box-shadow: 0px 10px 60px 0px rgba(200, 226, 255, 0.45);
}
.single-service-card .content .icon {
  font-size: 64px;
  line-height: 1;
  margin-bottom: 5px;
  color: #8A038C;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.single-service-card .content h3:hover a {
  color: #8A038C;
}
.single-service-card .content p {
  margin-top: 10px;
  font-size: 16px;
  line-height: 28px;
}

.service-2 {
  background-position: right bottom;
}

.service-3 {
  position: relative;
  z-index: 1;
}
.service-3::before {
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0;
  content: "";
  left: 0;
  z-index: -1;
  background-color: #eef4fc;
}
@media (min-width: 1367px) {
  .service-3 .container-fluid {
    padding: 0px 175px;
  }
}

.single-service-vcard {
  background-color: #fff;
  position: relative;
  padding: 35px;
  margin-top: 30px;
  -webkit-clip-path: polygon(87% 0, 100% 13%, 100% 78%, 100% 100%, 0 100%, 0 0);
  clip-path: polygon(87% 0, 100% 13%, 100% 78%, 100% 100%, 0 100%, 0 0);
}
.single-service-vcard:hover .link {
  border-color: #8A038C;
  background-color: #8A038C;
  color: #fff;
}
.single-service-vcard .link {
  border-width: 2px;
  border-color: #efeff1;
  border-style: solid;
  border-radius: 5px;
  width: 36px;
  height: 36px;
  color: #8A038C;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
  float: right;
  margin-top: -10px;
}

.single-service-vcard .icon {
  font-size: 46px;
  color: #8A038C;
  margin-top: 5px;
  margin-bottom: 20px;
}
.single-service-vcard .content h3 {
  font-size: 20px;
  margin-bottom: 5px;
}

.single-service {
  margin-top: 40px;
  padding-right: 30px;
}
.single-service .icon {
  font-size: 46px;
  line-height: 1;
  color: #8A038C;
  margin-bottom: 15px;
}
.single-service .content p {
  margin-top: 10px;
  color: #696969;
}

.single-our-service {
  position: relative;
  margin-top: 30px;
}
.single-our-service::before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgb(33, 30, 59)), color-stop(65%, rgba(33, 30, 59, 0.5)), to(rgba(33, 30, 59, 0)));
  background-image: linear-gradient(0deg, rgb(33, 30, 59) 0%, rgba(33, 30, 59, 0.5) 65%, rgba(33, 30, 59, 0) 100%);
}
.single-our-service.style-2 {
  background: #FFF;
}
.single-our-service.style-2:before {
  content: inherit;
}
.single-our-service.style-2:hover .content .icon i {
  color: #8A038C;
}
.single-our-service.style-2 .thumb {
  z-index: 1;
  height: 200px;
}
.single-our-service.style-2 .thumb:after {
  content: inherit;
}
.single-our-service.style-2 .content {
  border-top: 0;
  margin-top: -45px;
  padding-bottom: 35px;
  border: 1px solid rgba(33, 30, 59, 0.1);
}
@media (max-width: 480px) {
  .single-our-service.style-2 .content {
    padding: 0 20px 25px;
  }
}
.single-our-service.style-2 .content .icon {
  z-index: 1;
  width: 90px;
  height: 90px;
  font-size: 40px;
  line-height: 90px;
  position: relative;
  margin-bottom: 15px;
  display: inline-block;
  border: 1.5px solid #FFF;
  border-radius: 500px;
  -webkit-filter: drop-shadow(0px 10px 10px rgba(33, 30, 59, 0.05));
          filter: drop-shadow(0px 10px 10px rgba(33, 30, 59, 0.05));
}
.single-our-service.style-2 .content .icon i {
  color: #211e3b;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  line-height: 100px !important;
}
@media (max-width: 1199px) {
  .single-our-service.style-2 .content .icon {
    margin-bottom: 10px;
  }
}
@media (max-width: 480px) {
  .single-our-service.style-2 .content .icon {
    margin-bottom: 5px;
  }
}
.single-our-service.style-2 .content .icon:after {
  width: 80px;
  height: 80px;
  bottom: -1px;
  content: "";
  z-index: -1;
  position: absolute;
  background: #FFF;
  left: calc(50% - 40px);
  border-radius: 500px;
}
.single-our-service.style-2 .content .icon img {
  max-width: 40px;
}
.single-our-service.style-2 .content h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 15px;
}
@media (max-width: 1199px) {
  .single-our-service.style-2 .content h3 {
    margin-bottom: 10px;
  }
}
@media (max-width: 480px) {
  .single-our-service.style-2 .content h3 {
    margin-bottom: 5px;
  }
}
.single-our-service.style-2 .content h3:hover {
  color: #8A038C;
}
.single-our-service.style-2 .content p {
  color: #696969 !important;
}
.single-our-service.style-2 .content .read-more {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  line-height: 17px;
  color: #8A038C;
}
@media (max-width: 1199px) {
  .single-our-service.style-2 .content .read-more {
    margin-top: 15px;
  }
}
@media (max-width: 480px) {
  .single-our-service.style-2 .content .read-more {
    margin-bottom: 10px;
  }
}
.single-our-service.style-2 .content .read-more:hover {
  color: #211e3b;
}
.single-our-service.style-2 .content .read-more i {
  font-size: 12px;
  margin-left: 20px;
}
.single-our-service .thumb {
  height: 370px;
  position: relative;
  background-color: #eee;
  z-index: -1;
}
.single-our-service .thumb::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgb(33, 30, 59)), color-stop(65%, rgba(33, 30, 59, 0.5)), to(rgba(33, 30, 59, 0)));
  background-image: linear-gradient(0deg, rgb(33, 30, 59) 0%, rgba(33, 30, 59, 0.5) 65%, rgba(33, 30, 59, 0) 100%);
}
.single-our-service .content {
  margin-top: -90px;
  position: relative;
  z-index: 1;
  padding: 30px;
  padding-top: 0;
  border: 1px solid #2c2945;
  border-top: 0px;
}
.single-our-service .content .icon {
  font-size: 60px;
  line-height: 1;
  margin-bottom: 20px;
}
.single-our-service .content h3 {
  margin-bottom: 10px;
}
.single-our-service .content p {
  color: #a9a9af !important;
}

.service-sidebar {
  margin-top: -50px;
}
.service-sidebar .single-sidebar-widgets {
  margin-top: 50px;
}
.service-sidebar .single-sidebar-widgets .wid-title {
  margin-bottom: 20px;
}
.service-sidebar .single-sidebar-widgets .wid-title h3 {
  font-weight: 700;
}
.service-sidebar .single-sidebar-widgets .services-category-link a {
  display: block;
  padding: 18px 35px;
  line-height: 1;
  background: #f5f5f5;
  margin-bottom: 10px;
}
.service-sidebar .single-sidebar-widgets .services-category-link a:hover {
  background: #8A038C;
  color: #fff;
}
.service-sidebar .single-sidebar-widgets .contact-form-widgets form {
  width: 100%;
}
.service-sidebar .single-sidebar-widgets .contact-form-widgets form input {
  padding: 15px 35px;
  line-height: 1;
  border: 0;
  color: #8A038C;
  width: 100%;
  background-color: #f5f5f5;
  margin-bottom: 20px;
}
.service-sidebar .single-sidebar-widgets .contact-form-widgets form textarea {
  border: 0;
  color: #8A038C;
  width: 100%;
  background-color: #f5f5f5;
  min-height: 140px;
  padding: 15px 35px;
}
.service-sidebar .single-sidebar-widgets .contact-form-widgets form .theme-btn {
  margin-top: 10px;
  display: block;
}
.service-sidebar .single-sidebar-widgets .download-service-doc a:first-child {
  margin-bottom: 20px;
}
.service-sidebar .single-sidebar-widgets .theme-btn {
  width: 100%;
  display: block;
  text-align: center;
}

.service-details-content img {
  margin-bottom: 30px;
}
.service-details-content h1, .service-details-content h3, .service-details-content h4, .service-details-content h2, .service-details-content h5, .service-details-content h6 {
  margin-bottom: 30px;
}
.service-details-content p {
  margin-bottom: 30px;
}
.service-details-content p:last-child {
  margin-bottom: 0;
}
.service-details-content ul {
  margin-bottom: 30px;
}
.service-details-content ul li {
  color: #211e3b;
  margin-top: 8px;
  padding-left: 40px;
  font-weight: 700;
}
.service-details-content ul li::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\f058";
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  font-size: 25px;
  color: #8A038C;
}

.analytics-wrapper .service-element {
  -webkit-column-break-inside: 6px;
     -moz-column-break-inside: 6px;
          break-inside: 6px;
  background-color: #FFF;
  padding: 40px;
}
.analytics-wrapper .service-element .icon {
  margin-bottom: 30px;
}
.analytics-wrapper .service-element h4 {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #211e3b;
  margin-bottom: 20px;
}
.analytics-wrapper .service-element p {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 17px;
  line-height: 32px;
  color: #696969;
  margin-bottom: 20px;
}
.analytics-wrapper .service-element a {
  border-radius: 999px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  padding: 12px 24px;
}
.analytics-wrapper .service-element:hover .icon img {
  -webkit-animation: shake 0.5s linear 1;
          animation: shake 0.5s linear 1;
}

.service__wrapper {
  position: relative;
  overflow: hidden;
  padding-bottom: 120px;
}
@media (max-width: 991px) {
  .service__wrapper {
    padding: 100px 0px;
  }
}
@media (max-width: 767px) {
  .service__wrapper {
    padding: 50px 0px;
  }
}
.service__wrapper .service-content .supports p {
  position: relative;
  padding-left: 25px;
}
.service__wrapper .service-content .supports p::after {
  clear: both;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  bottom: 0;
  left: 0;
  position: absolute;
  content: "\f058";
  font-family: "Font Awesome 5 Pro";
  color: rgb(8, 106, 215);
  font-weight: 600;
}
.service__wrapper .service-content .progress {
  height: 20px;
  border-radius: 999px;
}
.service__wrapper .service-content .progress .progress-bar {
  margin-left: 10px;
  height: 8px;
  background-color: rgb(8, 106, 215);
  border-radius: 999px;
}
.service__wrapper .service__image {
  position: relative;
}
.service__wrapper .service__image .video-play-btn {
  z-index: 5;
  position: absolute;
  top: 40%;
  left: 40%;
}
.service__wrapper .service__image .video-play-btn > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 90px;
  height: 90px;
  text-align: center;
  background-color: rgb(8, 106, 215);
  color: rgb(255, 255, 255);
  border-radius: 50%;
  line-height: 54px;
  border: 10px solid #FFF;
}
.service__wrapper .service__image .video-play-btn > a i {
  color: #FFF;
}
.service__wrapper .service__element {
  position: absolute;
  bottom: -260px;
  left: 0px;
  z-index: -1;
}
@media (max-width: 1800px) {
  .service__wrapper .service__element {
    left: -100px;
  }
}
@media (max-width: 1700px) {
  .service__wrapper .service__element {
    left: -200px;
  }
}
@media (max-width: 1500px) {
  .service__wrapper .service__element {
    left: -300px;
  }
}
@media (max-width: 1300px) {
  .service__wrapper .service__element {
    left: -500px;
  }
}
@media (max-width: 1080px) {
  .service__wrapper .service__element {
    display: none !important;
  }
}

.service_wrapper {
  z-index: 1;
  position: relative;
}
.service_wrapper .service_element {
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
}
.service_wrapper .service_btn {
  margin-top: 50px;
  text-align: center;
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: #5F637A;
}
.service_wrapper .service_btn a {
  text-decoration: underline;
  background: -webkit-gradient(linear, left top, right top, color-stop(74.75%, #E44C64), to(#692BAA));
  background: linear-gradient(90deg, #E44C64 74.75%, #692BAA 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.single_service_items {
  padding: 100px 50px;
  position: relative;
  height: 420px;
  width: 100%;
  border-radius: 70% 30% 50% 50%/60% 53% 47% 40%;
  background: linear-gradient(140.51deg, #FEFBFB 19.45%, #FCFBFE 88.84%);
}
@media (max-width: 768px) {
  .single_service_items {
    padding: 50px 20px;
  }
}
.single_service_items .icon {
  display: inline-block;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}
.single_service_items .icon::after {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  position: absolute;
  opacity: 0;
  bottom: 0%;
  left: 0%;
  z-index: -1;
  content: "";
  clear: both;
  width: 27px;
  height: 27px;
  background: rgba(225, 75, 101, 0.22);
  border-radius: 500px;
}
.single_service_items::after {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  position: absolute;
  opacity: 0;
  top: 0%;
  left: 0%;
  z-index: -1;
  content: "";
  clear: both;
  width: 100%;
  height: 100%;
  background: linear-gradient(96.87deg, #E64C62 4.82%, #652AAB 96.93%);
  border-radius: 61% 39% 29% 71%/52% 67% 33% 48%;
}
.single_service_items::before {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  position: absolute;
  opacity: 1;
  top: 0%;
  left: 0%;
  z-index: -1;
  content: "";
  clear: both;
  width: 100%;
  height: 100%;
  background: linear-gradient(96.87deg, rgba(230, 76, 98, 0.1) 4.82%, rgba(101, 42, 171, 0.1) 96.93%);
  border-radius: 61% 39% 29% 71%/52% 67% 33% 48%;
}
.single_service_items h4 {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
}
.single_service_items p {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 30px;
  color: #5F637A;
}
.single_service_items:hover::after {
  opacity: 1;
}
.single_service_items:hover .icon::after {
  opacity: 1;
}
.single_service_items:hover::before {
  opacity: 0;
}

/* ----------------------------------
  software toolsSection Styles
 ------------------------------------ */
.tools_wrapper {
  position: relative;
  z-index: 1;
}
.tools_wrapper .bg_shape {
  position: absolute;
  top: 25%;
  left: 20%;
}
@media (max-width: 1366px) {
  .tools_wrapper .bg_shape {
    top: 25%;
    left: 5%;
  }
}

.single_tools {
  z-index: 1;
  overflow: hidden;
  position: relative;
  padding: 30px 35px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 8px 20px rgba(1, 1, 28, 0.1);
          box-shadow: 0px 8px 20px rgba(1, 1, 28, 0.1);
  border-radius: 10px;
}
.single_tools .icons_info {
  margin-bottom: 50px;
}
.single_tools .icons_info .content h6 {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
.single_tools .icons_info .content small {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #5F637A;
}
.single_tools p {
  border-top: 1px dashed rgba(66, 18, 180, 0.1);
  padding-top: 10px;
  font-size: 17px;
  color: #5F637A;
  font-family: "Syne", sans-serif;
  margin-bottom: 25px;
}
.single_tools a {
  background: -webkit-gradient(linear, left top, right top, from(rgba(66, 18, 180, 0.1)), to(rgba(238, 53, 84, 0.1)));
  background: linear-gradient(90deg, rgba(66, 18, 180, 0.1) 0%, rgba(238, 53, 84, 0.1) 100%);
  border-radius: 49px;
  padding: 12px 20px;
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  border: none;
  color: #5F637A;
}
.single_tools a:hover {
  border: none;
  color: #FFF;
  background: -webkit-gradient(linear, left top, right top, from(#4212B4), to(#EE3554));
  background: linear-gradient(90deg, #4212B4 0%, #EE3554 100%);
}
.single_tools .shape_element {
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: -1;
}

.testimonial-wrapper {
  position: relative;
  z-index: 1;
}
.testimonial-wrapper .testimonial-bg {
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: #fff;
  left: 0;
  top: 0;
}
@media (max-width: 1199px) {
  .testimonial-wrapper .testimonial-bg {
    width: 30%;
  }
}
@media (max-width: 991px) {
  .testimonial-wrapper .testimonial-bg {
    background-image: inherit;
    background: #8A038C;
    opacity: 0.2;
  }
}
.testimonial-wrapper .owl-dots .owl-dot {
  border-width: 3px;
  border-color: transparent;
  border-style: solid;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: inline-block;
  line-height: 24px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: center;
  position: relative;
  margin: 0px 5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.testimonial-wrapper .owl-dots .owl-dot:hover, .testimonial-wrapper .owl-dots .owl-dot.active {
  border-color: #8A038C;
}
.testimonial-wrapper .owl-dots .owl-dot:hover span, .testimonial-wrapper .owl-dots .owl-dot.active span {
  background-color: #8A038C;
}
.testimonial-wrapper .owl-dots .owl-dot span {
  background-color: #dae9f9;
  margin: 0;
  line-height: 24px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 50%;
  margin-left: -5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.single-testimonial .icon {
  font-size: 100px;
  color: #8A038C;
  line-height: 1;
}
@media (max-width: 991px) {
  .single-testimonial .icon {
    font-size: 80px;
  }
}
@media (max-width: 767px) {
  .single-testimonial .icon {
    font-size: 60px;
  }
}
.single-testimonial h2 {
  font-weight: 400 !important;
  font-style: italic;
  font-size: 30px;
  line-height: 140%;
}
@media (max-width: 767px) {
  .single-testimonial h2 {
    font-size: 26px;
    letter-spacing: -2px;
  }
}
@media (max-width: 375px) {
  .single-testimonial h2 {
    font-size: 24px;
    font-style: normal;
  }
}
.single-testimonial .client-info {
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.single-testimonial .client-info .client-img {
  border-radius: 50%;
  background-color: #eee;
  width: 70px;
  height: 70px;
  line-height: 70px;
  float: left;
  overflow: hidden;
  margin-right: 25px;
}
@media (max-width: 767px) {
  .single-testimonial .client-info .client-img {
    margin-right: 20px;
  }
}
.single-testimonial .client-info .client-bio {
  overflow: auto;
}
.single-testimonial .client-info .client-bio h3 {
  font-size: 25px;
}
.single-testimonial .client-info .client-bio p {
  color: #8A038C;
  font-weight: 500;
}

.testimonial-carousel-2 {
  padding-left: -10px;
  padding-right: -10px;
  position: relative;
}
@media (max-width: 991px) {
  .testimonial-carousel-2 {
    margin-bottom: -30px;
  }
}
@media (max-width: 767px) {
  .testimonial-carousel-2 {
    margin-bottom: -50px;
  }
}
.testimonial-carousel-2 .owl-dots .owl-dot {
  border-width: 3px;
  border-color: transparent;
  border-style: solid;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: inline-block;
  line-height: 24px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: center;
  position: relative;
  margin: 0px 5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.testimonial-carousel-2 .owl-dots .owl-dot:hover, .testimonial-carousel-2 .owl-dots .owl-dot.active {
  border-color: #8A038C;
}
.testimonial-carousel-2 .owl-dots .owl-dot:hover span, .testimonial-carousel-2 .owl-dots .owl-dot.active span {
  background-color: #8A038C;
}
.testimonial-carousel-2 .owl-dots .owl-dot span {
  background-color: #dae9f9;
  margin: 0;
  line-height: 24px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 50%;
  margin-left: -5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.testimonial-carousel-2 .owl-stage-outer {
  padding: 0 10px;
  margin-right: -15px;
}
.testimonial-carousel-2 .single-testimonial {
  border: 1px solid #eee;
  border-radius: 7px;
  padding: 50px 65px 70px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  background-color: #fff;
  margin-bottom: 80px;
  margin-top: 80px;
}
@media (max-width: 991px) {
  .testimonial-carousel-2 .single-testimonial {
    margin-bottom: 50px;
    margin-top: 50px;
  }
}
@media (max-width: 767px) {
  .testimonial-carousel-2 .single-testimonial {
    margin-bottom: 30px;
    margin-top: 30px;
    padding: 30px 30px 50px;
  }
}
.testimonial-carousel-2 .single-testimonial::before {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #8A038C;
  border-radius: 7px;
  content: "";
  left: 0;
  top: 0;
  z-index: -1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.testimonial-carousel-2 .single-testimonial:hover, .testimonial-carousel-2 .single-testimonial.active {
  background-color: #fff;
  border-color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(8, 106, 215, 0.1);
          box-shadow: 0px 10px 60px 0px rgba(8, 106, 215, 0.1);
}
.testimonial-carousel-2 .single-testimonial:hover::before, .testimonial-carousel-2 .single-testimonial.active::before {
  opacity: 1;
  visibility: visible;
  top: -10px;
  left: -10px;
}
@media (max-width: 767px) {
  .testimonial-carousel-2 .single-testimonial .client-info .client-bio h3 {
    font-size: 18px;
    line-height: 1;
  }
}
@media (max-width: 767px) {
  .testimonial-carousel-2 .single-testimonial .client-info .client-bio p {
    font-size: 15px;
  }
}

.tes_element {
  z-index: -1;
  position: absolute;
  top: 0%;
  left: 0%;
}

.brand-logo-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.testimonial_widget .testimonial_items {
  overflow: hidden;
  position: relative;
  padding: 40px 60px 40px 40px;
  background: #FFFFFF;
  border-radius: 10px;
}
@media (max-width: 500px) {
  .testimonial_widget .testimonial_items {
    padding: 20px 40px 20px 20px;
  }
}
.testimonial_widget .testimonial_items h4 {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #0E1871;
  margin-bottom: 15px;
}
.testimonial_widget .testimonial_items p {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #5F637A;
  margin-bottom: 30px;
}
.testimonial_widget .testimonial_items .author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}
.testimonial_widget .testimonial_items .author_img {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 500px;
}
.testimonial_widget .testimonial_items .author_img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.testimonial_widget .testimonial_items h5 {
  font-family: "Syne", sans-serif;
  color: #0E1871;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  padding-bottom: 0;
  margin-bottom: 0;
}
.testimonial_widget .testimonial_items small {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 100%;
  color: #5F637A;
}
.testimonial_widget .testimonial_items .review i {
  color: #F1AA1D;
  margin: 0px 2px;
}
.testimonial_widget .testimonial_items .shape_element {
  position: absolute;
  top: 0%;
  right: 0%;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 500px) {
  .testimonial_widget .testimonial_items .shape_element {
    display: none;
  }
}

.project-wrapper {
  position: relative;
}
.project-wrapper .portfolio-carousel-active .single-project .project-contents {
  background-color: #f7f7f7;
  border-radius: 10px;
  position: relative;
  margin-top: 60px;
}
.project-wrapper .portfolio-carousel-active .single-project .project-details {
  padding: 100px 0px;
}
.project-wrapper .portfolio-carousel-active .single-project .project-details .project-meta .project-cat {
  margin-right: 30px;
}
.project-wrapper .portfolio-carousel-active .single-project .project-details .project-meta .client-info span {
  color: #000;
  font-weight: 600;
  padding-right: 5px;
}
.project-wrapper .portfolio-carousel-active .single-project .project-details .project-meta .client-info i {
  color: #000;
  font-weight: 500;
}
.project-wrapper .portfolio-carousel-active .single-project .project-details h2 {
  font-size: 40px;
  line-height: 50px;
  margin-top: 5px;
}
.project-wrapper .portfolio-carousel-active .single-project .project-details p {
  margin-top: 15px;
}
.project-wrapper .portfolio-carousel-active .single-project .project-details .read-btn {
  margin-top: 35px;
}
.project-wrapper .portfolio-carousel-active .single-project .project-thumbnail {
  position: relative;
  height: 512px;
  background-color: #dddddd;
  border-radius: 10px;
  margin-top: -60px;
  overflow: hidden;
}
.project-wrapper .portfolio-carousel-active .single-project .project-thumbnail .popup-gallery {
  display: block;
  width: 100%;
  height: 100%;
}
.project-wrapper .portfolio-carousel-active .single-project .project-thumbnail .popup-gallery img {
  max-width: 100%;
  width: 100%;
}
.project-wrapper .project-carousel-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  right: 95px;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  border-radius: 10px;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}
.project-wrapper .project-carousel-nav div {
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 24px;
  color: #fff;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background: #8A038C;
  cursor: pointer;
}
.project-wrapper .project-carousel-nav div.owl-next {
  background: #fff;
}

.portfolio-cat-filter button {
  border: none;
  background-color: #f8f8f8;
  display: inline-block;
  padding: 15px 35px;
  line-height: 1;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 30px;
  margin: 0px 3px;
  font-weight: 600;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .portfolio-cat-filter button {
    padding: 15px 25px;
    font-size: 14px;
    margin-bottom: 10px;
  }
}
.portfolio-cat-filter button.active, .portfolio-cat-filter button:hover {
  color: #fff;
  background-color: #ff5e14;
}

.portfolio-carousel-wrapper .center .single-project-item .project-details {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.portfolio-carousel-wrapper .single-project-item {
  padding-bottom: 30px;
}
.portfolio-carousel-wrapper .single-project-item:hover .project-details {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.portfolio-carousel-wrapper .single-project-item .project-thumb {
  height: 450px;
  background-color: #eee;
  position: relative;
}
.portfolio-carousel-wrapper .single-project-item .project-details {
  position: relative;
  background-color: #fff;
  width: 90%;
  margin: 0px auto;
  z-index: 3;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
          box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  padding: 25px 35px;
  margin-top: -60px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.portfolio-carousel-wrapper .single-project-item .project-details::before {
  position: absolute;
  width: 90%;
  left: 50%;
  bottom: -10px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #ffd039;
  z-index: -1;
  content: "";
  height: 10px;
}
.portfolio-carousel-wrapper .single-project-item .project-details .icon {
  display: inline-block;
  position: absolute;
  right: 30px;
  top: 0;
  padding: 10px 30px;
  background-color: #ffd039;
  color: #001248;
  text-align: center;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}
.portfolio-carousel-wrapper .single-project-item .project-details .meta span:last-child {
  color: #001248;
  font-weight: 600;
}
.portfolio-carousel-wrapper .single-project-item .project-details h3 {
  color: #001248;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.portfolio-carousel-wrapper .single-project-item .project-details h3:hover {
  color: #ffd039;
}

.case-cat-filter button {
  display: inline-block;
  border: 0;
  font-size: 14px;
  line-height: 1;
  padding: 9px 15px;
  border-radius: 7px;
  background-color: #fff;
  color: #211e3b;
  text-transform: capitalize;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(121, 121, 121, 0.45);
          box-shadow: 0px 10px 60px 0px rgba(121, 121, 121, 0.45);
  margin-left: 10px;
}
@media (max-width: 991px) {
  .case-cat-filter button {
    margin-top: 10px;
    margin-right: 8px;
  }
}

.single-case-study {
  border-radius: 7px;
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(200, 226, 255, 0.45);
          box-shadow: 0px 10px 60px 0px rgba(200, 226, 255, 0.45);
  margin-top: 30px;
}
.single-case-study .features-thumb {
  border-radius: 7px;
  height: 285px;
  background-color: #eee;
}
.single-case-study .content {
  padding: 33px 35px;
}
.single-case-study .content s h3 {
  margin-bottom: 5px;
  margin-top: -5px;
}
.single-case-study .content a {
  color: #8A038C;
  text-transform: uppercase;
  margin-top: 15px;
  font-size: 14px;
  line-height: 1;
  display: block;
}
.single-case-study .content a i {
  margin-left: 10px;
}

.case-study-carousel-wrapper {
  position: relative;
  z-index: 1;
}
.case-study-carousel-wrapper::before {
  position: absolute;
  width: 100%;
  height: 50%;
  background-color: #eef4fc;
  content: "";
  left: 0;
  bottom: 0;
  z-index: -1;
}
.case-study-carousel-wrapper.style-2::before {
  bottom: auto;
  top: 0;
}
.case-study-carousel-wrapper .work-process-nav div {
  border-radius: 5px;
  width: 55px;
  height: 55px;
  display: inline-block;
  line-height: 55px;
  text-align: center;
  background-color: #d7e6f8;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-left: 10px;
  cursor: pointer;
}
.case-study-carousel-wrapper .work-process-nav div:hover {
  background-color: #8A038C;
  color: #fff;
}
@media (max-width: 991px) {
  .case-study-carousel-wrapper .work-process-nav div {
    margin-left: 0;
    margin-right: 10px;
  }
}

.case-study-items .single-case-item, .case-study-2 .single-case-item {
  text-align: center;
  overflow: hidden;
}
.case-study-items .single-case-item:hover .overlay-content, .case-study-2 .single-case-item:hover .overlay-content {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.case-study-items .single-case-item .case-thumb, .case-study-2 .single-case-item .case-thumb {
  height: 370px;
  border-radius: 7px;
  position: relative;
}
.case-study-items .single-case-item .contents, .case-study-2 .single-case-item .contents {
  width: 90%;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 7px;
  margin-top: -50px;
  position: relative;
  z-index: 9;
}
.case-study-items .single-case-item .content-visible, .case-study-2 .single-case-item .content-visible {
  padding: 20px 30px 15px;
  position: relative;
  display: inline-block;
}
.case-study-items .single-case-item .content-visible h3, .case-study-2 .single-case-item .content-visible h3 {
  margin-bottom: 5px;
}
.case-study-items .single-case-item .content-visible span, .case-study-2 .single-case-item .content-visible span {
  color: #8A038C;
  line-height: 1;
}
.case-study-items .single-case-item .overlay-content, .case-study-2 .single-case-item .overlay-content {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  padding: 35px 30px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 7px;
  -webkit-transform: translateY(50px);
          transform: translateY(50px);
}
.case-study-items .single-case-item .overlay-content p, .case-study-2 .single-case-item .overlay-content p {
  margin-top: 10px;
  font-size: 16px;
}
.case-study-items .single-case-item .overlay-content .theme-btn, .case-study-2 .single-case-item .overlay-content .theme-btn {
  padding: 16px 35px;
  font-size: 14px;
  margin-top: 20px;
}
.case-study-items .single-case-item .overlay-content .theme-btn i, .case-study-2 .single-case-item .overlay-content .theme-btn i {
  margin-right: 10px;
}

.case-study-2 {
  margin-top: -30px;
}
.case-study-2 .single-case-item {
  margin-top: 30px;
}
.case-study-2 .single-case-item .contents {
  -webkit-box-shadow: 0px 10px 30px 0px rgba(8, 106, 215, 0.1);
          box-shadow: 0px 10px 30px 0px rgba(8, 106, 215, 0.1);
}

.case-grid-photos {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-gap: 30px;
}

.case-grid-photos > *:nth-child(1) {
  -ms-grid-row: 1; /* Internet Explorer 10 */
  -ms-grid-column: 1; /* Internet Explorer 10 */
  grid-row: 1; /* Standard property */
  grid-column: 1; /* Standard property */
}

.case-grid-photos > *:nth-child(2) {
  -ms-grid-row: 1; /* Internet Explorer 10 */
  -ms-grid-column: 3; /* Internet Explorer 10 */
  grid-row: 1; /* Standard property */
  grid-column: 3; /* Standard property */
}

@media (max-width: 767px) {
  .case-grid-photos {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.case-study-post-wrapper .case-details-content {
  margin-top: 40px;
}
.case-study-post-wrapper .case-details-content p {
  margin-bottom: 20px;
  margin-top: 10px;
}
.case-study-post-wrapper .case-details-content p:nth-child(1)::first-letter {
  font-size: 20px;
  color: #fff;
  border-radius: 7px;
  background-color: #8A038C;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(8, 106, 215, 0.5);
          box-shadow: 0px 10px 30px 0px rgba(8, 106, 215, 0.5);
  padding: 20px;
  line-height: 1;
  margin-right: 15px;
  float: left;
  overflow: hidden;
  font-weight: 700;
  text-transform: uppercase;
}
.case-study-post-wrapper .case-details-content ul {
  margin-top: 20px;
}
.case-study-post-wrapper .case-details-content ul li {
  color: #211e3b;
  margin-top: 8px;
  padding-left: 40px;
  font-weight: 700;
}
.case-study-post-wrapper .case-details-content ul li::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\f058";
  font-family: "Font Awesome 5 Pro";
  font-size: 25px;
  color: #8A038C;
}

.case-info-card {
  border-radius: 7px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 7px;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(212, 212, 212, 0.38);
          box-shadow: 0px 10px 60px 0px rgba(212, 212, 212, 0.38);
  margin-top: -70px;
  position: relative;
}
@media (max-width: 991px) {
  .case-info-card {
    margin-top: 30px;
  }
}
.case-info-card .case-head {
  padding: 20px 35px;
  background-color: #8A038C;
}
.case-info-card .case-head h3 {
  color: #fff;
}
.case-info-card .project-data {
  padding: 35px;
}
.case-info-card .project-data .single-info-item {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 30% 50px 65%;
  grid-template-columns: 30% 65%;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-gap: 50px;
  margin-bottom: 10px;
}
.case-info-card .project-data .single-info-item > *:nth-child(1) {
  -ms-grid-row: 1; /* Internet Explorer 10 */
  -ms-grid-column: 1; /* Internet Explorer 10 */
  grid-row: 1; /* Standard property */
  grid-column: 1; /* Standard property */
}
.case-info-card .project-data .single-info-item > *:nth-child(2) {
  -ms-grid-row: 1; /* Internet Explorer 10 */
  -ms-grid-column: 3; /* Internet Explorer 10 */
  grid-row: 1; /* Standard property */
  grid-column: 3; /* Standard property */
}
.case-info-card .project-data .single-info-item .left-data {
  color: #74787c;
  position: relative;
}
.case-info-card .project-data .single-info-item .left-data::before {
  position: absolute;
  right: -10px;
  content: ":";
}
.case-info-card .project-data .single-info-item .right-data {
  color: #211e3b;
  font-weight: 700;
}
.case-info-card .project-data .single-info-item .social-link a {
  display: inline-block;
  margin: 0px 4px;
  color: #8A038C;
  font-weight: 400;
}

.project-case-study {
  padding: 0px 30px;
}
.project-case-study.owl-carousel .owl-item.center .single-project-card .contents {
  opacity: 1;
  visibility: visible;
  bottom: 30px;
}
.project-case-study .single-project-card {
  -webkit-clip-path: polygon(13% 0, 100% 0, 100% 100%, 52% 100%, 0 100%, 0 14%);
  clip-path: polygon(13% 0, 100% 0, 100% 100%, 52% 100%, 0 100%, 0 14%);
  position: relative;
  margin-top: 30px;
}
.project-case-study .single-project-card .case-thumb {
  height: 530px;
}
@media (max-width: 585px) {
  .project-case-study .single-project-card .case-thumb {
    height: 400px;
  }
}
.project-case-study .single-project-card .contents {
  width: 90%;
  background-color: #fff;
  border-radius: 7px;
  position: absolute;
  left: 50%;
  bottom: 0px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 9;
  padding: 30px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 585px) {
  .project-case-study .single-project-card .contents {
    width: 95%;
  }
  .project-case-study .single-project-card .contents p {
    font-size: 15px;
    line-height: 140%;
  }
}
.project-case-study .single-project-card .contents h3 {
  margin-bottom: 20px;
}
@media (max-width: 585px) {
  .project-case-study .single-project-card .contents h3 {
    margin-bottom: 10px;
  }
}
.project-case-study .single-project-card .contents .theme-btn {
  margin-top: 20px;
}
@media (max-width: 585px) {
  .project-case-study .single-project-card .contents .theme-btn {
    margin-top: 15px;
  }
}
.project-case-study .owl-dots {
  margin-top: 30px !important;
  margin-bottom: -10px;
}
.project-case-study .owl-dots .owl-dot {
  border-width: 3px;
  border-color: transparent;
  border-style: solid;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: inline-block;
  line-height: 24px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: center;
  position: relative;
  margin: 0px 5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.project-case-study .owl-dots .owl-dot:hover, .project-case-study .owl-dots .owl-dot.active {
  border-color: #8A038C;
}
.project-case-study .owl-dots .owl-dot:hover span, .project-case-study .owl-dots .owl-dot.active span {
  background-color: #8A038C;
}
.project-case-study .owl-dots .owl-dot span {
  background-color: #dae9f9;
  margin: 0;
  line-height: 24px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 50%;
  margin-left: -5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.project__items {
  position: relative;
  overflow: hidden;
}
.project__items .project__image {
  position: relative;
  width: auto;
  height: 445px;
}
.project__items .project__image .box {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  top: 2.5%;
  left: 2.5%;
  position: absolute;
  width: 95%;
  height: 0%;
  background: rgba(8, 106, 215, 0.9);
}
.project__items .project__image .box > a {
  -webkit-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: -4%;
  right: -4%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #FFF;
}
.project__items .project__image .box > a i {
  font-size: 30px;
  color: #FFF;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.project__items .project__content {
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  position: absolute;
  bottom: 25px;
  left: 25px;
  opacity: 0;
  visibility: hidden;
}
.project__items .project__content h4 {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.03em;
  color: #FFF;
}
.project__items .project__content p {
  font-style: normal;
  font-family: "Circular Std", sans-serif;
  font-weight: 450;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: -0.03em;
  color: #FFF;
}
.project__items:hover .box {
  height: 95%;
}
.project__items:hover .box > a {
  visibility: visible;
  opacity: 1;
}
.project__items:hover .project__content {
  visibility: visible;
  opacity: 1;
}

.project-carousel-wrapper {
  overflow: hidden;
  position: relative;
}
@media (max-width: 500px) {
  .project-carousel-wrapper .project__btn {
    z-index: 7;
    text-align: center !important;
  }
}
.project-carousel-wrapper .project-carousel-card-active {
  overflow: hidden;
  margin-left: 0;
}
@media (max-width: 1920px) {
  .project-carousel-wrapper .project-carousel-card-active {
    margin-left: 375px;
  }
}
@media (max-width: 1600px) {
  .project-carousel-wrapper .project-carousel-card-active {
    margin-left: 200px;
  }
}
@media (max-width: 1366px) {
  .project-carousel-wrapper .project-carousel-card-active {
    margin-left: 100px;
  }
}
@media (max-width: 1180px) {
  .project-carousel-wrapper .project-carousel-card-active {
    margin-left: 0px !important;
  }
}
.project-carousel-wrapper::after {
  z-index: -1;
  position: absolute;
  top: 0%;
  left: 0%;
  background: #FAFAFF;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 75%, 0% 100%);
          clip-path: polygon(0 0, 100% 0, 100% 75%, 0% 100%);
  content: "";
  clear: both;
  height: 70%;
  width: 100%;
}

.single-pricing-plan {
  padding: 50px 45px;
  border-width: 2px;
  border-color: #312e49;
  border-style: solid;
  border-radius: 7px;
  margin-top: 40px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1;
}
.single-pricing-plan.style-2 {
  background-color: #fff;
  border-color: #fff;
  margin-top: 0px !important;
}
.single-pricing-plan.style-2 h3, .single-pricing-plan.style-2 h2, .single-pricing-plan.style-2 span {
  color: #211e3b !important;
}
.single-pricing-plan.style-2 .theme-btn {
  border-color: #efeff1;
  background-color: transparent;
  color: #211e3b;
}
.single-pricing-plan.style-2 .plan-features li {
  color: #696969 !important;
}
.single-pricing-plan.style-2 .plan-features li::before {
  color: #8A038C;
}
.single-pricing-plan.style-2:hover h3, .single-pricing-plan.style-2:hover h2, .single-pricing-plan.style-2:hover span, .single-pricing-plan.style-2.active h3, .single-pricing-plan.style-2.active h2, .single-pricing-plan.style-2.active span {
  color: #fff !important;
}
.single-pricing-plan.style-2:hover .theme-btn, .single-pricing-plan.style-2.active .theme-btn {
  background-color: #fff;
  border-color: #fff;
  color: #8A038C;
}
.single-pricing-plan.style-2:hover .plan-features li, .single-pricing-plan.style-2.active .plan-features li {
  color: #fff !important;
}
.single-pricing-plan.style-2:hover .plan-features li::before, .single-pricing-plan.style-2.active .plan-features li::before {
  color: #fff;
}
.single-pricing-plan:hover, .single-pricing-plan.active {
  background-color: #8A038C;
  border-color: #8A038C;
}
.single-pricing-plan:hover p, .single-pricing-plan:hover h3, .single-pricing-plan:hover span, .single-pricing-plan.active p, .single-pricing-plan.active h3, .single-pricing-plan.active span {
  color: #fff !important;
}
.single-pricing-plan:hover a, .single-pricing-plan.active a {
  color: #8A038C;
  background-color: #fff;
  border-color: #fff;
}
.single-pricing-plan h3 {
  color: #fff;
  font-weight: 500;
  margin-bottom: 10px;
}
.single-pricing-plan p {
  color: #9a95c0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.single-pricing-plan .pricing {
  margin-top: 15px;
  position: relative;
}
.single-pricing-plan .pricing span,
.single-pricing-plan .pricing h2,
.single-pricing-plan .pricing p {
  color: #fff;
  display: inline-block;
  font-weight: 400;
}
.single-pricing-plan .pricing span {
  position: absolute;
  font-size: 18px;
  top: 0;
}
.single-pricing-plan .pricing h2 {
  font-size: 42px;
  line-height: 1;
  padding-left: 18px;
  letter-spacing: -1px;
}
.single-pricing-plan .pricing p {
  color: #9a95c0;
  margin-left: 5px;
  text-transform: lowercase;
}
.single-pricing-plan .plan-features {
  margin-top: 30px;
}
.single-pricing-plan .plan-features li {
  position: relative;
  padding-left: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #a5beec;
  margin-bottom: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.single-pricing-plan .plan-features li.active {
  color: #fff;
}
.single-pricing-plan .plan-features li::before {
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  content: "\f00c";
  z-index: -1;
  left: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.single-pricing-plan .theme-btn {
  margin-top: 28px;
  border-color: #312e49;
  background-color: #312e49;
  color: #fff;
}
.single-pricing-plan .theme-btn i {
  margin-left: 10px;
}

@media (max-width: 1199px) {
  .our-pricing-wrapper .dot-circle {
    display: none;
  }
}
.our-pricing-wrapper.right-bg {
  position: relative;
}
.our-pricing-wrapper.right-bg::before {
  position: absolute;
  width: 50%;
  height: 100%;
  right: 0;
  top: 0;
  content: "";
  background-color: #eef4fc;
  z-index: -1;
}
@media (max-width: 1199px) {
  .our-pricing-wrapper.right-bg::before {
    width: 100%;
  }
}

.price__wrapper {
  position: relative;
  overflow-y: hidden;
}
.price__wrapper .price__widget {
  background: linear-gradient(153.97deg, rgba(73, 21, 179, 0.05) 0%, rgba(233, 54, 89, 0.05) 100%);
  border-radius: 10px;
}
.price__wrapper .price__widget .price__head {
  padding: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.price__wrapper .price__widget .price__head h4 {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  color: #0E1871;
}
.price__wrapper .price__widget .price__head p {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 100%;
  color: #0E1871;
}
.price__wrapper .price__widget .price__head p span {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  background: linear-gradient(96.92deg, #4915B3 4.41%, #EB3658 55.41%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
.price__wrapper .price__widget .price__head p sup {
  font-family: "Syne";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 100%;
  /* or 40px */
  text-align: center;
  text-transform: uppercase;
  background: linear-gradient(96.92deg, #4915B3 4.41%, #EB3658 55.41%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
.price__wrapper .price__widget .price__head .icon {
  background: rgba(255, 255, 255, 0.6);
  width: 100px;
  height: 100px;
  border-radius: 10px;
  border: 1px dashed rgba(189, 186, 186, 0.8156862745);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.price__wrapper .price__widget .preice__body {
  padding: 40px 40px 40px 40px;
}
.price__wrapper .price__widget .preice__body ul > li {
  position: relative;
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  color: #0E1871;
  padding: 20px 0px;
  padding-left: 30px;
  border-bottom: 1px solid rgba(66, 18, 180, 0.1);
}
.price__wrapper .price__widget .preice__body ul > li.active::after {
  color: #E93659;
}
.price__wrapper .price__widget .preice__body ul > li::after {
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  content: "\f336";
  color: #4613B2;
  font-weight: 900;
  z-index: -1;
  left: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.price__wrapper .price__widget .preice__body ul > li:last-child {
  border-bottom: 0px solid rgba(66, 18, 180, 0.1);
}
.price__wrapper .price__widget a {
  border-radius: 50px;
}
.price__wrapper .price__element {
  background: #FFFFFF;
  -webkit-box-shadow: 0px 10px 25px rgba(11, 11, 127, 0.05);
          box-shadow: 0px 10px 25px rgba(11, 11, 127, 0.05);
  position: relative;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.price__wrapper .price__element .price__head {
  background-color: #211e3b;
  padding: 40px;
}
.price__wrapper .price__element .price__head .price__range {
  position: relative;
}
.price__wrapper .price__element .price__head .price__range span {
  position: absolute;
  top: 0%;
  left: -3%;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 30px;
  color: #FFF;
}
.price__wrapper .price__element .price__head .price__range h3 {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  text-transform: capitalize;
  color: #FFF;
}
.price__wrapper .price__element .price__head .price__range p {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 30px;
  color: #FFF;
}
.price__wrapper .price__element .preice__body {
  z-index: 5;
  padding: 40px;
}
.price__wrapper .price__element .preice__body .cetagory__element {
  position: absolute;
  right: 20px;
  z-index: 5;
  top: 25%;
}
.price__wrapper .price__element .preice__body .cetagory__element h4 {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  text-align: right;
  color: #FFF;
}
.price__wrapper .price__element .preice__body .cetagory__element p {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: right;
  color: #FFF;
}
.price__wrapper .price__element .preice__body a {
  margin-top: 10px;
  padding: 15px 30px;
}
.price__wrapper .price__element .preice__body ul li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
}
.price__wrapper .price__element .preice__body ul li::before {
  clear: both;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  bottom: 0;
  left: 0;
  position: absolute;
  content: "\f058";
  font-family: "Font Awesome 5 Pro";
  color: rgb(8, 106, 215);
  font-weight: 600;
}
.price__wrapper .price__element .preice__body ul li.active::before {
  color: #E6F0FB;
}
.price__wrapper .price__element::after {
  clear: both;
  top: 0;
  right: 0;
  position: absolute;
  content: "";
  width: 199px;
  height: 320px;
  background-color: #8A038C;
  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 50%);
          clip-path: polygon(100% 0, 100% 100%, 0 50%);
}
.price__wrapper .price__element:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.price__wrapper .dots-top-element {
  position: absolute;
  top: 10%;
  left: 15%;
  z-index: -1;
}
.price__wrapper .dots-bottom-element {
  position: absolute;
  bottom: 0%;
  right: 15%;
  z-index: -1;
}

.price-top-element {
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: -1;
}

/* -------------------------------
   filter button
---------------------------------- */
.filter_button {
  margin: 30px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 30px;
}
@media (max-width: 768px) {
  .filter_button {
    display: block !important;
  }
  .filter_button button {
    margin: 10px 0px;
    margin: 10px auto;
  }
}
.filter_button button {
  background: -webkit-gradient(linear, left top, right top, from(#DED1FA), to(#FBD0D8));
  background: linear-gradient(90deg, #DED1FA 0%, #FBD0D8 100%);
  border-radius: 10px;
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: #0E1871;
  height: 120px;
  padding: 30px 68px;
  max-width: 420px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.filter_button button span {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
}
.filter_button button:focus, .filter_button button:active, .filter_button button:hover {
  color: #FFF;
  background: -webkit-gradient(linear, left top, right top, from(#4714B3), to(#EC3657));
  background: linear-gradient(90deg, #4714B3 0%, #EC3657 100%);
}

.filter {
  display: none;
}
.filter.active {
  display: block !important;
}

/* ----------------------------------
	Call To Action Section Styles
 ------------------------------------ */
.cta-banner {
  z-index: 3;
  position: relative;
}
.cta-banner.style-2 .container-fluid {
  background-color: #8A038C;
}
.cta-banner.style-2 .container-fluid::after, .cta-banner.style-2 .container-fluid::before {
  border-color: #fff;
}
.cta-banner.style-2 .theme-btn:first-child {
  background-color: #fff;
  color: #8A038C;
}
.cta-banner.style-3 {
  overflow: hidden;
  padding-top: 120px;
}
@media (max-width: 1199px) {
  .cta-banner.style-3 {
    padding-top: 100px;
  }
}
@media (max-width: 991px) {
  .cta-banner.style-3 {
    padding-top: 90px;
  }
}
@media (max-width: 767px) {
  .cta-banner.style-3 {
    padding-top: 80px;
  }
}
@media (max-width: 575px) {
  .cta-banner.style-3 {
    padding-top: 70px;
  }
}
@media (max-width: 480px) {
  .cta-banner.style-3 {
    padding-top: 50px;
  }
}
.cta-banner.style-3 .container-fluid {
  max-width: 1520px;
  padding: 90px 15px;
  border-radius: 0;
  background-color: #8A038C;
}
@media (max-width: 1199px) {
  .cta-banner.style-3 .container-fluid {
    padding: 80px 15px;
  }
}
@media (max-width: 991px) {
  .cta-banner.style-3 .container-fluid {
    padding: 70px 15px;
  }
}
@media (max-width: 767px) {
  .cta-banner.style-3 .container-fluid {
    padding: 60px 15px;
  }
}
@media (max-width: 575px) {
  .cta-banner.style-3 .container-fluid {
    padding: 50px 15px;
  }
}
.cta-banner.style-3 .container-fluid:after {
  content: inherit;
}
.cta-banner.style-3 .container-fluid:before {
  width: 1360px;
  height: 210px;
  border-width: inherit;
  border-color: inherit;
  border-style: inherit;
  border-radius: 0;
  background-repeat: no-repeat;
  background-image: url(assets/img/techex-landing-page/cta-overly.html);
}
.cta-banner.style-3 .container-fluid h1 {
  font-weight: 900;
  letter-spacing: -0.03em;
}
@media (max-width: 767px) {
  .cta-banner.style-3 .container-fluid h1 {
    line-height: 42px;
  }
}
.cta-banner.style-3 .btn-wraper {
  gap: 30px;
}
@media (max-width: 1199px) {
  .cta-banner.style-3 .btn-wraper {
    gap: 20px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.cta-banner.style-3 .theme-btn {
  z-index: 1;
  font-weight: 500;
  overflow: hidden;
  line-height: 16px;
  padding: 18px 30px;
  position: relative;
  border: 1px solid #FFFFFF;
}
.cta-banner.style-3 .theme-btn:before {
  left: 0;
  width: 0%;
  bottom: 0;
  content: "";
  z-index: -1;
  height: 100%;
  position: absolute;
  background-color: #FFF;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cta-banner.style-3 .theme-btn i {
  font-size: 12px;
  margin-left: 8px;
}
.cta-banner.style-3 .theme-btn:first-child {
  color: #8A038C;
  background-color: #fff;
}
.cta-banner.style-3 .theme-btn:first-child:before {
  background-color: #000;
}
.cta-banner.style-3 .theme-btn:first-child:hover {
  color: #FFF;
  border-color: #000;
}
.cta-banner.style-3 .theme-btn:hover {
  color: #8A038C;
  background: transparent;
}
.cta-banner.style-3 .theme-btn:hover:before {
  width: 100%;
}
.cta-banner .container-fluid {
  padding-top: 90px;
  padding-bottom: 85px;
  border-radius: 7px;
  position: relative;
  z-index: 3;
  margin: 0 auto;
}
@media (min-width: 1650px) {
  .cta-banner .container-fluid {
    max-width: 1600px;
    width: 100%;
  }
}
@media (max-width: 1649px) {
  .cta-banner .container-fluid {
    max-width: 96%;
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 1199px) {
  .cta-banner .container-fluid {
    max-width: 90%;
  }
}
@media (max-width: 767px) {
  .cta-banner .container-fluid {
    max-width: 92%;
    padding: 50px 15px;
  }
}
@media (max-width: 500px) {
  .cta-banner .container-fluid {
    max-width: 96%;
    padding: 40px 20px;
  }
}
.cta-banner .container-fluid::after {
  border-width: 3px;
  border-color: rgb(255, 150, 0);
  border-style: solid;
  border-radius: 50%;
  position: absolute;
  width: 14px;
  height: 14px;
  right: 7%;
  top: 60px;
  content: "";
  z-index: -1;
}
@media (max-width: 767px) {
  .cta-banner .container-fluid::after {
    top: 30px;
  }
}
.cta-banner .container-fluid::before {
  border-width: 5px;
  border-color: #e8524a;
  border-style: solid;
  border-radius: 50%;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 6%;
  bottom: 60px;
  content: "";
  z-index: -1;
}
.cta-banner .cta-content {
  max-width: 1170px;
  width: 100%;
  margin: 0px auto;
}
@media (max-width: 500px) {
  .cta-banner .cta-content h1 {
    font-size: 30px;
  }
}
.cta-banner .minimal-btn {
  color: #fff;
}

.video-section::before {
  position: absolute;
  left: 0;
  content: "";
  top: 0;
  background: #8A038C;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: -1;
}
.video-section .section-title span {
  color: #00baa3;
}
.video-section .section-title h1 {
  color: #fff;
}

.subscribe-box-wrapper .subscribe_left_bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 40%;
  height: 100%;
  content: "";
  background-color: #211e3b;
}
.subscribe-box-wrapper .cta-contents .icon {
  width: 100px;
  height: 100px;
  border-radius: 30px;
  color: #fff;
  font-size: 50px;
  line-height: 100px;
  text-align: center;
  background-color: #8A038C;
  margin-bottom: 40px;
}
.subscribe-box-wrapper .cta-contents p {
  margin-top: 25px;
  color: #cfcdcc;
  font-size: 20px;
}
.subscribe-box-wrapper .subscribe-form form {
  height: 74px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 55px;
}
.subscribe-box-wrapper .subscribe-form form input {
  border: 0px;
  width: 70%;
  position: relative;
  border-style: solid;
  border-width: 2px;
  border-right-width: 0px;
  border-color: #353839;
  border-radius: 10px 0px 0px 10px;
  background-color: rgba(255, 255, 255, 0.059);
  padding-left: 35px;
  padding-right: 15px;
  line-height: 1;
  color: #fff;
}
.subscribe-box-wrapper .subscribe-form form input::-webkit-input-placeholder {
  color: #999;
}
.subscribe-box-wrapper .subscribe-form form input::-moz-placeholder {
  color: #999;
}
.subscribe-box-wrapper .subscribe-form form input:-ms-input-placeholder {
  color: #999;
}
.subscribe-box-wrapper .subscribe-form form input::-ms-input-placeholder {
  color: #999;
}
.subscribe-box-wrapper .subscribe-form form input::placeholder {
  color: #999;
}
.subscribe-box-wrapper .subscribe-form form button {
  width: 30%;
  height: 100%;
  background: #8A038C;
  color: #000;
  display: inline-block;
  border-radius: 0px 10px 10px 0px;
  font-size: 16px;
  line-height: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-weight: 700;
}
.subscribe-box-wrapper .subscribe-form form button:hover {
  color: #fff;
}

.cta-funfact-wrap {
  background: #211e3b;
}
.cta-funfact-wrap .fun-fact {
  background: #ff5e14;
  height: 140px;
}
.cta-funfact-wrap .fun-fact span {
  font-size: 80px;
  line-height: 1;
  font-weight: 700;
  margin: 0 10px;
}
.cta-funfact-wrap .cta-wrap {
  position: relative;
  z-index: 1;
  height: 140px;
}
.cta-funfact-wrap .cta-wrap::before {
  position: absolute;
  width: 200%;
  height: 100%;
  background-color: #211e3b;
  top: 0;
  left: -15px;
  content: "";
  z-index: -1;
}
.cta-funfact-wrap .cta-wrap h2 {
  font-size: 40px;
  line-height: 130%;
}
.cta-funfact-wrap .cta-wrap a {
  color: #ff5e14;
  text-decoration: underline;
  margin-right: -40px;
}
.cta-funfact-wrap .cta-wrap a i {
  margin-left: 10px;
}

.video_wrapper {
  border-style: solid;
  border-width: 10px;
  border-color: #fff;
  border-radius: 20px;
  background-color: #eee;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
          box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
  height: 475px;
}
@media (max-width: 767px) {
  .video_wrapper {
    height: 300px;
  }
}

.cta-banner-content {
  background-color: #eee;
  border-radius: 10px;
  padding: 50px 0px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.cta-banner-content::before {
  position: absolute;
  width: 100%;
  background-color: #8A038C;
  content: "";
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0.9;
}
.cta-banner-content a {
  background-color: #fff;
  border-color: #fff;
  color: #8A038C;
}

.cta-content-banner-4 {
  background-color: #8A038C;
  padding: 50px 0px;
  -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 78%, 97% 100%, 0 100%, 0 0);
  clip-path: polygon(50% 0%, 100% 0, 100% 78%, 97% 100%, 0 100%, 0 0);
}
@media (max-width: 767px) {
  .cta-content-banner-4 h1 {
    font-size: 28px;
  }
}

.cta-banner-5 {
  padding: 60px 60px 50px;
  border: 1px solid #e8e8eb;
  margin-top: 120px;
}
@media (max-width: 991px) {
  .cta-banner-5 {
    margin-top: 100px;
    padding: 40px 30px 40px;
  }
}
@media (max-width: 767px) {
  .cta-banner-5 {
    padding: 30px;
  }
  .cta-banner-5 p {
    font-size: 15px;
    line-height: 140%;
  }
}
.cta-banner-5 h2 {
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .cta-banner-5 h2 {
    font-size: 28px;
  }
}

.promo-featured-wrapper .featured-img {
  height: 556px;
  background-color: #eee;
  position: relative;
  margin-left: 60px;
  background-size: cover;
  background-position: center;
  margin-right: 60px;
}
.promo-featured-wrapper .top-img {
  width: 180px;
  height: 180px;
  background-color: #eee;
  left: -60px;
  top: -40px;
  position: absolute;
  -webkit-box-shadow: 0px 16px 32px 0px rgba(52, 52, 52, 0.04);
          box-shadow: 0px 16px 32px 0px rgba(52, 52, 52, 0.04);
}
.promo-featured-wrapper .bottom-img {
  border-style: solid;
  border-width: 10px;
  border-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 16px 32px 0px rgba(52, 52, 52, 0.04);
          box-shadow: 0px 16px 32px 0px rgba(52, 52, 52, 0.04);
  background-color: #eee;
  position: absolute;
  width: 269px;
  height: 269px;
  right: -60px;
  bottom: -60px;
}

.features-list .single-item {
  overflow: hidden;
  margin-top: 40px;
}
.features-list .single-item .icon {
  background-color: #fff;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  float: left;
  overflow: hidden;
  margin-right: 40px;
}
.features-list .single-item .content {
  overflow: hidden;
}
.features-list .single-item .content p {
  padding-left: 20px;
  margin-top: 15px;
  position: relative;
}
.features-list .single-item .content p::before {
  position: absolute;
  width: 2px;
  height: 42px;
  left: 0;
  top: 50%;
  content: "";
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: #ff5e14;
}

.video_bg {
  position: absolute;
  width: 50%;
  height: 100%;
  content: "";
  background-size: cover;
  right: 0;
  top: 0;
  z-index: -1;
}

.call-consultation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.call-consultation .icon {
  float: left;
  border-radius: 50%;
  background-color: #8A038C;
  width: 60px;
  height: 60px;
  line-height: 64px;
  font-size: 20px;
  overflow: hidden;
  text-align: center;
  color: #fff;
  margin-right: 20px;
}
.call-consultation .content {
  overflow: auto;
}
.call-consultation .content span {
  font-weight: 500;
  font-size: 15px;
  line-height: 1;
}
.call-consultation .content h5 {
  margin-top: -2px;
}

.consultations-wrapper {
  background-color: #eef4fc;
  background-position: 20px 30px;
  position: relative;
}
.consultations-wrapper::before {
  position: absolute;
  width: 100%;
  height: 130px;
  bottom: 0;
  left: 0;
  content: "";
  background-color: #fff;
  z-index: -1;
}
@media (max-width: 991px) {
  .consultations-wrapper::before {
    height: 100px;
  }
}
@media (max-width: 767px) {
  .consultations-wrapper::before {
    height: 80px;
  }
}

.single-work-process {
  position: relative;
  z-index: 1;
  margin-top: 30px;
  text-align: center;
  padding: 0px 25px;
}
.single-work-process .icon {
  width: 172px;
  height: 172px;
  line-height: 172px;
  text-align: center;
  position: relative;
  color: #8A038C;
  font-size: 70px;
  margin: 0 auto;
}
.single-work-process .icon::before {
  -webkit-clip-path: polygon(27% 0, 100% 0, 100% 82%, 79% 100%, 0 100%, 0 19%);
  clip-path: polygon(27% 0, 100% 0, 100% 82%, 79% 100%, 0 100%, 0 19%);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #eef5fc;
  z-index: -1;
  -webkit-transform: rotate(25deg);
          transform: rotate(25deg);
}
.single-work-process .icon span {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 16px;
  line-height: 40px;
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: #8A038C;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
}
.single-work-process .content {
  margin-top: 40px;
}
.single-work-process .content h3 {
  margin-bottom: 10px;
}
.work-link {
  position: absolute;
  left: 50%;
  top: 40%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  z-index: -1;
}

.team-members-list .single-team-member {
  padding: 0px 30px 30px;
  position: relative;
  z-index: 1;
  border-radius: 7px;
  text-align: center;
  overflow: hidden;
  margin-top: 40px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.team-members-list .single-team-member.style-2 {
  border: 1px solid #efeff1;
  background: transparent;
  padding-top: 30px;
}
.team-members-list .single-team-member.style-2:hover, .team-members-list .single-team-member.style-2.active {
  background: #fff !important;
  border-color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(200, 226, 255, 0.45);
          box-shadow: 0px 10px 60px 0px rgba(200, 226, 255, 0.45);
}
.team-members-list .single-team-member.style-2:hover h4, .team-members-list .single-team-member.style-2.active h4 {
  color: #8A038C;
}
.team-members-list .single-team-member.style-2:hover p, .team-members-list .single-team-member.style-2.active p {
  color: inherit;
}
.team-members-list .single-team-member.style-2::before {
  display: none;
}
.team-members-list .single-team-member.style-2 .social-profile a {
  border: 1px solid #e6f0fb;
  color: #696969;
  background: #e6f0fb;
  font-weight: 400;
}
.team-members-list .single-team-member.style-2 .social-profile a:hover {
  background: #8A038C;
  border-color: #8A038C;
  color: #fff;
}
.team-members-list .single-team-member::before {
  position: absolute;
  background-color: #f5f5f5;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 75%;
  border-radius: 7px;
  z-index: -1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.team-members-list .single-team-member:hover h4, .team-members-list .single-team-member:hover p, .team-members-list .single-team-member.active h4, .team-members-list .single-team-member.active p {
  color: #fff;
}
.team-members-list .single-team-member:hover::before, .team-members-list .single-team-member.active::before {
  background-color: #8A038C;
}
.team-members-list .single-team-member .member-img {
  border-radius: 7px;
  width: 210px;
  height: 210px;
  background-color: #eee;
  margin: 0 auto;
  margin-bottom: 20px;
  background-position: center top;
}
.team-members-list .single-team-member .member-bio {
  margin-bottom: 20px;
}
.team-members-list .single-team-member .member-bio h4, .team-members-list .single-team-member .member-bio p {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.team-members-list .single-team-member .member-bio p {
  line-height: 1;
  margin-top: 5px;
}
.team-members-list .single-team-member .social-profile a {
  display: inline-block;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #84b5eb;
  color: #84b5eb;
  text-align: center;
  font-size: 15px;
  margin: 0 2px;
}
.team-members-list .single-team-member .social-profile a:hover {
  background: #fff;
  color: #8A038C;
  border: 1px solid #fff;
}

.member-details-wrap {
  background-color: #f5f5f5;
  padding: 30px;
  border-radius: 7px;
}
.member-details-wrap .sec-title h2 {
  font-size: 28px;
  margin-bottom: 10px;
  line-height: 130%;
  letter-spacing: 0px;
}
.member-details-wrap .member-skills p, .member-details-wrap .member-about p {
  margin-top: 15px;
}
.member-details-wrap .single-contact-info {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.member-details-wrap .single-contact-info p {
  margin-top: 0;
}
.member-details-wrap .member-card {
  background: #fff;
  border-radius: 7px;
  overflow: hidden;
}
.member-details-wrap .member-card .member-photo {
  border-radius: 7px;
  background-color: #8A038C;
  height: 345px;
  background-position: center top;
}
.member-details-wrap .member-card .member-info {
  text-align: center;
  padding: 30px;
}
.member-details-wrap .member-card .member-info .social-link {
  margin-top: 15px;
}
.member-details-wrap .member-card .member-info .social-link a {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  line-height: 36px;
  display: inline-block;
  background-color: #8A038C;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin: 0 2px;
}
.member-details-wrap .member-card .member-info .social-link a:hover {
  color: #8A038C;
  background-color: #ff5e14;
}

.member-contact-info .single-contact-info {
  margin-top: 5px;
}
.member-contact-info .single-contact-info .icon {
  margin-right: 15px;
  color: #8A038C;
  font-size: 20px;
}

.single-member-card {
  background-color: #fff;
  -webkit-clip-path: polygon(85% 0, 100% 14%, 100% 100%, 50% 100%, 0 100%, 0 0);
  clip-path: polygon(85% 0, 100% 14%, 100% 100%, 50% 100%, 0 100%, 0 0);
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: 30px;
}
.single-member-card:hover .social-profile {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.single-member-card:hover .member-bio {
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}
.single-member-card.style-2 {
  -webkit-clip-path: inherit;
          clip-path: inherit;
  background: #8A038C;
}
.single-member-card.style-2 .member-img {
  height: 300px;
}
.single-member-card.style-2 .member-bio {
  padding: 21.5px 15px;
}
.single-member-card.style-2 .member-bio h4 {
  line-height: 25px;
}
.single-member-card.style-2 .member-bio h4, .single-member-card.style-2 .member-bio p {
  color: #FFF;
}
.single-member-card.style-2 .social-profile {
  bottom: 14px;
  background: #8A038C;
}
.single-member-card.style-2 .social-profile span {
  color: #FFF;
  display: block;
  font-size: 17px;
  line-height: 21px;
  margin-bottom: 10px;
}
.single-member-card.style-2 .social-profile a {
  width: 30px;
  height: 30px;
  color: #FFF;
  margin: 0 7.5px;
  font-size: 15px;
  line-height: 32px;
  border: 1px solid #FFF;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
}
.single-member-card.style-2 .social-profile a:hover {
  background: #FFF;
  color: #8A038C;
}
.single-member-card .member-img {
  height: 271px;
  background-color: #fff;
  background-position: top;
}
.single-member-card .member-bio {
  padding: 25px 15px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.single-member-card .social-profile {
  padding: 0 15px;
  padding-bottom: 25px;
  z-index: -1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: 70px;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
}
.single-member-card .social-profile a {
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 39px;
  border-radius: 50%;
  background-color: #e8e8eb;
  margin: 0 2px;
  text-align: center;
  font-size: 14px;
  color: #000;
}
.single-member-card .social-profile a:hover {
  background-color: #8A038C;
  color: #fff;
}

.single_team_widget {
  position: relative;
  z-index: 1;
}
.single_team_widget .team_img {
  height: 310px;
  width: 100%;
  overflow: hidden;
}
.single_team_widget .team_img .icons {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  position: absolute;
  bottom: 100px;
  left: 60px;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 7px;
}
.single_team_widget .team_img .icons ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.single_team_widget .team_img .icons ul > li {
  display: inline;
}
.single_team_widget .team_img .icons ul > li a {
  width: 40px;
  height: 40px;
  border-radius: 500px;
  border: 1px solid rgba(211, 50, 101, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
}
.single_team_widget .team_img .icons ul > li a i {
  color: #FFF;
}
.single_team_widget .team_img .icons ul > li a:hover {
  background: linear-gradient(132.63deg, #5116AD 15.86%, #E1345D 91.61%);
}
.single_team_widget .content {
  margin-top: 30px;
}
.single_team_widget .content h4 {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #0E1871;
  margin-bottom: 5px;
}
.single_team_widget .content p {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  text-align: center;
  color: #5F637A;
}
.single_team_widget:hover .team_img {
  cursor: pointer;
}
.single_team_widget:hover .team_img .icons {
  opacity: 1 !important;
}

.funfact-wrapper {
  position: relative;
  z-index: 1;
}
.funfact-wrapper.bottom::before {
  bottom: 0;
  top: auto;
}
.funfact-wrapper.no-bg::before {
  display: none;
}
.funfact-wrapper::before {
  position: absolute;
  height: 50%;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #211e3b;
  content: "";
}
@media (min-width: 1500px) {
  .funfact-wrapper .container {
    max-width: 1400px;
    width: 100%;
  }
}
.funfact-wrapper .funfact-content-grid {
  background-color: #8A038C;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.funfact-content-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 7px;
  padding: 80px 0px 70px;
}

.funfact-content-grid > *:nth-child(1) {
  -ms-grid-row: 1; /* Internet Explorer 10 */
  -ms-grid-column: 1; /* Internet Explorer 10 */
  grid-row: 1; /* Standard property */
  grid-column: 1; /* Standard property */
}

.funfact-content-grid > *:nth-child(2) {
  -ms-grid-row: 1; /* Internet Explorer 10 */
  -ms-grid-column: 2; /* Internet Explorer 10 */
  grid-row: 1; /* Standard property */
  grid-column: 2; /* Standard property */
}

.funfact-content-grid > *:nth-child(3) {
  -ms-grid-row: 1; /* Internet Explorer 10 */
  -ms-grid-column: 3; /* Internet Explorer 10 */
  grid-row: 1; /* Standard property */
  grid-column: 3; /* Standard property */
}

.funfact-content-grid > *:nth-child(4) {
  -ms-grid-row: 1; /* Internet Explorer 10 */
  -ms-grid-column: 4; /* Internet Explorer 10 */
  grid-row: 1; /* Standard property */
  grid-column: 4; /* Standard property */
}
@media (max-width: 991px) {
  .funfact-content-grid {
    -ms-grid-columns: 1fr 40px 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
  }
}
@media (max-width: 500px) {
  .funfact-content-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
}
.funfact-content-grid .single-funfact-item .icon {
  font-size: 42px;
  line-height: 1;
  margin-bottom: 20px;
}
.funfact-content-grid .single-funfact-item h3 {
  font-weight: 500;
  font-size: 55px;
  line-height: 1;
  position: relative;
  display: inline-block;
}
.funfact-content-grid .single-funfact-item h3::before {
  position: absolute;
  content: "+";
  right: -20px;
  top: -15px;
  font-size: 30px;
  font-weight: 400;
}
.funfact-content-grid .single-funfact-item p {
  margin-top: 5px;
}

.our-achivement-fact {
  position: relative;
  margin-top: -100px;
  z-index: -1;
  padding-top: 100px;
}
.our-achivement-fact::after {
  position: absolute;
  width: 100%;
  background-color: #2b2843;
  content: "";
  height: 100%;
  left: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.5;
}

.work-process-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 80px 1fr 80px 1fr 80px 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-gap: 80px;
  position: relative;
}

.work-process-grid > *:nth-child(1) {
  -ms-grid-row: 1; /* Internet Explorer 10 */
  -ms-grid-column: 1; /* Internet Explorer 10 */
  grid-row: 1; /* Standard property */
  grid-column: 1; /* Standard property */
}

.work-process-grid > *:nth-child(2) {
  -ms-grid-row: 1; /* Internet Explorer 10 */
  -ms-grid-column: 3; /* Internet Explorer 10 */
  grid-row: 1; /* Standard property */
  grid-column: 3; /* Standard property */
} 

.work-process-grid > *:nth-child(3) {
  -ms-grid-row: 1; /* Internet Explorer 10 */
  -ms-grid-column: 5; /* Internet Explorer 10 */
  grid-row: 1; /* Standard property */
  grid-column: 5; /* Standard property */
}

.work-process-grid > *:nth-child(4) {
  -ms-grid-row: 1; /* Internet Explorer 10 */
  -ms-grid-column: 7; /* Internet Explorer 10 */
  grid-row: 1; /* Standard property */
  grid-column: 7; /* Standard property */
}
@media (max-width: 1199px) {
  .work-process-grid {
    -ms-grid-columns: 1fr 80px 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 80px;
  }
}
@media (max-width: 767px) {
  .work-process-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}
.work-process-grid::after {
  background-image: url("../img/work-process-line.html");
  width: 1543px;
  height: 154px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 30%;
  content: "";
  background-repeat: no-repeat;
  z-index: -1;
}
@media (max-width: 1600px) {
  .work-process-grid::after {
    display: none;
  }
}
.work-process-grid .single-work-process {
  text-align: center;
  margin-top: 30px;
  position: relative;
  z-index: 1;
}
.work-process-grid .single-work-process:nth-child(2), .work-process-grid .single-work-process:nth-child(4) {
  margin-top: 90px;
}
@media (max-width: 1400px) {
  .work-process-grid .single-work-process:nth-child(2), .work-process-grid .single-work-process:nth-child(4) {
    margin-top: 30px;
  }
}
.work-process-grid .single-work-process:nth-child(4) {
  margin-top: 60px;
}
@media (max-width: 1400px) {
  .work-process-grid .single-work-process:nth-child(4) {
    margin-top: 30px;
  }
}
.work-process-grid .single-work-process:hover .icon {
  background-color: #8A038C;
  color: #fff;
}
.work-process-grid .single-work-process .icon {
  border-radius: 50%;
  width: 115px;
  height: 115px;
  border: 10px solid #fff;
  background-color: #e6f0fb;
  color: #8A038C;
  line-height: 100px;
  font-size: 50px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0 auto;
  margin-bottom: 25px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.work-process-grid .single-work-process .icon:hover {
  background-color: #8A038C;
  color: #fff;
}
.work-process-grid .single-work-process .content h3 {
  margin-bottom: 10px;
}
.work-process-grid .single-work-process .content p {
  font-size: 16px;
}
.work-process-grid .single-work-process .content span {
  display: inline-block;
  border: 2px solid #dbe0ea;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  line-height: 32px;
  background: transparent;
  margin: 0 auto;
  margin-top: 15px;
}

.single-skill-bar {
  margin-top: 35px;
}
.single-skill-bar .d-flex {
  margin-bottom: 10px;
}
.single-skill-bar .d-flex span {
  color: #211e3b;
  font-weight: 700;
}
.single-skill-bar .progress {
  height: 3px;
}
.single-skill-bar .progress .progress-bar {
  background-color: #8A038C;
}

.skill-banner {
  position: relative;
}
.skill-banner .skill-popup-video {
  border-width: 10px;
  border-color: rgb(255, 255, 255);
  border-style: solid;
  border-radius: 50%;
  background-color: rgb(191, 191, 191);
  -webkit-box-shadow: 0px 10px 60px 0px rgba(22, 37, 66, 0.4);
          box-shadow: 0px 10px 60px 0px rgba(22, 37, 66, 0.4);
  width: 190px;
  height: 190px;
  z-index: 2;
  position: absolute;
  bottom: 60px;
  left: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
@media (max-width: 991px) {
  .skill-banner .skill-popup-video {
    right: 0;
    left: auto;
  }
}
@media (max-width: 767px) {
  .skill-banner .skill-popup-video {
    right: 0;
    left: auto;
    -webkit-transform: translateX(20%);
            transform: translateX(20%);
  }
}
@media (max-width: 600px) {
  .skill-banner .skill-popup-video {
    right: 0;
    left: auto;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}
.skill-banner .skill-popup-video::before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  background: #211e3b;
  border-radius: 50%;
  left: 0;
  top: 0;
  opacity: 0.7;
  z-index: -1;
}
.skill-banner .skill-popup-video .video-play-btn {
  width: 50px;
  height: 50px;
  text-align: center;
  background-color: #8A038C;
  color: #fff;
  border-radius: 50%;
  line-height: 54px;
}
.skill-banner .skill-popup-video .video-play-btn a {
  color: #fff;
  line-height: 1;
}

.funfact-box-bg {
  width: 100%;
  background-color: #8A038C;
  border-radius: 7px;
  margin: 0px auto;
  padding: 80px 0px;
}
@media (min-width: 1450px) {
  .funfact-box-bg {
    max-width: 1400px;
  }
}

.fun-fact-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-gap: 30px;
  text-align: center;
}

.fun-fact-grid > *:nth-child(1) {
  -ms-grid-row: 1; /* Internet Explorer 10 */
  -ms-grid-column: 1; /* Internet Explorer 10 */
  grid-row: 1; /* Standard property */
  grid-column: 1; /* Standard property */
}

.fun-fact-grid > *:nth-child(2) {
  -ms-grid-row: 1; /* Internet Explorer 10 */
  -ms-grid-column: 3; /* Internet Explorer 10 */
  grid-row: 1; /* Standard property */
  grid-column: 3; /* Standard property */
}
@media (max-width: 500px) {
  .fun-fact-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}
.fun-fact-grid .single-funfact-item {
  background-color: #fff;
  padding: 40px 30px 30px;
  -webkit-clip-path: polygon(13% 0, 100% 0, 100% 100%, 52% 100%, 0 100%, 0 14%);
  clip-path: polygon(13% 0, 100% 0, 100% 100%, 52% 100%, 0 100%, 0 14%);
}
.fun-fact-grid .single-funfact-item .icon {
  font-size: 55px;
  margin-bottom: 10px;
  color: #8A038C;
}
.fun-fact-grid .single-funfact-item h3 {
  font-size: 55px;
  line-height: 1;
}
.prosess-wrapper {
  position: relative;
}
.prosess-wrapper .prosess-element .icon {
  height: 100px;
  width: 100px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: #E6F0FB;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 30px;
}
.prosess-wrapper .prosess-element h4 {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #211e3b;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.prosess-wrapper .prosess-element p {
  font-style: normal;
  font-weight: 450;
  font-size: 17px;
  line-height: 32px;
  text-align: center;
}
.prosess-wrapper .prosess-element span {
  position: absolute;
  top: 10%;
  left: 12%;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  text-transform: uppercase;
  color: #8A038C;
}
@media (max-width: 995px) {
  .prosess-wrapper .mt-70 {
    margin-top: 0px !important;
  }
}
@media (max-width: 995px) {
  .prosess-wrapper .mt-40 {
    margin-top: 0px !important;
  }
}
.prosess-wrapper .pro-line {
  z-index: -1;
  position: absolute;
  top: 45%;
  left: 20%;
}
@media (max-width: 1920px) {
  .prosess-wrapper .pro-line {
    left: 29%;
  }
}
@media (max-width: 1600px) {
  .prosess-wrapper .pro-line {
    left: 24%;
  }
}
@media (max-width: 1366px) {
  .prosess-wrapper .pro-line {
    left: 16%;
  }
}
@media (max-width: 995px) {
  .prosess-wrapper .pro-line {
    display: none !important;
  }
}

.funfact-wrapper_2 .funfact-content-grid {
  padding-bottom: 150px;
}
@media (max-width: 768px) {
  .funfact-wrapper_2 .funfact-content-grid {
    padding-bottom: 80px;
  }
}
.funfact-wrapper_2 .single-funfact-item h3 {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 75px;
  text-transform: uppercase;
  color: #FFF;
}
.funfact-wrapper_2 .single-funfact-item h3::before {
  content: "" !important;
}
.funfact-wrapper_2 .single-funfact-item p {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 32px;
  text-transform: capitalize;
  color: #FFF;
}

.brand-carousel-active {
  border-top: 1px solid #e8e8eb;
}

.client-brand-wrap .single-client img {
  -webkit-filter: brightness(10);
          filter: brightness(10);
}

.agent__wrapper {
  position: relative;
}
.agent__wrapper .owl-nav .owl-prev, .agent__wrapper .owl-nav .owl-next {
  position: absolute;
  top: -30%;
  right: 0%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #8A038C;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
          transform: matrix(-1, 0, 0, 1, 0, 0);
  z-index: 5;
}
@media (max-width: 500px) {
  .agent__wrapper .owl-nav .owl-prev, .agent__wrapper .owl-nav .owl-next {
    display: none;
  }
}
.agent__wrapper .owl-nav .owl-prev i, .agent__wrapper .owl-nav .owl-next i {
  color: #8A038C;
}
.agent__wrapper .owl-nav .owl-prev::after, .agent__wrapper .owl-nav .owl-next::after {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  z-index: -1;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  clear: both;
  background-color: #8A038C;
  visibility: hidden;
  opacity: 0;
}
.agent__wrapper .owl-nav .owl-prev:hover i, .agent__wrapper .owl-nav .owl-next:hover i {
  color: #FFF;
}
.agent__wrapper .owl-nav .owl-prev:hover::after, .agent__wrapper .owl-nav .owl-next:hover::after {
  visibility: visible;
  opacity: 1;
}
.agent__wrapper .owl-nav .owl-next {
  right: 7%;
}
@media (max-width: 995px) {
  .agent__wrapper .owl-nav .owl-next {
    right: 15%;
  }
}

.testimonial_widget .owl-nav {
  margin-top: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 30px;
}
.testimonial_widget .owl-nav .icon-arrow-left, .testimonial_widget .owl-nav .icon-arrow-right {
  background: -webkit-gradient(linear, left top, right top, color-stop(75.47%, #E54C62), color-stop(96.12%, #642AAB));
  background: linear-gradient(90deg, #E54C62 75.47%, #642AAB 96.12%);
  font-weight: 900;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.faq-section {
  position: relative;
  background-color: #f8f8f8;
}

.faq-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  content: "";
  background-color: #eee;
}

.accordion {
  color: #777;
}
.accordion.accordion_2 .card-header {
  background: -webkit-gradient(linear, left top, right top, from(#4513B3), to(#EC3656));
  background: linear-gradient(90deg, #4513B3 0%, #EC3656 100%);
  border-radius: 4px;
}
.accordion.accordion_2 .card-header a {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: #FFF;
  padding: 15px 20px;
}
.accordion.accordion_2 .card-header a:hover {
  color: #FFF;
}
.accordion.accordion_2 .card-header a:after {
  border: 1px dashed #FFFFFF;
  border-radius: 500px;
  padding: 4px;
  content: "\f067";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  right: 0;
  top: 50%;
  font-weight: 700;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #FFF;
}
.accordion .card {
  margin-top: 15px;
  border: none;
  border-radius: 7px !important;
}
.accordion .card-header {
  padding: 0px 28px;
  border: none;
  background-color: transparent;
}
@media (max-width: 480px) {
  .accordion .card-header {
    padding: 0 15px;
  }
}
.accordion .card-header a {
  display: block;
  font-weight: 600;
  line-height: 50px;
  padding: 10px 0px;
  position: relative;
  color: #000;
  font-size: 18px;
}
.accordion .card-header a:after {
  content: "\f067";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  right: 0;
  top: 50%;
  font-weight: 700;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #8A038C;
}
.accordion .card-header a[aria-expanded=true]:after {
  content: "\f068";
  /* <i class="fal fa-angle-up"></i> */
}
@media (max-width: 767px) {
  .accordion .card-header a {
    line-height: 40px;
    font-size: 15px;
  }
}
.accordion .card-header a:hover {
  color: #8A038C;
}
.accordion .card-body {
  padding-left: 28px;
  padding-top: 10px;
  padding-bottom: 30px;
}

.single-faq-card {
  text-align: center;
  position: relative;
  z-index: 1;
  padding: 60px 45px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 991px) {
  .single-faq-card {
    margin-bottom: 40px;
  }
}
.single-faq-card::before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  background: #000;
  opacity: 0.6;
  z-index: -1;
  left: 0;
  top: 0;
}
.single-faq-card .icon {
  width: 120px;
  height: 120px;
  line-height: 120px;
  margin: 0 auto;
  display: inline-block;
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  margin-bottom: 30px;
}
.single-faq-card h3 {
  color: #fff;
  margin-bottom: 10px;
}
.single-faq-card p {
  color: #b1b5b7;
}
.single-faq-card a {
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin: 0 auto;
  display: inline-block;
  background-color: #00baa3;
  border-radius: 50%;
  text-align: center;
  margin-top: 20px;
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.single-faq-card a:hover {
  color: #00baa3;
  background: #fff;
}
.single-faq-card.card2 a {
  background-color: #d55342;
}
.single-faq-card.card2 a:hover {
  color: #d55342;
  background: #fff;
}
.single-faq-card.card3 a {
  background-color: #efc94c;
}
.single-faq-card.card3 a:hover {
  color: #efc94c;
  background: #fff;
}

.single-blog-card {
  background-color: #fff;
  border-radius: 7px;
  padding: 20px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-top: 40px;
  position: relative;
}
.single-blog-card.single-blog-card_2 {
  padding: 0;
  margin-top: 30px;
  border-radius: 0;
}
.single-blog-card.single-blog-card_2 .blog-featured-thumb {
  height: 400px !important;
  border-radius: 10;
}
.single-blog-card.single-blog-card_2 .blog-featured-thumb_2 {
  width: 200px !important;
  height: 200px !important;
}
.single-blog-card.single-blog-card_2 .content {
  z-index: 1;
  padding: 28px;
  position: relative;
  padding-bottom: 30px;
}
@media (max-width: 1199px) {
  .single-blog-card.single-blog-card_2 .content {
    padding: 25px;
  }
}
.single-blog-card.single-blog-card_2 .content .post-top-meta {
  gap: 0 30px;
  position: absolute;
  top: -10px;
}
@media (max-width: 500px) {
  .single-blog-card.single-blog-card_2 .content .post-top-meta {
    margin-top: 5px;
    position: relative;
    top: 0px;
  }
}
.single-blog-card.single-blog-card_2 .content .post-top-meta a {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  color: #5F637A;
  padding: 8px 16px;
  background: -webkit-gradient(linear, left top, right top, from(#ECE7F7), to(#FDEBEE));
  background: linear-gradient(90deg, #ECE7F7 0%, #FDEBEE 100%);
  border-radius: 4px;
}
.single-blog-card.single-blog-card_2 .content .post-top-meta a i {
  margin-right: 10px;
}
.single-blog-card.single-blog-card_2 .content .post-top-meta a:hover:-moz-any-link {
  color: #8A038C;
}
.single-blog-card.single-blog-card_2 .content .post-top-meta a:hover:any-link {
  color: #8A038C;
}
.single-blog-card.single-blog-card_2 .content h3 {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #0E1871;
  margin: 15px 0 5px;
}
@media (max-width: 1199px) {
  .single-blog-card.single-blog-card_2 .content h3 {
    margin: 10px 0 3px;
  }
}
.single-blog-card.single-blog-card_2 .content h3:hover {
  color: #8A038C;
}
.single-blog-card.single-blog-card_2 .author {
  margin-top: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 15px;
}
.single-blog-card.single-blog-card_2 .author .author_img {
  height: 40px;
  width: 40px;
  border-radius: 500px;
  overflow: hidden;
}
.single-blog-card.single-blog-card_2 .author .author_img img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.single-blog-card.single-blog-card_2 .author h5 {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: #0E1871;
}
.single-blog-card.single-blog-card_2:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.single-blog-card.single-blog-card_3 {
  padding: 0;
  margin-top: 30px;
  border-radius: 0;
}
.single-blog-card.single-blog-card_3 .blog-featured-thumb {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 10;
}
@media (max-width: 576px) {
  .single-blog-card.single-blog-card_3 .blog-featured-thumb {
    width: auto !important;
  }
}
.single-blog-card.single-blog-card_3 .blog-featured-thumb .author_img {
  position: absolute;
  left: 10px;
  bottom: 10px;
  height: 40px;
  width: 40px;
  border-radius: 500px;
  overflow: hidden;
}
.single-blog-card.single-blog-card_3 .blog-featured-thumb .author_img img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.single-blog-card.single-blog-card_3 .content {
  z-index: 1;
  padding: 20px;
  position: relative;
}
.single-blog-card.single-blog-card_3 .content .post-top-meta {
  gap: 10px;
}
.single-blog-card.single-blog-card_3 .content .post-top-meta a {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  color: #5F637A;
  padding: 8px 16px;
  background: -webkit-gradient(linear, left top, right top, from(#ECE7F7), to(#FDEBEE));
  background: linear-gradient(90deg, #ECE7F7 0%, #FDEBEE 100%);
  border-radius: 4px;
}
.single-blog-card.single-blog-card_3 .content .post-top-meta a i {
  margin-right: 10px;
}
.single-blog-card.single-blog-card_3 .content .post-top-meta a:hover:-moz-any-link {
  color: #8A038C;
}
.single-blog-card.single-blog-card_3 .content .post-top-meta a:hover:any-link {
  color: #8A038C;
}
.single-blog-card.single-blog-card_3 .content h3 {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #0E1871;
  margin: 15px 0 5px;
}
@media (max-width: 1199px) {
  .single-blog-card.single-blog-card_3 .content h3 {
    margin: 10px 0 3px;
  }
}
.single-blog-card.single-blog-card_3 .content h3:hover {
  color: #8A038C;
}
.single-blog-card.single-blog-card_3:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}
@media (max-width: 991px) {
  .single-blog-card {
    margin-top: 30px;
  }
}
.single-blog-card:hover, .single-blog-card.active {
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(200, 226, 255, 0.45);
          box-shadow: 0px 10px 60px 0px rgba(200, 226, 255, 0.45);
}
.single-blog-card.style-2 {
  -webkit-box-shadow: 0px 10px 60px 0px rgba(200, 226, 255, 0.45);
          box-shadow: 0px 10px 60px 0px rgba(200, 226, 255, 0.45);
  background-color: #fff;
  padding: 0;
}
.single-blog-card.style-3 {
  padding: 0;
  margin-top: 30px;
  border-radius: 0;
  border: 1px solid rgba(33, 30, 59, 0.1);
}
.single-blog-card.style-3 .blog-featured-thumb {
  height: 200px;
  border-radius: 0;
}
.single-blog-card.style-3 .content {
  z-index: 1;
  padding: 28px;
  position: relative;
  padding-bottom: 30px;
}
@media (max-width: 1199px) {
  .single-blog-card.style-3 .content {
    padding: 25px;
  }
}
.single-blog-card.style-3 .content:before {
  top: 0;
  left: 0;
  width: 100%;
  content: "";
  z-index: -1;
  height: 0%;
  position: absolute;
  background: #EBF1FF;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.single-blog-card.style-3 .content .post-top-meta {
  gap: 0 30px;
}
.single-blog-card.style-3 .content .post-top-meta a {
  font-size: 15px;
  font-weight: 400;
  line-height: 17px;
  font-family: roboto;
}
.single-blog-card.style-3 .content .post-top-meta a i {
  margin-right: 10px;
}
.single-blog-card.style-3 .content .post-top-meta a:hover:-moz-any-link {
  color: #8A038C;
}
.single-blog-card.style-3 .content .post-top-meta a:hover:any-link {
  color: #8A038C;
}
.single-blog-card.style-3 .content h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  margin: 15px 0 5px;
}
@media (max-width: 1199px) {
  .single-blog-card.style-3 .content h3 {
    margin: 10px 0 3px;
  }
}
.single-blog-card.style-3 .content h3:hover {
  color: #8A038C;
}
.single-blog-card.style-3 .content .btn-link-share {
  gap: 10px;
  margin-top: 15px;
}
@media (max-width: 1199px) {
  .single-blog-card.style-3 .content .btn-link-share {
    margin-top: 10px;
  }
}
.single-blog-card.style-3 .content .btn-link-share a {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #8A038C;
  text-transform: uppercase;
}
.single-blog-card.style-3 .content .btn-link-share a:first-child i {
  margin-left: 15px;
}
.single-blog-card.style-3 .content .btn-link-share a:last-child {
  margin: 0;
  color: rgba(33, 30, 59, 0.3);
}
.single-blog-card.style-3 .content .btn-link-share a:hover {
  color: #211e3b !important;
}
.single-blog-card.style-3:hover .content:before {
  height: 100%;
}
.single-blog-card .future-elem {
  position: absolute;
  top: 10px;
  left: 10px;
}
.single-blog-card .future-elem p {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 17px;
  line-height: 100%;
  color: #FFF;
  padding: 8px 16px;
  background: #8A038C;
  border-radius: 6px;
}
.single-blog-card .blog-featured-thumb {
  border-radius: 7px;
  background-color: #eee;
  height: 210px;
}
.single-blog-card .content {
  padding: 30px;
  padding-bottom: 40px;
}
.single-blog-card .content .post-author a {
  color: #696969;
}
.single-blog-card .content h3 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}
.single-blog-card .content .btn-link-share {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.single-blog-card .content .btn-link-share .theme-btn.minimal-btn {
  font-size: 14px;
  line-height: 1;
  color: #696969;
  border: 1px solid #e8e8eb;
  padding: 20px 30px;
}
.single-blog-card .content .btn-link-share .theme-btn.minimal-btn:hover {
  background-color: #8A038C;
  color: #fff;
  border: 1px solid #8A038C;
}
.single-blog-card .content .btn-link-share a:last-child {
  color: #696969;
  margin-left: 20px;
  font-size: 20px;
}
.single-blog-card .content .btn-link-share a:last-child:hover {
  color: #8A038C;
}

.single-news-card {
  position: relative;
  margin-top: 30px;
}
.single-news-card .blog-featured-thumb {
  height: 350px;
  background-color: #eee;
  position: relative;
  -webkit-clip-path: polygon(13% 0, 100% 0, 100% 100%, 52% 100%, 0 100%, 0 14%);
  clip-path: polygon(13% 0, 100% 0, 100% 100%, 52% 100%, 0 100%, 0 14%);
}
.single-news-card .blog-featured-thumb .date {
  display: inline-block;
  padding: 15px;
  background-color: #8A038C;
  color: #fff;
  line-height: 1;
  text-align: center;
  position: absolute;
  right: 15px;
  top: 15px;
}
.single-news-card .blog-featured-thumb .date span {
  font-weight: 500;
  font-size: 25px;
  display: block;
  margin-bottom: 3px;
}
.single-news-card .content {
  padding: 30px;
  padding-bottom: 40px;
  border: 1px solid #f8f8f8;
  border-top: 0px;
}
.single-news-card .content .post-author a {
  color: #696969;
}
.single-news-card .content h3 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
}
.single-news-card .content .btn-link-share {
  margin-top: 20px;
}
.single-news-card .content .btn-link-share .theme-btn.off-white {
  font-size: 14px;
  line-height: 1;
  color: #696969;
  border: 1px solid #e8e8eb;
  padding: 20px 30px;
}
.single-news-card .content .btn-link-share .theme-btn.off-white:hover {
  background-color: #8A038C;
  color: #fff;
  border: 1px solid #8A038C;
}

.blog-wrapper .border-wrap {
  padding: 50px;
}
@media (max-width: 767px) {
  .blog-wrapper .border-wrap {
    padding: 50px 15px;
  }
}
.blog-wrapper .border-wrap .single-blog-post .post-content {
  padding: 0px;
}
.blog-wrapper .single-blog-post {
  margin-bottom: 40px;
  position: relative;
}
.blog-wrapper .single-blog-post.post-details .post-content {
  border: none;
}
.blog-wrapper .single-blog-post.post-details .post-content img {
  margin-top: 20px;
  margin-bottom: 20px;
}
.blog-wrapper .single-blog-post.post-details .post-content h1 {
  font-size: 36px;
  line-height: 1.4;
}
@media (max-width: 767px) {
  .blog-wrapper .single-blog-post.post-details .post-content h1 {
    font-size: 28px;
  }
  .blog-wrapper .single-blog-post.post-details .post-content h3 {
    font-size: 20px;
  }
}
.blog-wrapper .single-blog-post.post-details blockquote, .blog-wrapper .single-blog-post.post-details .wp-block-quote {
  background-color: #211e3b;
  color: #fff;
  font-size: 26px;
  line-height: 1.4;
  font-family: "Circular Std", sans-serif;
  font-weight: 400;
  padding: 60px;
  text-align: left;
  margin: 40px 0px;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .blog-wrapper .single-blog-post.post-details blockquote, .blog-wrapper .single-blog-post.post-details .wp-block-quote {
    padding: 30px 15px;
    font-size: 18px;
    line-height: 1.5;
  }
}
.blog-wrapper .single-blog-post.post-details blockquote::before, .blog-wrapper .single-blog-post.post-details .wp-block-quote::before {
  right: 30px;
  font-size: 110px;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  content: "\f10d";
  bottom: -20px;
  color: #fff;
  z-index: -1;
  opacity: 0.1;
  font-weight: 900;
}
.blog-wrapper .single-blog-post.format-video .post-featured-thumb, .blog-wrapper .single-blog-post.category-video-post .post-featured-thumb {
  z-index: 1;
}
.blog-wrapper .single-blog-post.format-video .post-featured-thumb::before, .blog-wrapper .single-blog-post.category-video-post .post-featured-thumb::before {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  content: "";
  background-color: #282835;
  opacity: 0.3;
  z-index: -1;
}
.blog-wrapper .single-blog-post.quote-post .post-content, .blog-wrapper .single-blog-post.format-quote .post-content {
  position: relative;
  background-color: #8A038C;
  background-image: url("../img/quotepost.html");
}
.blog-wrapper .single-blog-post.quote-post .quote-content, .blog-wrapper .single-blog-post.format-quote .quote-content {
  overflow: hidden;
}
@media (max-width: 585px) {
  .blog-wrapper .single-blog-post.quote-post .quote-content, .blog-wrapper .single-blog-post.format-quote .quote-content {
    text-align: center;
    font-size: 24px;
  }
}
.blog-wrapper .single-blog-post.quote-post .quote-content .icon, .blog-wrapper .single-blog-post.format-quote .quote-content .icon {
  font-size: 76px;
  line-height: 80px;
  float: left;
  overflow: hidden;
  margin-right: 30px;
}
@media (max-width: 585px) {
  .blog-wrapper .single-blog-post.quote-post .quote-content .icon, .blog-wrapper .single-blog-post.format-quote .quote-content .icon {
    float: none;
    margin-bottom: 10px;
    margin-right: 0;
    font-size: 50px;
    line-height: 60px;
  }
}
.blog-wrapper .single-blog-post.quote-post .quote-content .quote-text, .blog-wrapper .single-blog-post.format-quote .quote-content .quote-text {
  overflow: hidden;
}
.blog-wrapper .single-blog-post.quote-post .quote-content h2, .blog-wrapper .single-blog-post.format-quote .quote-content h2 {
  margin-top: -2px;
}
.blog-wrapper .single-blog-post:last-child {
  margin-bottom: 0;
}
.blog-wrapper .single-blog-post .post-featured-thumb {
  height: 460px;
  width: 100%;
  position: relative;
  background-color: #f2f2f2;
}
@media (max-width: 991px) {
  .blog-wrapper .single-blog-post .post-featured-thumb {
    height: 350px;
  }
}
@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-featured-thumb {
    height: 280px;
  }
}
.blog-wrapper .single-blog-post .post-featured-thumb .video-play-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2;
}
.blog-wrapper .single-blog-post .post-featured-thumb .video-play-btn .play-video {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 18px;
}
.blog-wrapper .single-blog-post .post-content {
  padding: 50px;
  border: 2px solid #ededed;
  border-top: 0px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-content {
    padding: 50px 10px;
  }
  .blog-wrapper .single-blog-post .post-content h2 {
    font-size: 26px;
    line-height: 1.4;
  }
}
@media (max-width: 414px) {
  .blog-wrapper .single-blog-post .post-content h2 {
    font-size: 22px;
  }
}
.blog-wrapper .single-blog-post .post-content .post-cat a {
  color: #fff;
  display: inline-block;
  background-color: #8A038C;
  padding: 9px 18px;
  line-height: 1;
  font-size: 14px;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-content .post-cat a {
    font-size: 12px;
  }
}
.blog-wrapper .single-blog-post .post-content .post-cat a:hover {
  background-color: #211e3b;
}
.blog-wrapper .single-blog-post .post-content ul {
  margin-bottom: 20px;
  margin-top: 15px;
}
@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-content ul {
    margin-left: 0;
  }
}
.blog-wrapper .single-blog-post .post-content ul li {
  font-size: 16px;
  color: #727475;
  font-weight: 700;
  margin-top: 10px;
  padding-left: 30px;
  display: block;
}
.blog-wrapper .single-blog-post .post-content ul li::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  color: #222;
}
.blog-wrapper .single-blog-post .post-content p {
  margin-top: 15px;
}
.blog-wrapper .single-blog-post .post-content .post-meta {
  margin-top: 10px;
}
.blog-wrapper .single-blog-post .post-content .post-meta span {
  color: #202426;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  display: inline-block;
  margin-right: 25px;
}
@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-content .post-meta span {
    font-size: 13px;
  }
}
.blog-wrapper .single-blog-post .post-content .post-meta span i {
  margin-right: 5px;
}
.blog-wrapper .single-blog-post .post-content .author-info {
  overflow: hidden;
}
.blog-wrapper .single-blog-post .post-content .author-info .author-img {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  background-color: #f2f2f2;
  margin-right: 15px;
  float: left;
  overflow: hidden;
  background-position: center;
  background-size: cover;
}
@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-content .author-info .author-img {
    margin-right: 5px;
  }
}
.blog-wrapper .single-blog-post .post-content .author-info h5 {
  display: inline-block;
  line-height: 1;
  font-size: 16px;
  margin-top: 9px;
}
@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-content .author-info h5 {
    font-size: 14px;
  }
}
.blog-wrapper .single-blog-post .post-content .post-link a {
  font-weight: 700;
}
@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-content .post-link a {
    font-size: 14px;
  }
}
.blog-wrapper .single-blog-post .post-content .post-link a i {
  margin-right: 5px;
}
@media (max-width: 767px) {
  .blog-wrapper .single-blog-post .post-content .post-link a i {
    margin-right: 3px;
  }
}

@media (min-width: 991px) {
  .main-sidebar {
    margin-left: 20px;
  }
}
@media (max-width: 991px) {
  .main-sidebar {
    margin-top: 40px;
  }
}
.main-sidebar .single-sidebar-widget {
  border: 2px solid #ededed;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 40px;
  margin-bottom: 40px;
}
.main-sidebar .single-sidebar-widget:last-child {
  margin-bottom: 0;
}
.main-sidebar .single-sidebar-widget .wid-title {
  margin-bottom: 35px;
  position: relative;
  padding-left: 45px;
}
.main-sidebar .single-sidebar-widget .wid-title::before {
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  content: "";
  height: 4px;
  width: 18px;
  border-radius: 5px;
  position: absolute;
  background-color: #8A038C;
}
.main-sidebar .single-sidebar-widget .wid-title::after {
  left: 22px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  content: "";
  height: 4px;
  width: 4px;
  border-radius: 50%;
  position: absolute;
  background-color: #8A038C;
}
.main-sidebar .single-sidebar-widget .social-link a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  background-color: #f2f2f2;
  font-size: 14px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.main-sidebar .single-sidebar-widget .social-link a:hover {
  background-color: #8A038C;
  color: #fff;
}
.main-sidebar .author-box-widegts .author-img {
  width: 140px;
  height: 140px;
  line-height: 140px;
  background-color: #f2f2f2;
  border-radius: 50%;
  margin: 0 auto;
  display: inline-block;
  margin-bottom: 15px;
}
.main-sidebar .author-box-widegts p {
  font-size: 14px;
  margin-top: 10px;
}
.main-sidebar .author-box-widegts .social-profile {
  margin-top: 15px;
}
.main-sidebar .author-box-widegts .social-profile a {
  display: inline-block;
  margin-top: 10px;
  margin-right: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.main-sidebar .author-box-widegts .social-profile a:hover {
  color: #8A038C;
}

.search_widget form {
  width: 100%;
  position: relative;
}
.search_widget form input {
  background-color: #f8f8f8;
  font-size: 15px;
  padding: 20px;
  width: 100%;
}
.search_widget form button {
  position: absolute;
  right: 0;
  top: 0;
  width: 70px;
  font-size: 18px;
  height: 100%;
  background-color: #8A038C;
  color: #fff;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.search_widget form button:hover {
  background-color: #211e3b;
}

.instagram-gallery, .instagram_gallery {
  width: 100%;
  overflow: hidden;
  margin-left: -5px;
}
.instagram-gallery .single-photo-item, .instagram_gallery .single-photo-item {
  float: left;
  margin-left: 4%;
  background-color: #f8f8f8;
  height: 80px;
  width: 29%;
  overflow: hidden;
  margin-bottom: 10px;
}

.popular-posts .single-post-item, .popular_posts .single-post-item {
  overflow: hidden;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #f2f2f2;
}
.popular-posts .single-post-item:last-child, .popular_posts .single-post-item:last-child {
  margin-bottom: 0;
  border: none;
  padding-bottom: 0;
}
.popular-posts .single-post-item .thumb, .popular_posts .single-post-item .thumb {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #f2f2f2;
  float: left;
  overflow: hidden;
  margin-right: 20px;
}
.popular-posts .single-post-item .post-content, .popular_posts .single-post-item .post-content {
  overflow: hidden;
}
.popular-posts .single-post-item .post-content h5, .popular_posts .single-post-item .post-content h5 {
  font-size: 16px;
  line-height: 22px;
}
.popular-posts .single-post-item .post-content h5:hover, .popular_posts .single-post-item .post-content h5:hover {
  color: #8A038C;
}
.popular-posts .single-post-item .post-content .post-date, .popular_posts .single-post-item .post-content .post-date {
  margin-top: 5px;
  font-weight: 700;
  font-size: 14px;
}
.popular-posts .single-post-item .post-content .post-date i, .popular_posts .single-post-item .post-content .post-date i {
  margin-right: 7px;
}

.tagcloud a {
  display: inline-block;
  padding: 11px 26px;
  line-height: 1;
  font-size: 14px;
  font-weight: 700;
  background: #f8f8f8;
  margin-right: 5px;
  margin-bottom: 8px;
}
.tagcloud a:last-child {
  margin-right: 0;
}
.tagcloud a:hover {
  background-color: #8A038C;
  color: #fff;
}

.widget_categories ul li {
  display: block;
}
.widget_categories ul li:last-child a {
  margin-bottom: 0;
}
.widget_categories ul li a {
  position: relative;
  background: #f8f8f8;
  padding: 20px 30px;
  line-height: 1;
  font-size: 14px;
  font-weight: 700;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-bottom: 10px;
}
.widget_categories ul li a:hover {
  color: #ff5e14;
}
.widget_categories ul li a span {
  position: absolute;
  width: 60px;
  line-height: 53px;
  height: 100%;
  content: "";
  background-color: #e3e3e3;
  right: 0;
  top: 0;
  text-align: center;
}

.causes-page-nav ul li, .page-nav-wrap ul li {
  display: inline-block;
}
.causes-page-nav ul li a, .page-nav-wrap ul li a {
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  background: #f6f6f6;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin: 0 2px;
}
@media (max-width: 767px) {
  .causes-page-nav ul li a, .page-nav-wrap ul li a {
    margin-top: 10px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
  }
}
.causes-page-nav ul li a i, .page-nav-wrap ul li a i {
  margin-top: 2px;
}
.causes-page-nav ul li a:hover, .page-nav-wrap ul li a:hover {
  background-color: #8A038C;
  color: #fff;
}

.social-share a {
  margin-left: 10px;
}

.tag-share-wrap {
  padding-bottom: 50px;
}
.tag-share-wrap h4 {
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .tag-share-wrap h4 {
    margin-bottom: 10px;
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .tag-share-wrap .tagcloud {
    margin-bottom: 20px;
  }
}

.related-post-wrap, .related_posts_wrap {
  border-top: 1px solid #e2e2e2;
  padding-top: 40px;
}
.related-post-wrap .single-related-post, .related_posts_wrap .single-related-post {
  margin-top: 33px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(93, 93, 93, 0.06);
          box-shadow: 0px 8px 16px 0px rgba(93, 93, 93, 0.06);
}
.related-post-wrap .single-related-post:hover h4, .related_posts_wrap .single-related-post:hover h4 {
  color: #ff5e14;
}
.related-post-wrap .single-related-post .featured-thumb, .related_posts_wrap .single-related-post .featured-thumb {
  height: 280px;
  background-color: #f8f8f8;
}
@media (max-width: 767px) {
  .related-post-wrap .single-related-post .featured-thumb, .related_posts_wrap .single-related-post .featured-thumb {
    height: 200px;
  }
}
.related-post-wrap .single-related-post .post-content, .related_posts_wrap .single-related-post .post-content {
  padding: 35px;
}
@media (max-width: 767px) {
  .related-post-wrap .single-related-post .post-content, .related_posts_wrap .single-related-post .post-content {
    padding: 30px 15px;
  }
}
.related-post-wrap .single-related-post .post-content .post-date, .related_posts_wrap .single-related-post .post-content .post-date {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 14px;
  color: #202426;
}
.related-post-wrap .single-related-post .post-content .post-date i, .related_posts_wrap .single-related-post .post-content .post-date i {
  margin-right: 6px;
}
.related-post-wrap .single-related-post .post-content h4, .related_posts_wrap .single-related-post .post-content h4 {
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .related-post-wrap .single-related-post .post-content h4, .related_posts_wrap .single-related-post .post-content h4 {
    font-size: 18px;
  }
}
.related-post-wrap .single-related-post .post-content p, .related_posts_wrap .single-related-post .post-content p {
  font-size: 15px;
  line-height: 26px;
  color: #727475;
}

.comments-section-wrap {
  overflow: hidden;
}

.comments-heading h3, .related-post-wrap h3, .comment-form-wrap h3 {
  font-size: 26px;
}
@media (max-width: 767px) {
  .comments-heading h3, .related-post-wrap h3, .comment-form-wrap h3 {
    font-size: 20px;
  }
}

.comments-item-list .replay-comment {
  margin-left: 100px;
}
@media (max-width: 767px) {
  .comments-item-list .replay-comment {
    margin-left: 15px;
  }
}
.comments-item-list .single-comment-item {
  margin-top: 30px;
}
.comments-item-list .single-comment-item .author-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  float: left;
  overflow: hidden;
  margin-right: 30px;
}
@media (max-width: 585px) {
  .comments-item-list .single-comment-item .author-img {
    float: none;
    margin-bottom: 20px;
  }
}
.comments-item-list .single-comment-item .author-info-comment {
  overflow: auto;
  padding-bottom: 25px;
  border-bottom: 1px solid #e2e2e2;
}
.comments-item-list .single-comment-item .author-info-comment .info {
  position: relative;
}
@media (max-width: 767px) {
  .comments-item-list .single-comment-item .author-info-comment .info h5 {
    font-size: 16px;
  }
}
.comments-item-list .single-comment-item .author-info-comment span {
  color: #8A038C;
}
.comments-item-list .single-comment-item .author-info-comment .theme-btn {
  padding: 10px 18px;
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #000;
}
@media (max-width: 767px) {
  .comments-item-list .single-comment-item .author-info-comment .theme-btn {
    font-size: 12px;
  }
}
.comments-item-list .single-comment-item .author-info-comment .theme-btn i {
  margin-right: 5px;
  margin-left: 0;
}
.comments-item-list .single-comment-item .author-info-comment .comment-text {
  margin-top: 15px;
}

.comment-form {
  background-color: #f0f0f0;
  padding: 50px;
  width: 100%;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .comment-form {
    padding: 30px;
  }
}
@media (max-width: 585px) {
  .comment-form {
    padding: 30px 15px;
  }
}
.comment-form input, .comment-form textarea {
  margin-bottom: 20px;
  padding: 24px 30px;
  line-height: 1;
  font-size: 14px;
  width: 100%;
}
@media (max-width: 585px) {
  .comment-form input, .comment-form textarea {
    padding: 15px;
  }
}
.comment-form textarea {
  height: 200px;
}
@media (max-width: 767px) {
  .comment-form textarea {
    height: 120px;
  }
}
.comment-form .submit-btn {
  display: inline-block;
  background-color: #8A038C;
  color: #fff;
  line-height: 1;
  font-size: 700;
  text-transform: capitalize;
  padding: 24px 60px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 767px) {
  .comment-form .submit-btn {
    padding: 20px 40px;
  }
}
@media (max-width: 585px) {
  .comment-form .submit-btn {
    padding: 15px 40px;
    font-size: 14px;
  }
}
.comment-form .submit-btn:hover {
  background-color: #211e3b;
}
.comment-form .submit-btn i {
  margin-right: 10px;
}

.border-wrap {
  border: 2px solid #ededed;
}

.agent__wrapper .agent-item {
  position: relative;
  padding: 40px;
  background-color: #FFF;
  border-radius: 6px;
}
.agent__wrapper .agent-item .agent-content .agent-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}
.agent__wrapper .agent-item .agent-content .agent-content_name h4 {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
  color: #211E3B;
}
.agent__wrapper .agent-item .agent-content .agent-content_name p {
  margin: 5px 0px;
}
.agent__wrapper .agent-item p {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-transform: capitalize;
  margin: 30px 0px;
}
.agent__wrapper .agent-item .quera img {
  position: absolute;
  top: 30px;
  right: 60px;
}

/* ----------------------------------
	Contact Us Section Styles
 ------------------------------------ */
.contact-form {
  padding-top: 40px;
}
@media (max-width: 585px) {
  .contact-form {
    padding-top: 20px;
  }
}
.contact-form .single-personal-info {
  margin-bottom: 20px;
}
.contact-form input, .contact-form textarea {
  width: 100%;
  background: #f8f8f8;
  line-height: 1;
  padding: 25px 30px;
  border: 0px;
  border-radius: 7px;
}
@media (max-width: 585px) {
  .contact-form input, .contact-form textarea {
    padding: 15px;
    font-size: 13px;
  }
}
.contact-form textarea {
  height: 220px;
}
@media (max-width: 767px) {
  .contact-form textarea {
    height: 160px;
  }
}
.contact-form label {
  color: #202426;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.contact-form label span {
  color: #727475;
  margin-left: 5px;
}
.contact-form input[type=submit], .contact-form .submit-btn {
  background: #8A038C;
  color: #fff;
  font-weight: 700;
  line-height: 1;
  padding: 25px 40px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 280px;
}
.contact-form input[type=submit].active, .contact-form input[type=submit]:hover, .contact-form .submit-btn.active, .contact-form .submit-btn:hover {
  background-color: #ff5e14;
}
.contact-form input[type=submit] i, .contact-form .submit-btn i {
  margin-right: 8px;
}
@media (max-width: 400px) {
  .contact-form input[type=submit], .contact-form .submit-btn {
    width: 100%;
  }
}

.single-contact-card {
  background-color: #f8f8f8;
  padding: 40px;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 585px) {
  .single-contact-card {
    padding: 30px;
  }
}
.single-contact-card:hover {
  background-color: #202426;
}
.single-contact-card:hover .icon {
  color: #fff !important;
  background-color: #8A038C !important;
}
.single-contact-card:hover .bottom-part .icon {
  color: #8A038C !important;
  background-color: #fff !important;
}
.single-contact-card:hover p, .single-contact-card:hover span {
  color: #c5c7c8;
}
.single-contact-card:hover h4 {
  color: #fff;
}
.single-contact-card .top-part {
  overflow: hidden;
}
.single-contact-card .top-part .icon {
  width: 70px;
  height: 70px;
  font-size: 20px;
  line-height: 70px;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  float: left;
  color: #8A038C;
  overflow: hidden;
  margin-right: 20px;
}
.single-contact-card .top-part .title {
  overflow: hidden;
}
.single-contact-card .bottom-part {
  margin-top: 25px;
  overflow: hidden;
}
.single-contact-card .bottom-part .icon {
  width: 50px;
  height: 50px;
  font-size: 14px;
  line-height: 50px;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  float: right;
  overflow: hidden;
  margin-left: 20px;
}
.single-contact-card .bottom-part .info {
  display: inline-block;
  overflow: hidden;
}

.contact-map-wrap iframe {
  height: 450px;
}
@media (max-width: 991px) {
  .contact-map-wrap iframe {
    height: 300px;
  }
}

.consultations-form {
  background-color: #8A038C;
  padding: 70px 65px;
  border-radius: 7px;
}
@media (max-width: 991px) {
  .consultations-form {
    padding: 60px 50px;
  }
}
@media (max-width: 767px) {
  .consultations-form {
    padding: 40px 30px;
  }
}
.consultations-form.style-2 {
  position: relative;
  z-index: 99;
  margin-top: -190px;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(8, 106, 215, 0.1);
          box-shadow: 0px 10px 60px 0px rgba(8, 106, 215, 0.1);
}
@media (max-width: 1199px) {
  .consultations-form.style-2 {
    padding: 50px 40px;
  }
}
@media (max-width: 991px) {
  .consultations-form.style-2 {
    margin-top: 30px;
  }
}
.consultations-form.style-2 p {
  color: #8A038C;
}
.consultations-form.style-2 form input, .consultations-form.style-2 form textarea {
  border-width: 2px;
  border-color: #e9e9eb;
  color: #211e3b;
  font-weight: 500;
  background-color: #fff;
}
.consultations-form.style-2 form input::-webkit-input-placeholder, .consultations-form.style-2 form textarea::-webkit-input-placeholder {
  color: #211e3b;
}
.consultations-form.style-2 form input::-moz-placeholder, .consultations-form.style-2 form textarea::-moz-placeholder {
  color: #211e3b;
}
.consultations-form.style-2 form input:-ms-input-placeholder, .consultations-form.style-2 form textarea:-ms-input-placeholder {
  color: #211e3b;
}
.consultations-form.style-2 form input::-ms-input-placeholder, .consultations-form.style-2 form textarea::-ms-input-placeholder {
  color: #211e3b;
}
.consultations-form.style-2 form input::placeholder, .consultations-form.style-2 form textarea::placeholder {
  color: #211e3b;
}
.consultations-form.style-2 form input:hover, .consultations-form.style-2 form textarea:hover {
  border-color: #8A038C;
}
.consultations-form.style-2 form button {
  background-color: #8A038C;
  border-color: #8A038C;
  color: #fff;
  margin-top: 30px;
}
.consultations-form.style-2 form button i {
  margin-left: 10px;
}
.consultations-form p {
  text-transform: uppercase;
}
.consultations-form h1 {
  text-transform: capitalize;
  margin-top: 5px;
}
.consultations-form form input, .consultations-form form textarea {
  border-width: 1px;
  border-color: #2179db;
  border-style: solid;
  border-radius: 7px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  padding: 28px 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-top: 30px;
  background: transparent;
  color: #fff;
}
@media (max-width: 767px) {
  .consultations-form form input, .consultations-form form textarea {
    padding: 20px;
    font-size: 16px;
  }
}
.consultations-form form input:hover, .consultations-form form textarea:hover {
  border-color: #fff;
}
.consultations-form form input::-webkit-input-placeholder, .consultations-form form textarea::-webkit-input-placeholder {
  text-transform: capitalize;
  color: #fff;
}
.consultations-form form input::-moz-placeholder, .consultations-form form textarea::-moz-placeholder {
  text-transform: capitalize;
  color: #fff;
}
.consultations-form form input:-ms-input-placeholder, .consultations-form form textarea:-ms-input-placeholder {
  text-transform: capitalize;
  color: #fff;
}
.consultations-form form input::-ms-input-placeholder, .consultations-form form textarea::-ms-input-placeholder {
  text-transform: capitalize;
  color: #fff;
}
.consultations-form form input::placeholder, .consultations-form form textarea::placeholder {
  text-transform: capitalize;
  color: #fff;
}
.consultations-form form textarea {
  min-height: 200px;
}
@media (max-width: 767px) {
  .consultations-form form textarea {
    min-height: 130px;
  }
}
.consultations-form form button {
  background-color: #fff;
  color: #8A038C;
  margin-top: 30px;
}
.consultations-form form button i {
  margin-left: 10px;
}

.contact_form__wrapper {
  position: relative;
}
.contact_form__wrapper .contact__element {
  margin-top: -250px;
  padding: 70px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 10px 30px rgba(11, 11, 127, 0.05);
          box-shadow: 0px 10px 30px rgba(11, 11, 127, 0.05);
}
@media (max-width: 768px) {
  .contact_form__wrapper .contact__element {
    margin-top: 0px;
    padding: 30px;
  }
}
.contact_form__wrapper .contact__element input[type=text], .contact_form__wrapper .contact__element input[type=email] {
  height: 50px;
  border: 1px solid #E6F0FB;
  margin: 10px;
}
.contact_form__wrapper .contact__element input[type=submit] {
  height: 50px;
  background-color: #8A038C;
  color: #FFF;
  margin: 10px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.contact_form__wrapper .contact__element input[type=submit]:hover {
  background-color: #000b2c;
}
.contact_form__wrapper .contact__element textarea {
  margin: 10px;
  height: 150px;
}
.contact_form__wrapper .contact__element .form-control:focus {
  border: 1.5px solid #E6F0FB !important;
}
.contact_form__wrapper .contact__detels h2 {
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  text-transform: capitalize;
  color: #211E3B;
}
@media (max-width: 768px) {
  .contact_form__wrapper .contact__detels h2 {
    font-size: 32px !important;
  }
}
.contact_form__wrapper .contact__detels p {
  margin: 20px 0px;
}
.contact_form__wrapper .contact__detels .content h4 {
  font-family: "Circular Std", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #211E3B;
}
.contact_form__wrapper .contact__detels .content p {
  margin: 0px;
}
.contact_form__wrapper .arrow__element {
  position: absolute;
  bottom: 20%;
  right: 20%;
}

.content-area {
  overflow: hidden;
}

.blog-section.techex-landing-page {
  padding: 120px 0;
  background: #FAFAFF;
}
@media (max-width: 1199px) {
  .blog-section.techex-landing-page {
    padding: 100px 0;
  }
}
@media (max-width: 991px) {
  .blog-section.techex-landing-page {
    padding: 90px 0;
  }
}
@media (max-width: 767px) {
  .blog-section.techex-landing-page {
    padding: 80px 0;
  }
}
@media (max-width: 575px) {
  .blog-section.techex-landing-page {
    padding: 70px 0;
  }
}
@media (max-width: 480px) {
  .blog-section.techex-landing-page {
    padding: 50px 0;
  }
}
.blog-section.techex-landing-page .container {
  position: relative;
}
.blog-section.techex-landing-page .container:before {
  top: -218px;
  left: -385px;
  width: 519px;
  content: "";
  height: 519px;
  position: absolute;
  background: no-repeat;
  background-image: url("assets/img/techex-landing-page/blog_bg1.html");
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-name: scale5;
          animation-name: scale5;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}
@media (max-width: 1366px) {
  .blog-section.techex-landing-page .container:before {
    top: -180px;
    left: -350px;
  }
}
@media (max-width: 1199px) {
  .blog-section.techex-landing-page .container:before {
    top: -150px;
  }
}
@media (max-width: 767px) {
  .blog-section.techex-landing-page .container:before {
    top: -100px;
  }
}
@media (max-width: 480px) {
  .blog-section.techex-landing-page .container:before {
    top: -50px;
  }
}
@media (max-width: 1199px) {
  .blog-section.techex-landing-page .theme-btn-sm {
    margin-bottom: 10px;
  }
}
@media (max-width: 991px) {
  .blog-section.techex-landing-page .theme-btn-sm {
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .blog-section.techex-landing-page .theme-btn-sm {
    margin-bottom: 0;
  }
}
.blog-section.techex-landing-page .section-title {
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  .blog-section.techex-landing-page .section-title {
    margin-bottom: 10px;
  }
}
.blog-section.techex-landing-page .section-title h1 {
  font-weight: 700;
  letter-spacing: -0.03em;
}
@media (max-width: 767px) {
  .blog-section.techex-landing-page .section-title h1 {
    line-height: 42px;
  }
}

.blog-wrapper {
  background-color: #FAFAFF;
}

.our-team-wrapper.techex-landing-page {
  padding: 120px 0;
  position: relative;
}
@media (max-width: 1199px) {
  .our-team-wrapper.techex-landing-page {
    padding: 100px 0;
  }
}
@media (max-width: 991px) {
  .our-team-wrapper.techex-landing-page {
    padding: 80px 0;
  }
}
@media (max-width: 767px) {
  .our-team-wrapper.techex-landing-page {
    padding: 60px 0;
  }
}
@media (max-width: 575px) {
  .our-team-wrapper.techex-landing-page {
    padding: 50px 0;
  }
}
.our-team-wrapper.techex-landing-page .container {
  position: relative;
}
.our-team-wrapper.techex-landing-page .container:after {
  top: -180px;
  width: 123px;
  z-index: 1;
  content: "";
  right: -250px;
  height: 187px;
  position: absolute;
  background-image: url(assets/img/techex-landing-page/our-team-bg-dot.html);
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-name: movebounce;
          animation-name: movebounce;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}
.our-team-wrapper.techex-landing-page .container:before {
  top: 64px;
  left: -57px;
  width: 1192px;
  content: "";
  height: 701px;
  position: absolute;
  background: no-repeat;
  background-image: url("assets/img/techex-landing-page/our-team-bg.html");
}
@media (max-width: 1199px) {
  .our-team-wrapper.techex-landing-page .theme-btn-sm {
    margin-bottom: 10px;
  }
}
@media (max-width: 991px) {
  .our-team-wrapper.techex-landing-page .theme-btn-sm {
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .our-team-wrapper.techex-landing-page .theme-btn-sm {
    margin-bottom: 0;
  }
}
.our-team-wrapper.techex-landing-page .section-title {
  max-width: 420px;
  margin: 0 auto 20px;
}
@media (max-width: 991px) {
  .our-team-wrapper.techex-landing-page .section-title {
    margin-bottom: 10px;
  }
}
.our-team-wrapper.techex-landing-page .section-title h1 {
  font-weight: 700;
  letter-spacing: -0.03em;
}
@media (max-width: 767px) {
  .our-team-wrapper.techex-landing-page .section-title h1 {
    line-height: 42px;
  }
}

.our-service-provide.techex-landing-page {
  padding: 120px 0;
  overflow: hidden;
  position: relative;
  background: #FAFAFF;
}
@media (max-width: 1199px) {
  .our-service-provide.techex-landing-page {
    padding: 100px 0;
  }
}
@media (max-width: 991px) {
  .our-service-provide.techex-landing-page {
    padding: 80px 0;
  }
}
@media (max-width: 767px) {
  .our-service-provide.techex-landing-page {
    padding: 60px 0;
  }
}
@media (max-width: 575px) {
  .our-service-provide.techex-landing-page {
    padding: 50px 0;
  }
}
.our-service-provide.techex-landing-page .container {
  position: relative;
}
.our-service-provide.techex-landing-page .container:before {
  top: 71px;
  width: 386px;
  content: "";
  left: -156px;
  height: 386px;
  position: absolute;
  background: no-repeat;
  background-size: cover;
  background-image: url("assets/img/techex-landing-page/blog_bg1.html");
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-name: scale5;
          animation-name: scale5;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}
@media (max-width: 1199px) {
  .our-service-provide.techex-landing-page .theme-btn-sm {
    margin-bottom: 10px;
  }
}
@media (max-width: 991px) {
  .our-service-provide.techex-landing-page .theme-btn-sm {
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .our-service-provide.techex-landing-page .theme-btn-sm {
    margin-bottom: 0;
  }
}
.our-service-provide.techex-landing-page .section-title {
  max-width: 480px;
  margin: 0 auto 20px;
}
@media (max-width: 991px) {
  .our-service-provide.techex-landing-page .section-title {
    margin-bottom: 10px;
  }
}
.our-service-provide.techex-landing-page .section-title h1 {
  font-weight: 700;
  letter-spacing: -0.03em;
}
@media (max-width: 767px) {
  .our-service-provide.techex-landing-page .section-title h1 {
    line-height: 42px;
  }
}

.we-provide-solutions {
  background: #FAFAFF;
  padding: 100px 0 120px;
}
@media (max-width: 1199px) {
  .we-provide-solutions {
    padding: 100px 0;
  }
}
@media (max-width: 991px) {
  .we-provide-solutions {
    padding: 80px 0;
  }
}
@media (max-width: 767px) {
  .we-provide-solutions {
    padding: 60px 0;
  }
}
@media (max-width: 575px) {
  .we-provide-solutions {
    padding: 50px 0;
  }
}
@media (max-width: 1199px) {
  .we-provide-solutions .theme-btn-sm {
    margin-bottom: 10px;
  }
}
@media (max-width: 991px) {
  .we-provide-solutions .theme-btn-sm {
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .we-provide-solutions .theme-btn-sm {
    margin-bottom: 0;
  }
}
.we-provide-solutions .section-title {
  max-width: 770px;
  margin: 0 auto 20px;
}
@media (max-width: 991px) {
  .we-provide-solutions .section-title {
    margin-bottom: 10px;
  }
}
.we-provide-solutions .section-title h1 {
  font-weight: 700;
  letter-spacing: -0.03em;
}
@media (max-width: 767px) {
  .we-provide-solutions .section-title h1 {
    line-height: 42px;
  }
}

.single-provide-solutions {
  z-index: 1;
  margin-top: 30px;
  overflow: hidden;
  display: block;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-repeat: no-repeat;
  background-color: #eef4fc;
  background-position: bottom right;
}
.single-provide-solutions:after {
  top: 0;
  left: 0;
  height: 0%;
  width: 100%;
  z-index: -1;
  content: "";
  position: absolute;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: #8A038C;
}
.single-provide-solutions:hover:after {
  height: 100%;
}
.single-provide-solutions:hover .content h3, .single-provide-solutions:hover .content p, .single-provide-solutions:hover .content .read-more {
  color: #FFF;
}
.single-provide-solutions:hover .content p {
  font-weight: 400;
}
.single-provide-solutions .number {
  top: -14px;
  left: -10px;
  display: block;
  font-size: 96px;
  font-weight: 700;
  line-height: 96px;
  position: absolute;
  color: rgba(33, 30, 59, 0.05);
}
.sub-menu li a{
  color: #840AA6 !important;
}
@media (max-width: 767px) {
  .single-provide-solutions .number {
    font-size: 80px;
    line-height: 80px;
  }
}
.single-provide-solutions .content {
  padding: 72px 29px 58px;
}
@media (max-width: 767px) {
  .single-provide-solutions .content {
    padding: 60px 20px 30px;
  }
}
.single-provide-solutions .content h3 {
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 767px) {
  .single-provide-solutions .content h3 {
    margin-bottom: 5px;
  }
}
.single-provide-solutions .content p {
  color: #696969;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.single-provide-solutions .content .read-more {
  display: block;
  font-size: 14px;
  margin-top: 30px;
  font-weight: 500;
  line-height: 18px;
  color: #8A038C;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 767px) {
  .single-provide-solutions .content .read-more {
    margin-top: 15px;
  }
}
.single-provide-solutions .content .read-more i {
  font-size: 12px;
  margin-left: 10px;
}

.client-brand-logo-wrap.techex-landing-page {
  padding: 120px 0;
  background: #fafaff;
}
@media (max-width: 1199px) {
  .client-brand-logo-wrap.techex-landing-page {
    padding: 100px 0;
  }
}
@media (max-width: 991px) {
  .client-brand-logo-wrap.techex-landing-page {
    padding: 80px 0;
  }
}
@media (max-width: 767px) {
  .client-brand-logo-wrap.techex-landing-page {
    padding: 60px 0;
  }
}
@media (max-width: 575px) {
  .client-brand-logo-wrap.techex-landing-page {
    padding: 50px 0;
  }
}
.client-brand-logo-wrap.techex-landing-page .brand-carousel-active {
  border: 0;
}

.project-case-study-wrapper.techex-landing-page {
  padding: 120px 0;
  overflow: hidden;
  background: #FAFAFF;
}
@media (max-width: 1199px) {
  .project-case-study-wrapper.techex-landing-page {
    padding: 100px 0;
  }
}
@media (max-width: 991px) {
  .project-case-study-wrapper.techex-landing-page {
    padding: 80px 0;
  }
}
@media (max-width: 767px) {
  .project-case-study-wrapper.techex-landing-page {
    padding: 60px 0;
  }
}
@media (max-width: 575px) {
  .project-case-study-wrapper.techex-landing-page {
    padding: 50px 0;
  }
}
.project-case-study-wrapper.techex-landing-page .container {
  position: relative;
}
.project-case-study-wrapper.techex-landing-page .project-case-study-wrapper__circle:after, .project-case-study-wrapper.techex-landing-page .project-case-study-wrapper__circle:before {
  width: 764px;
  content: "";
  height: 777px;
  position: absolute;
  background: #EEF4FC;
  border-radius: 500px;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-name: scale5;
          animation-name: scale5;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}
.project-case-study-wrapper.techex-landing-page .project-case-study-wrapper__circle:after {
  left: -671px;
  bottom: -382px;
}
.project-case-study-wrapper.techex-landing-page .project-case-study-wrapper__circle:before {
  right: -723px;
  bottom: -777px;
}
@media (max-width: 1199px) {
  .project-case-study-wrapper.techex-landing-page .theme-btn-sm {
    margin-bottom: 10px;
  }
}
@media (max-width: 991px) {
  .project-case-study-wrapper.techex-landing-page .theme-btn-sm {
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .project-case-study-wrapper.techex-landing-page .theme-btn-sm {
    margin-bottom: 0;
  }
}
.project-case-study-wrapper.techex-landing-page .section-title {
  max-width: 716px;
  margin: 0 auto 50px;
}
@media (max-width: 991px) {
  .project-case-study-wrapper.techex-landing-page .section-title {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .project-case-study-wrapper.techex-landing-page .section-title {
    margin-bottom: 35px;
  }
}
.project-case-study-wrapper.techex-landing-page .section-title h1 {
  font-weight: 700;
  letter-spacing: -0.03em;
}
@media (max-width: 767px) {
  .project-case-study-wrapper.techex-landing-page .section-title h1 {
    line-height: 42px;
  }
}

.success-item {
  z-index: 1;
  display: block;
  position: relative;
}
.success-item:after {
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(8, 106, 215, 0)), to(rgba(33, 30, 59, 0.8)));
  background: linear-gradient(180deg, rgba(8, 106, 215, 0) 0%, rgba(33, 30, 59, 0.8) 100%);
}
.success-item:hover:after {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(8, 106, 215, 0)), to(rgba(8, 106, 215, 0.8)));
  background: linear-gradient(180deg, rgba(8, 106, 215, 0) 0%, rgba(8, 106, 215, 0.8) 100%);
}
.success-item:hover .content .icon {
  opacity: 1;
  visibility: inherit;
  -webkit-transform: translate(0);
  transform: translate(0);
}
.success-item .thumb {
  width: 100%;
  height: 445px;
}
@media (max-width: 1366px) {
  .success-item .thumb {
    height: 400px;
  }
}
@media (max-width: 991px) {
  .success-item .thumb {
    height: 370px;
  }
}
@media (max-width: 767px) {
  .success-item .thumb {
    height: 350px;
  }
}
@media (max-width: 575px) {
  .success-item .thumb {
    height: 320px;
  }
}
.success-item .content {
  bottom: 0;
  z-index: 1;
  width: 100%;
  padding: 20px 24px;
  position: absolute;
}
.success-item .content .text h3, .success-item .content .text p {
  color: #FFF;
}
.success-item .content .text h3 {
  margin-bottom: 5px;
}
.success-item .content .text p {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
}
.success-item .content .icon {
  opacity: 0;
  width: 35px;
  height: 35px;
  color: #FFF;
  font-size: 10px;
  line-height: 35px;
  visibility: hidden;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 1px solid #FFF;
  border-radius: 500px;
  -webkit-transform: translate(0, 20px);
  transform: translate(0, 20px);
}
.success-item .content .icon:hover {
  background: #FFF;
  color: #8A038C;
}
.success-item__wrapper {
  margin: auto;
  padding: 0 15px;
  max-width: 1522px;
}
.success-item__wrapper .owl-dots {
  display: block !important;
  margin: 50px -7.5px 0 !important;
}
@media (max-width: 1366px) {
  .success-item__wrapper .owl-dots {
    margin: 30px -5px 0 !important;
  }
}
@media (max-width: 991px) {
  .success-item__wrapper .owl-dots {
    margin: 20px -3px 0 !important;
  }
}
.success-item__wrapper .owl-dots .owl-dot {
  width: 15px;
  height: 15px;
  margin: 0px 7.5px;
  line-height: 15px;
  position: relative;
  border-radius: 50%;
  border: 1px solid #FFF;
  border-color: transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1366px) {
  .success-item__wrapper .owl-dots .owl-dot {
    margin: 0 5px;
  }
}
@media (max-width: 1366px) {
  .success-item__wrapper .owl-dots .owl-dot {
    margin: 0 3px;
  }
}
.success-item__wrapper .owl-dots .owl-dot:hover, .success-item__wrapper .owl-dots .owl-dot.active {
  border-color: #8A038C;
}
.success-item__wrapper .owl-dots .owl-dot:hover span, .success-item__wrapper .owl-dots .owl-dot.active span {
  background-color: #8A038C;
}
.success-item__wrapper .owl-dots .owl-dot span {
  margin: 0;
  width: 9px;
  height: 9px;
  position: absolute;
  top: calc(50% - 4.5px);
  left: calc(50% - 4.5px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: rgba(8, 106, 215, 0.3);
}

.testimonial-wrapper.techex-landing-page {
  overflow: hidden;
  padding: 120px 0;
}
@media (max-width: 1199px) {
  .testimonial-wrapper.techex-landing-page {
    padding: 100px 0;
  }
}
@media (max-width: 991px) {
  .testimonial-wrapper.techex-landing-page {
    padding: 80px 0;
  }
}
@media (max-width: 767px) {
  .testimonial-wrapper.techex-landing-page {
    padding: 60px 0;
  }
}
@media (max-width: 575px) {
  .testimonial-wrapper.techex-landing-page {
    padding: 50px 0;
  }
}
.testimonial-wrapper.techex-landing-page .container {
  position: relative;
}
.testimonial-wrapper.techex-landing-page .container:after {
  top: 164px;
  width: 123px;
  content: "";
  right: -350px;
  height: 137px;
  position: absolute;
  background-image: url(assets/img/techex-landing-page/triangle.html);
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-name: movebounce;
          animation-name: movebounce;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}
@media (max-width: 1199px) {
  .testimonial-wrapper.techex-landing-page .theme-btn-sm {
    margin-bottom: 10px;
  }
}
@media (max-width: 991px) {
  .testimonial-wrapper.techex-landing-page .theme-btn-sm {
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .testimonial-wrapper.techex-landing-page .theme-btn-sm {
    margin-bottom: 0;
  }
}
.testimonial-wrapper.techex-landing-page .section-title {
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .testimonial-wrapper.techex-landing-page .section-title {
    margin-bottom: 35px;
  }
}
.testimonial-wrapper.techex-landing-page .section-title h1 {
  font-weight: 700;
  letter-spacing: -0.03em;
}
@media (max-width: 767px) {
  .testimonial-wrapper.techex-landing-page .section-title h1 {
    line-height: 42px;
  }
}
.testimonial-wrapper.techex-landing-page .testimonial-media {
  z-index: 1;
  position: relative;
  margin-left: -30px;
  margin-right: -20px;
}
@media (max-width: 767px) {
  .testimonial-wrapper.techex-landing-page .testimonial-media {
    margin: 20px 0 0;
  }
}
.testimonial-wrapper.techex-landing-page .testimonial-media:after {
  top: 44px;
  content: "";
  left: -71px;
  z-index: -1;
  width: 431px;
  height: 431px;
  position: absolute;
  border-radius: 500px;
  background: rgba(65, 118, 255, 0.04);
}

.testimonial-carousel-3 .single-testimonial {
  max-width: 669px;
}
.testimonial-carousel-3 .single-testimonial .icon {
  font-size: 75px;
  line-height: 75px;
  margin-bottom: 5px;
}
@media (max-width: 991px) {
  .testimonial-carousel-3 .single-testimonial .icon {
    font-size: 60px;
    margin-bottom: 0;
    line-height: 60px;
  }
}
.testimonial-carousel-3 .single-testimonial h2 {
  font-size: 28px;
  line-height: 38px;
  color: #696969;
  letter-spacing: 0;
  font-style: normal;
}
@media (max-width: 991px) {
  .testimonial-carousel-3 .single-testimonial h2 {
    font-size: 22px;
    line-height: 32px;
  }
}
.testimonial-carousel-3 .single-testimonial .client-info {
  margin-top: 15px;
}
@media (max-width: 991px) {
  .testimonial-carousel-3 .single-testimonial .client-info {
    margin-top: 5px;
  }
}
.testimonial-carousel-3 .single-testimonial .client-info p {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #696969;
  position: relative;
  padding-left: 70px;
  font-family: "Roboto";
}
.testimonial-carousel-3 .single-testimonial .client-info p:after {
  left: 0;
  width: 60px;
  height: 1px;
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  background: rgba(33, 30, 59, 0.3);
}
.testimonial-carousel-3 .single-testimonial .client-info p a {
  font-weight: 700;
  color: #211e3b;
}
.testimonial-carousel-3 .single-testimonial .client-info p a:hover {
  color: #8DC63F;
}
.testimonial-carousel-3 .owl-nav {
  text-align: left;
  margin: 30px -10px -10px !important;
}
@media (max-width: 991px) {
  .testimonial-carousel-3 .owl-nav {
    margin: 20px -7px -7px !important;
  }
}
.testimonial-carousel-3 .owl-nav .owl-prev, .testimonial-carousel-3 .owl-nav .owl-next {
  width: 44px;
  margin: 10px;
  height: 44px;
  color: #000;
  font-size: 7px;
  text-align: center;
  line-height: 34px;
  background: transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 500px;
  border: 1px solid rgba(33, 30, 59, 0.2);
}
@media (max-width: 991px) {
  .testimonial-carousel-3 .owl-nav .owl-prev, .testimonial-carousel-3 .owl-nav .owl-next {
    margin: 7px;
  }
}
.testimonial-carousel-3 .owl-nav .owl-prev:hover, .testimonial-carousel-3 .owl-nav .owl-next:hover {
  background: #8A038C;
}

.about-wrapper.techex-landing-page {
  padding: 120px 0;
}
@media (max-width: 1199px) {
  .about-wrapper.techex-landing-page {
    padding: 100px 0;
  }
}
@media (max-width: 991px) {
  .about-wrapper.techex-landing-page {
    padding: 80px 0;
  }
}
@media (max-width: 767px) {
  .about-wrapper.techex-landing-page {
    padding: 60px 0;
  }
}
@media (max-width: 575px) {
  .about-wrapper.techex-landing-page {
    padding: 50px 0;
  }
}
.about-wrapper.techex-landing-page .container {
  position: relative;
}
.about-wrapper.techex-landing-page .container:after {
  top: -180px;
  width: 123px;
  z-index: 1;
  content: "";
  right: -250px;
  height: 187px;
  position: absolute;
  background-image: url(assets/img/techex-landing-page/our-team-bg-dot.html);
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-name: movebounce;
          animation-name: movebounce;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}
.about-wrapper.techex-landing-page .container:before {
  top: -17px;
  z-index: -1;
  content: "";
  left: -45px;
  width: 123px;
  height: 135px;
  position: absolute;
  background-image: url(assets/img/techex-landing-page/our-team-bg-dot.html);
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-name: movebounce;
          animation-name: movebounce;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}
@media (max-width: 991px) {
  .about-wrapper.techex-landing-page .container:before {
    left: 135px;
  }
}
@media (max-width: 767px) {
  .about-wrapper.techex-landing-page .container:before {
    left: 92px;
  }
}
@media (max-width: 480px) {
  .about-wrapper.techex-landing-page .container:before {
    left: 80px;
  }
}
@media (max-width: 400px) {
  .about-wrapper.techex-landing-page .container:before {
    left: 60px;
  }
}
@media (max-width: 1199px) {
  .about-wrapper.techex-landing-page .theme-btn-sm {
    margin-bottom: 10px;
  }
}
@media (max-width: 991px) {
  .about-wrapper.techex-landing-page .theme-btn-sm {
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .about-wrapper.techex-landing-page .theme-btn-sm {
    margin-bottom: 0;
  }
}
.about-wrapper.techex-landing-page .section-title h1 {
  max-width: 460px;
  font-weight: 700;
  margin-bottom: 15px;
  letter-spacing: -0.03em;
}
@media (max-width: 767px) {
  .about-wrapper.techex-landing-page .section-title h1 {
    line-height: 42px;
    margin-bottom: 5px;
  }
}
.about-wrapper.techex-landing-page .section-title p {
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  color: #696969;
  text-transform: capitalize;
}
.about-wrapper.techex-landing-page .section-title .rate-content-grid {
  gap: 10px;
  margin-top: 20px;
}
@media (max-width: 575px) {
  .about-wrapper.techex-landing-page .section-title .rate-content-grid {
    margin-top: 10px;
  }
}
.about-wrapper.techex-landing-page .section-title .rate-content-grid .single-rate-item h3 {
  font-size: 36px;
  line-height: 46px;
}
@media (max-width: 767px) {
  .about-wrapper.techex-landing-page .section-title .rate-content-grid .single-rate-item h3 {
    font-size: 28px;
    line-height: 38px;
  }
}
@media (max-width: 767px) {
  .about-wrapper.techex-landing-page .section-title .rate-content-grid .single-rate-item p {
    font-size: 15px;
    line-height: 25px;
  }
}
.about-wrapper.techex-landing-page .section-title .theme-btn {
  z-index: 1;
  margin-top: 20px;
  overflow: hidden;
  position: relative;
  padding: 18.5px 27px;
  background: #211e3b;
}
@media (max-width: 991px) {
  .about-wrapper.techex-landing-page .section-title .theme-btn {
    margin: 10px 0 0;
  }
}
@media (max-width: 575px) {
  .about-wrapper.techex-landing-page .section-title .theme-btn {
    margin: 5px 0 0;
  }
}
.about-wrapper.techex-landing-page .section-title .theme-btn:before {
  left: 0;
  width: 0%;
  bottom: 0;
  content: "";
  z-index: -1;
  height: 100%;
  overflow: hidden;
  position: absolute;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #8A038C;
}
.about-wrapper.techex-landing-page .section-title .theme-btn:hover {
  background: transparent;
}
.about-wrapper.techex-landing-page .section-title .theme-btn:hover:before {
  width: 100%;
}
.about-wrapper.techex-landing-page .about-img {
  position: relative;
  margin: 0 15px 0 -175px;
}
@media (max-width: 991px) {
  .about-wrapper.techex-landing-page .about-img {
    margin: 0 0 50px;
  }
}
.about-wrapper.techex-landing-page .about-img .video-play-btn {
  right: 160px;
  bottom: 15px;
  position: absolute;
}
@media (max-width: 991px) {
  .about-wrapper.techex-landing-page .about-img .video-play-btn {
    right: 110px;
    bottom: 10px;
  }
}
@media (max-width: 500px) {
  .about-wrapper.techex-landing-page .about-img .video-play-btn {
    right: 98px;
  }
}
@media (max-width: 450px) {
  .about-wrapper.techex-landing-page .about-img .video-play-btn {
    right: 86px;
  }
}
@media (max-width: 400px) {
  .about-wrapper.techex-landing-page .about-img .video-play-btn {
    right: 70px;
    bottom: 5px;
  }
}
@media (max-width: 350px) {
  .about-wrapper.techex-landing-page .about-img .video-play-btn {
    right: 60px;
    bottom: 0px;
  }
}
.about-wrapper.techex-landing-page .about-img .video-play-btn .popup-video {
  width: 75px;
  height: 75px;
  line-height: 78px;
  -webkit-animation-name: playVideo1;
          animation-name: playVideo1;
  background-color: #8A038C;
}
@media (max-width: 767px) {
  .about-wrapper.techex-landing-page .about-img .video-play-btn .popup-video {
    width: 60px;
    height: 60px;
    line-height: 62px;
  }
}
@media (max-width: 450px) {
  .about-wrapper.techex-landing-page .about-img .video-play-btn .popup-video {
    width: 50px;
    height: 50px;
    line-height: 48px;
  }
}
.about-wrapper.techex-landing-page .about-img .video-play-btn .popup-video i {
  color: #FFF;
  font-size: 20px;
}
@media (max-width: 450px) {
  .about-wrapper.techex-landing-page .about-img .video-play-btn .popup-video i {
    font-size: 15px;
  }
}

.hero-slide-wrapper.techex-landing-page {
  position: relative;
}
.hero-slide-wrapper.techex-landing-page .single-slide {
  z-index: 1;
  padding: 202.5px 0;
  position: relative;
}
@media (max-width: 1366px) {
  .hero-slide-wrapper.techex-landing-page .single-slide {
    padding: 170px 0;
  }
}
@media (max-width: 1199px) {
  .hero-slide-wrapper.techex-landing-page .single-slide {
    padding: 150px 0;
  }
}
@media (max-width: 991px) {
  .hero-slide-wrapper.techex-landing-page .single-slide {
    padding: 110px 0;
  }
}
@media (max-width: 767px) {
  .hero-slide-wrapper.techex-landing-page .single-slide {
    padding: 90px 0 100px;
  }
}
@media (max-width: 575px) {
  .hero-slide-wrapper.techex-landing-page .single-slide {
    padding: 60px 0 80px;
  }
}
.hero-slide-wrapper.techex-landing-page .single-slide:after {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  content: "";
  height: 100%;
  position: absolute;
  background: rgba(21, 7, 137, 0.75);
}
.hero-slide-wrapper.techex-landing-page .single-slide .hero-contents {
  margin: auto;
  max-width: 761px;
}
@media (max-width: 991px) {
  .hero-slide-wrapper.techex-landing-page .single-slide .hero-contents {
    max-width: 580px;
  }
}
@media (max-width: 767px) {
  .hero-slide-wrapper.techex-landing-page .single-slide .hero-contents {
    max-width: 450px;
  }
}
.hero-slide-wrapper.techex-landing-page .single-slide .hero-contents .theme-btn-sm {
  font-size: 16px;
  font-weight: 500;
  padding: 8px 18px;
  line-height: 20px;
  margin-bottom: 15px;
}
@media (max-width: 1366px) {
  .hero-slide-wrapper.techex-landing-page .single-slide .hero-contents .theme-btn-sm {
    margin-bottom: 10px;
  }
}
@media (max-width: 1199px) {
  .hero-slide-wrapper.techex-landing-page .single-slide .hero-contents .theme-btn-sm {
    margin-bottom: 5px;
  }
}
@media (max-width: 575px) {
  .hero-slide-wrapper.techex-landing-page .single-slide .hero-contents .theme-btn-sm {
    font-size: 14px;
    line-height: 18px;
    padding: 7px 13px;
  }
}
.hero-slide-wrapper.techex-landing-page .single-slide .hero-contents h1 {
  color: #F5F5F5;
  font-size: 64px;
  font-weight: 700;
  line-height: 80px;
  margin-bottom: 15px;
  letter-spacing: -0.03em;
}
@media (max-width: 1366px) {
  .hero-slide-wrapper.techex-landing-page .single-slide .hero-contents h1 {
    font-size: 55px;
    line-height: 65px;
    margin-bottom: 10px;
  }
}
@media (max-width: 1199px) {
  .hero-slide-wrapper.techex-landing-page .single-slide .hero-contents h1 {
    font-size: 45px;
    line-height: 55px;
    margin-bottom: 5px;
  }
}
@media (max-width: 991px) {
  .hero-slide-wrapper.techex-landing-page .single-slide .hero-contents h1 {
    font-size: 35px;
    line-height: 45px;
  }
}
.hero-slide-wrapper.techex-landing-page .single-slide .hero-contents p {
  color: #FFF;
  max-width: 740px;
}
.hero-slide-wrapper.techex-landing-page .single-slide .hero-contents .btn__wrapper {
  gap: 30px;
  margin-top: 40px;
}
@media (max-width: 1366px) {
  .hero-slide-wrapper.techex-landing-page .single-slide .hero-contents .btn__wrapper {
    gap: 25px;
    margin-top: 30px;
  }
}
@media (max-width: 1199px) {
  .hero-slide-wrapper.techex-landing-page .single-slide .hero-contents .btn__wrapper {
    gap: 20px;
    margin-top: 20px;
  }
}
.hero-slide-wrapper.techex-landing-page .single-slide .hero-contents .btn__wrapper .theme-btn {
  z-index: 1;
  font-weight: 500;
  overflow: hidden;
  position: relative;
  border-color: #8A038C;
  border-radius: 4px;
  padding: 19px 27px 19px 30px;
}
@media (max-width: 1199px) {
  .hero-slide-wrapper.techex-landing-page .single-slide .hero-contents .btn__wrapper .theme-btn {
    padding: 16px 25px;
  }
}
@media (max-width: 991px) {
  .hero-slide-wrapper.techex-landing-page .single-slide .hero-contents .btn__wrapper .theme-btn {
    font-size: 13px;
    line-height: 13px;
    padding: 14px 22px;
  }
}
.hero-slide-wrapper.techex-landing-page .single-slide .hero-contents .btn__wrapper .theme-btn:before {
  left: 0;
  width: 0%;
  bottom: 0;
  content: "";
  opacity: 0;
  z-index: -1;
  height: 100%;
  overflow: hidden;
  position: absolute;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #000;
  border-radius: 4px;
}
.hero-slide-wrapper.techex-landing-page .single-slide .hero-contents .btn__wrapper .theme-btn:last-child {
  background: transparent;
  border: 1px solid #FFF;
}
.hero-slide-wrapper.techex-landing-page .single-slide .hero-contents .btn__wrapper .theme-btn:last-child:before {
  background-color: #FFF;
}
.hero-slide-wrapper.techex-landing-page .single-slide .hero-contents .btn__wrapper .theme-btn:last-child:hover {
  color: #8A038C;
  border-color: #FFF;
}
.hero-slide-wrapper.techex-landing-page .single-slide .hero-contents .btn__wrapper .theme-btn:hover {
  border-color: #000;
  background: transparent;
}
.hero-slide-wrapper.techex-landing-page .single-slide .hero-contents .btn__wrapper .theme-btn:hover:before {
  opacity: 1;
  width: 100%;
  visibility: visible;
}
.hero-slide-wrapper.techex-landing-page .single-slide .hero-contents .btn__wrapper .theme-btn i {
  font-size: 12px;
}
.hero-slide-wrapper.techex-landing-page .owl-nav {
  width: 100%;
  position: absolute;
  top: calc(50% - 25px);
}
@media (max-width: 575px) {
  .hero-slide-wrapper.techex-landing-page .owl-nav {
    display: none;
  }
}
.hero-slide-wrapper.techex-landing-page .owl-nav .owl-prev, .hero-slide-wrapper.techex-landing-page .owl-nav .owl-next {
  width: 50px;
  height: 50px;
  color: #FFF;
  font-size: 12px;
  line-height: 41px;
  text-align: center;
  position: absolute;
  background: transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 1px solid #FFF;
  border-radius: 500px;
}
@media (max-width: 1199px) {
  .hero-slide-wrapper.techex-landing-page .owl-nav .owl-prev, .hero-slide-wrapper.techex-landing-page .owl-nav .owl-next {
    width: 40px;
    height: 40px;
    font-size: 10px;
    line-height: 33px;
  }
}
.hero-slide-wrapper.techex-landing-page .owl-nav .owl-prev:hover, .hero-slide-wrapper.techex-landing-page .owl-nav .owl-next:hover {
  background: #8A038C;
  border-color: #8A038C;
}
.hero-slide-wrapper.techex-landing-page .owl-nav .owl-prev {
  left: 80px;
}
@media (max-width: 1366px) {
  .hero-slide-wrapper.techex-landing-page .owl-nav .owl-prev {
    left: 50px;
  }
}
@media (max-width: 1199px) {
  .hero-slide-wrapper.techex-landing-page .owl-nav .owl-prev {
    left: 25px;
  }
}
@media (max-width: 991px) {
  .hero-slide-wrapper.techex-landing-page .owl-nav .owl-prev {
    left: 15px;
  }
}
@media (max-width: 767px) {
  .hero-slide-wrapper.techex-landing-page .owl-nav .owl-prev {
    left: 7px;
  }
}
.hero-slide-wrapper.techex-landing-page .owl-nav .owl-next {
  right: 80px;
}
@media (max-width: 1366px) {
  .hero-slide-wrapper.techex-landing-page .owl-nav .owl-next {
    right: 50px;
  }
}
@media (max-width: 1199px) {
  .hero-slide-wrapper.techex-landing-page .owl-nav .owl-next {
    right: 25px;
  }
}
@media (max-width: 991px) {
  .hero-slide-wrapper.techex-landing-page .owl-nav .owl-next {
    right: 15px;
  }
}
@media (max-width: 767px) {
  .hero-slide-wrapper.techex-landing-page .owl-nav .owl-next {
    right: 7px;
  }
}
.hero-slide-wrapper.techex-landing-page .owl-dots {
  width: 100%;
  bottom: 50px;
  position: absolute;
  display: block !important;
  margin: -7.5px !important;
}
@media (max-width: 1199px) {
  .hero-slide-wrapper.techex-landing-page .owl-dots {
    bottom: 40px;
  }
}
@media (max-width: 991px) {
  .hero-slide-wrapper.techex-landing-page .owl-dots {
    bottom: 30px;
  }
}
@media (max-width: 767px) {
  .hero-slide-wrapper.techex-landing-page .owl-dots {
    bottom: 20px;
  }
}
@media (max-width: 1366px) {
  .hero-slide-wrapper.techex-landing-page .owl-dots {
    margin: 30px -5px 0 !important;
  }
}
@media (max-width: 991px) {
  .hero-slide-wrapper.techex-landing-page .owl-dots {
    margin: 20px -3px 0 !important;
  }
}
.hero-slide-wrapper.techex-landing-page .owl-dots .owl-dot {
  width: 15px;
  height: 15px;
  margin: 0px 7.5px;
  line-height: 15px;
  position: relative;
  border-radius: 50%;
  border: 1px solid #FFF;
  border-color: transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1366px) {
  .hero-slide-wrapper.techex-landing-page .owl-dots .owl-dot {
    margin: 0 5px;
  }
}
@media (max-width: 1366px) {
  .hero-slide-wrapper.techex-landing-page .owl-dots .owl-dot {
    margin: 0 3px;
  }
}
.hero-slide-wrapper.techex-landing-page .owl-dots .owl-dot span {
  background-color: #FFF !important;
}
.hero-slide-wrapper.techex-landing-page .owl-dots .owl-dot:hover, .hero-slide-wrapper.techex-landing-page .owl-dots .owl-dot.active {
  border-color: #FFF;
}
.hero-slide-wrapper.techex-landing-page .owl-dots .owl-dot:hover span, .hero-slide-wrapper.techex-landing-page .owl-dots .owl-dot.active span {
  background-color: #8A038C !important;
}
.hero-slide-wrapper.techex-landing-page .owl-dots .owl-dot span {
  margin: 0;
  width: 9px;
  height: 9px;
  position: absolute;
  top: calc(50% - 4.5px);
  left: calc(50% - 4.5px);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: rgba(8, 106, 215, 0.3);
}

/* ----------------------------------
	Dark Mode Style Scss
 ------------------------------------ */
.body-wrapper.dark-1 {
  background: #000;
}
.body-wrapper.dark-1 .header-2 .header-right-cta {
  color: #FFF !important;
}
.body-wrapper.dark-1 .header-2 .header-right-cta a {
  color: #FFF !important;
}
.body-wrapper.dark-1 .header-2 .header-right-cta .lan-select select {
  color: #FFF !important;
}
.body-wrapper.dark-1 .header-3 .top-header {
  background: #000;
  color: #FFF;
}
.body-wrapper.dark-1 .header-3 .single-menu-box .icon i {
  color: #FFF;
}
.body-wrapper.dark-1 .header-3 .single-menu-box .content h3 {
  color: #FFF;
}
.body-wrapper.dark-1 .header-3 .single-menu-box .content span {
  color: #FFF;
}
.body-wrapper.dark-1 .header-5 .main-header-wraper {
  background-color: #000;
  border: none !important;
}
.body-wrapper.dark-1 .header-5 .main-header-wraper ul > li .sub-menu > li a {
  color: #000 !important;
}
.body-wrapper.dark-1 .header-5 .header-search a span {
  color: #FFF !important;
}
.body-wrapper.dark-1 .header-6 .main-header-wraper ul > li .sub-menu > li a {
  color: #000 !important;
}
.body-wrapper.dark-1 .stop .main-header-wraper {
  background: #000 !important;
  border: none !important;
}
.body-wrapper.dark-1 .logo {
  display: none !important;
}
.body-wrapper.dark-1 .logo-2 {
  display: block !important;
}
.body-wrapper.dark-1 .features-2 {
  background: #000 !important;
}
.body-wrapper.dark-1 .main-menu ul > li a {
  color: #FFF;
}
.body-wrapper.dark-1 .we-provide-solutions, .body-wrapper.dark-1 .client-brand-logo-wrap, .body-wrapper.dark-1 .project-case-study-wrapper, .body-wrapper.dark-1 .blog-section, .body-wrapper.dark-1 .cta-wrapper, .body-wrapper.dark-1 .hero-4 .single-slide, .body-wrapper.dark-1 .our-service-provide, .body-wrapper.dark-1 .our-service-wrapper, .body-wrapper.dark-1 .cta-banner {
  background-color: #000 !important;
}
.body-wrapper.dark-1 .hero-1 .hero-contents h2, .body-wrapper.dark-1 .hero-1 .hero-contents h1, .body-wrapper.dark-1 .hero-1 .hero-contents p, .body-wrapper.dark-1 .hero-1 .hero-contents a {
  color: #FFF !important;
}
.body-wrapper.dark-1 .hero-6 .banner-content h2 {
  color: #FFF !important;
}
.body-wrapper.dark-1 .hero-6 .banner-content span {
  -webkit-text-stroke: 1px rgba(253, 253, 254, 0.675);
}
.body-wrapper.dark-1 .blog-section h3 a {
  color: #000 !important;
}
.body-wrapper.dark-1 .blog-section .post-author a {
  color: #FFF !important;
}
.body-wrapper.dark-1 .blog-section h3 {
  color: #FFF !important;
}
.body-wrapper.dark-1 .contact_form__wrapper .contact__element .section__title_3 h6, .body-wrapper.dark-1 .contact_form__wrapper .contact__element .section__title_3 h2, .body-wrapper.dark-1 .contact_form__wrapper .contact__element .section__title_3 p {
  color: #000 !important;
}
.body-wrapper.dark-1 .contact_form__wrapper .contact__detels h2, .body-wrapper.dark-1 .contact_form__wrapper .contact__detels h4 {
  color: #FFF !important;
}
.body-wrapper.dark-1 .service__wrapper .support {
  position: relative !important;
  top: none;
  right: none;
}
.body-wrapper.dark-1 .service__wrapper .single-skill-bar h4, .body-wrapper.dark-1 .service__wrapper .single-skill-bar span {
  color: #FFF !important;
}
.body-wrapper.dark-1 .analytics-wrapper, .body-wrapper.dark-1 .agent__wrapper {
  background-image: none !important;
  background: #000;
}
.body-wrapper.dark-1 .prosess-element h4 {
  color: #FFF !important;
}
.body-wrapper.dark-1 .project-carousel-wrapper::after {
  display: none !important;
}
.body-wrapper.dark-1 .section-title span {
  color: #FFF !important;
}
.body-wrapper.dark-1 .section-title p {
  color: #FFF !important;
}
.body-wrapper.dark-1 .section-title h1 {
  color: #FFF !important;
}
.body-wrapper.dark-1 .section-title.section__title_3 h2, .body-wrapper.dark-1 .section-title.section__title_3 h6 {
  color: #FFF !important;
}
.body-wrapper.dark-1 .our-team-wrapper .off-white {
  border: 1px solid #efeff1;
}
.body-wrapper.dark-1 .our-team-wrapper .off-white:hover {
  border: 1px solid #efeff1 !important;
}
.body-wrapper.dark-1 .our-team-wrapper.section-bg-2 {
  background: #000;
}
.body-wrapper.dark-1 .our-team-wrapper.section-bg-2 .theme-btn {
  border: 1px solid #211e3b;
}
.body-wrapper.dark-1 .our-team-wrapper.section-bg-2 .theme-btn:hover {
  border: 1px solid #FFF;
}
.body-wrapper.dark-1 .about-us-wrapper .content h3 {
  color: #FFF;
}
.body-wrapper.dark-1 .about-us-wrapper .darkblue {
  color: #000b2c !important;
}

.body-wrapper.dark-1 .about-us-wrapper.dark .checked-list > li {
  color: #696969;
}
.body-wrapper.dark-1 .about-us-wrapper.dark .checked-list > li::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\f058";
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  font-size: 25px;
  color: #696969;
}
.body-wrapper.dark-1 .about-wrapper h2 {
  color: #FFF !important;
}
.body-wrapper.dark-1 .about-wrapper .icon-box-item .icon i {
  color: #FFF !important;
}
.body-wrapper.dark-1 .about-wrapper .icon-box-item h4 a {
  color: #FFF !important;
}
.body-wrapper.dark-1 .about-wrapper .single-rate-item h3 {
  color: #FFF;
}
.body-wrapper.dark-1 .our-process h3 {
  color: #FFF !important;
}
.body-wrapper.dark-1 .services-wrapper.service-3::before {
  background: #000 !important;
}
.body-wrapper.dark-1 .work-process-wrapper {
  background: #000 !important;
}
.body-wrapper.dark-1 .work-process-wrapper .content h3, .body-wrapper.dark-1 .work-process-wrapper .content span {
  color: #FFF;
}
.body-wrapper.dark-1 .testimonial-wrapper .single-testimonial h2, .body-wrapper.dark-1 .testimonial-wrapper .single-testimonial i, .body-wrapper.dark-1 .testimonial-wrapper .single-testimonial h3, .body-wrapper.dark-1 .testimonial-wrapper .single-testimonial p {
  color: #FFF;
}
.body-wrapper.dark-1 .testimonial-wrapper .single-testimonial i {
  color: #FFF;
}
.body-wrapper.dark-1 .testimonial-wrapper .testimonial-carousel-2 .single-testimonial h2, .body-wrapper.dark-1 .testimonial-wrapper .testimonial-carousel-2 .single-testimonial i, .body-wrapper.dark-1 .testimonial-wrapper .testimonial-carousel-2 .single-testimonial h3, .body-wrapper.dark-1 .testimonial-wrapper .testimonial-carousel-2 .single-testimonial p {
  color: #000;
}
.body-wrapper.dark-1 .testimonial-wrapper .testimonial-carousel-2 .single-testimonial i {
  color: #000;
}
.body-wrapper.dark-1 .case-study-carousel-wrapper::before, .body-wrapper.dark-1 .consultations-wrapper::before {
  background-color: #000;
}
.body-wrapper.dark-1 .faq-section, .body-wrapper.dark-1 .consultations-wrapper {
  background-color: #000;
}
.body-wrapper.dark-1 .consultations-wrapper h1 {
  color: #FFF;
}
.body-wrapper.dark-1 .consultations-wrapper .call-consultation .icon {
  color: #000;
}
.body-wrapper.dark-1 .consultations-wrapper .call-consultation h5 a {
  color: #FFF !important;
}
.body-wrapper.dark-1 .footer-wrap {
  background-color: #000 !important;
}
.body-wrapper.dark-1 .footer-wrap .footer-logo {
  display: none !important;
}
.body-wrapper.dark-1 .footer-wrap .footer-logo-2 {
  display: block !important;
}
.body-wrapper.dark-1 .footer-wrap .single-footer-wid h4 {
  color: #FFF !important;
}
.body-wrapper.dark-1 .footer-wrap .single-footer-wid p {
  color: #FFF !important;
}
.body-wrapper.dark-1 .footer-wrap .single-footer-wid ul > li a {
  color: #FFF !important;
}
.body-wrapper.dark-1 .footer-wrap .single-footer-wid ul > li a:hover {
  color: #8A038C !important;
}
.body-wrapper.dark-1 .footer-wrap .single-footer-wid .post-data span {
  color: #FFF !important;
}
.body-wrapper.dark-1 .footer-wrap .single-footer-wid .post-data a {
  color: #FFF !important;
}
.body-wrapper.dark-1 .footer-wrap .single-footer-wid .post-data a:hover {
  color: #8A038C !important;
}
.body-wrapper.dark-1 .footer-wrap .footer-bottom-content {
  color: #FFF !important;
}

.dark_mode {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  right: 80px !important;
  bottom: 3%;
  z-index: 3;
}
.dark_mode .checkbox {
  opacity: 0;
  position: absolute;
}
.dark_mode .checkbox-label {
  background-color: #8A038C;
  width: 65px;
  height: 30px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.dark_mode .checkbox-label .fa-moon {
  color: #f1c40f;
}
.dark_mode .checkbox-label .fa-sun {
  color: #f39c12;
}
.dark_mode .checkbox-label .ball {
  background-color: #fff;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear;
}
.dark_mode .checkbox:checked + .checkbox-label .ball {
  -webkit-transform: translateX(35px);
          transform: translateX(35px);
}

/*  Support me if you like it */
.support {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.support a {
  color: #292c35;
  font-size: 32px;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: inline-block;
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.support a:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.footer-1 {
  background-color: #fff;
}
.footer-1 .footer-widgets {
  position: relative;
  z-index: 1;
}

.footer-widgets {
  padding-top: 55px;
  padding-bottom: 100px;
}
@media (max-width: 767px) {
  .footer-widgets {
    padding-bottom: 80px;
    padding-top: 35px;
  }
}

.footer-2 {
  background: #f2f7fd;
  position: relative;
}
.footer-2::before {
  position: absolute;
  width: 100%;
  height: 140px;
  bottom: 100%;
  left: 0;
  content: "";
  background-color: #f2f7fd;
  z-index: -1;
}
.footer-2 .social-link a {
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 39px;
  border-radius: 50%;
  background-color: #dae9f9;
  margin: 0 4px;
  text-align: center;
  font-size: 14px;
}
.footer-2 .social-link a:hover {
  background-color: #8A038C;
  color: #fff;
}

.footer-logo {
  display: block;
}

.footer-logo-2 {
  display: none;
}

.footer-wrap .footer-bottom .footer-bottom-content {
  border-top: 1px solid #efeff1;
  padding: 30px 0px;
}
.footer-wrap .footer-bottom a {
  color: #8A038C;
}
.footer-wrap .single-footer-wid {
  margin-top: 40px;
}
.footer-wrap .single-footer-wid .wid-title {
  margin-bottom: 30px;
}
.footer-wrap .single-footer-wid.site_info_box {
  text-align: center;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  position: relative;
  margin-right: 40px;
  padding: 60px 40px;
  margin-top: -80px;
  z-index: 9;
}
.footer-wrap .single-footer-wid.site_info_box .f_logo {
  margin-bottom: 30px;
  display: block;
}
.footer-wrap .single-footer-wid.site_info_box::before {
  position: absolute;
  width: 100%;
  height: 6px;
  background-color: #ff5e14;
  content: "";
  top: 0;
  left: 0;
}
.footer-wrap .single-footer-wid.site_info_box p {
  color: #696969;
  font-size: 14px;
}
.footer-wrap .single-footer-wid.site_info_box .social_link {
  margin-top: 40px;
}
@media (max-width: 767px) {
  .footer-wrap .single-footer-wid.site_info_box .social_link {
    margin-top: 30px;
  }
}
.footer-wrap .single-footer-wid.site_info_box .social_link a {
  background-color: #211e3b;
  width: 40px;
  height: 40px;
  line-height: 41px;
  border-radius: 50px;
  font-size: 14px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-align: center;
  display: inline-block;
  color: #211e3b;
  margin-right: 5px;
}
.footer-wrap .single-footer-wid.site_info_box .social_link a:hover {
  color: #fff;
  background-color: #ff5e14;
}
.footer-wrap .single-footer-wid.site_info_widget .single-contact-info {
  overflow: auto;
  margin-bottom: 20px;
  border-bottom: 1px solid #282828;
  padding-bottom: 25px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.footer-wrap .single-footer-wid.site_info_widget .single-contact-info:last-child {
  border: 0;
}
.footer-wrap .single-footer-wid.site_info_widget .single-contact-info:hover .icon {
  background-color: #fff;
  color: #8A038C;
}
.footer-wrap .single-footer-wid.site_info_widget .single-contact-info p {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}
.footer-wrap .single-footer-wid.site_info_widget .single-contact-info span {
  display: block;
  color: #cacaca;
  font-size: 14px;
}
.footer-wrap .single-footer-wid.site_info_widget .single-contact-info .icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #000;
  background-color: #8A038C;
  float: left;
  text-align: center;
  overflow: hidden;
  font-size: 16px;
  margin-right: 20px;
  border-radius: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.footer-wrap .single-footer-wid .social_link {
  margin-top: 40px;
}
@media (max-width: 767px) {
  .footer-wrap .single-footer-wid .social_link {
    margin-top: 30px;
  }
}
.footer-wrap .single-footer-wid .social_link a {
  background-color: #0f0f0f;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 10px;
  font-size: 16px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-align: center;
  display: inline-block;
  color: #fff;
  margin-right: 5px;
}
.footer-wrap .single-footer-wid .social_link a:hover {
  color: #fff;
  background-color: #8A038C;
}
.footer-wrap .single-footer-wid ul li {
  position: relative;
  z-index: 1;
  padding-left: 20px;
}
.footer-wrap .single-footer-wid ul li::before {
  position: absolute;
  content: "\f101";
  left: 0;
  font-family: "Font Awesome 5 Pro";
}
.footer-wrap .single-footer-wid ul li > a {
  color: #696969;
  margin-bottom: 15px;
  display: block;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 500;
  text-transform: capitalize;
}
@media (max-width: 1191px) {
  .footer-wrap .single-footer-wid ul li > a {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .footer-wrap .single-footer-wid ul li > a {
    margin-bottom: 15px;
  }
}
.footer-wrap .single-footer-wid ul li > a:hover {
  color: #8A038C;
  padding-left: 10px;
}
.footer-wrap .single-footer-wid ul li:last-child a {
  margin-bottom: 0;
}

.footer-3 {
  position: relative;
  padding-top: 130px;
  margin-top: -130px;
  z-index: 1;
}
.footer-3 h1, .footer-3 h2, .footer-3 h3, .footer-3 h4 {
  color: #fff !important;
}
.footer-3 a, .footer-3 p, .footer-3 span {
  color: #b9b9bb !important;
}
.footer-3::before {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  content: "";
  background: #09071b;
  opacity: 0.94;
  z-index: -1;
}
.footer-3 .social-link a {
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 39px;
  border-radius: 50%;
  background-color: #dae9f9;
  margin: 0 4px;
  text-align: center;
  font-size: 14px;
  color: #8A038C !important;
}
.footer-3 .social-link a:hover {
  background-color: #8A038C;
  color: #fff !important;
}
.footer-3 .footer-bottom-content {
  border-top: 1px solid #1a192b !important;
}

.footer-4 {
  z-index: 1;
  padding-top: 145px;
  margin-top: -145px;
  position: relative;
  background-color: #211e3b;
}
.footer-4 h1, .footer-4 h2, .footer-4 h3, .footer-4 h4 {
  color: #fff !important;
}
.footer-4 a, .footer-4 p, .footer-4 span {
  color: #b9b9bb !important;
}
.footer-4 a:hover {
  color: #8A038C !important;
}
.footer-4 .social-link a {
  width: 30px;
  height: 30px;
  margin: 0 4px;
  font-size: 14px;
  line-height: 32.5px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  background-color: #dae9f9;
  color: #8A038C !important;
}
.footer-4 .social-link a:hover {
  color: #fff !important;
  background-color: #8A038C;
  -webkit-transform: translate(0, -5px);
  transform: translate(0, -5px);
}
.footer-4 .footer-bottom-content {
  padding: 20px 0 !important;
  border-top: 1px solid #252242 !important;
}
.footer-4 .footer-bottom-content a {
  color: #696969 !important;
}
.footer-4 .footer-bottom-content a:hover {
  color: #8DC63F !important;
}
.footer-4 .recent_post_widget .recent-post-list .single-recent-post .thumb {
  border-radius: 0;
}
.footer-4 .recent_post_widget .recent-post-list .single-recent-post .post-data a {
  display: block;
}
.footer-4 .single-footer-wid ul li > a {
  margin-bottom: 10px;
}
.footer-4 .footer-widgets {
  padding-top: 60px;
  padding-bottom: 70px;
}

.footer-menu ul li {
  display: inline-block;
}
.footer-menu ul li a {
  display: inline-block;
  margin-left: 40px;
}

.recent_post_widget .recent-post-list .single-recent-post {
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 25px;
}
.recent_post_widget .recent-post-list .single-recent-post:last-child {
  margin-bottom: 0;
}
.recent_post_widget .recent-post-list .single-recent-post .thumb {
  background-color: #eee;
  width: 80px;
  height: 80px;
  overflow: hidden;
  float: left;
  margin-right: 20px;
  border-radius: 7px;
}
.recent_post_widget .recent-post-list .single-recent-post .post-data {
  margin-top: -5px;
}
.recent_post_widget .recent-post-list .single-recent-post .post-data span {
  font-size: 14px;
  line-height: 1;
}
.recent_post_widget .recent-post-list .single-recent-post .post-data span i {
  margin-right: 5px;
}
.recent_post_widget .recent-post-list .single-recent-post .post-data h5 {
  letter-spacing: -1px;
}
.recent_post_widget .recent-post-list .single-recent-post .post-data h5:hover {
  color: #8A038C;
}

.newsletter_widget .newsletter_box form {
  height: 64px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 25px;
  margin-bottom: 10px;
}
.newsletter_widget .newsletter_box form input {
  width: 75%;
  position: relative;
  background: #f5f5f5;
  padding-left: 25px;
  padding-right: 15px;
  line-height: 1;
  color: #8A038C;
  border-radius: 7px 0px 0px 7px;
  font-size: 16px;
}
.newsletter_widget .newsletter_box form input::-webkit-input-placeholder {
  color: #696969;
  text-transform: capitalize;
}
.newsletter_widget .newsletter_box form input::-moz-placeholder {
  color: #696969;
  text-transform: capitalize;
}
.newsletter_widget .newsletter_box form input:-ms-input-placeholder {
  color: #696969;
  text-transform: capitalize;
}
.newsletter_widget .newsletter_box form input::-ms-input-placeholder {
  color: #696969;
  text-transform: capitalize;
}
.newsletter_widget .newsletter_box form input::placeholder {
  color: #696969;
  text-transform: capitalize;
}
.newsletter_widget .newsletter_box form button {
  width: 25%;
  height: 100%;
  background: #8A038C;
  color: #fff;
  display: inline-block;
  border-radius: 0px 10px 10px 0px;
  font-size: 22px;
  line-height: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.newsletter_widget .newsletter_box form button:hover {
  color: #000;
}
.newsletter_widget .newsletter_box span {
  color: #cacaca;
}

.contact_widget_2 .contact-us .single-contact-info {
  overflow: hidden;
  margin-bottom: 15px;
}
.contact_widget_2 .contact-us .single-contact-info:last-child {
  margin-bottom: 0;
}
.contact_widget_2 .contact-us .single-contact-info .icon {
  float: left;
  margin-right: 15px;
  overflow: hidden;
  color: #8A038C;
  font-size: 24px;
  padding-right: 5px;
}
.contact_widget_2 .contact-us .single-contact-info .contact-info {
  overflow: hidden;
}
.contact_widget_2 .contact-us .single-contact-info .contact-info p {
  margin-top: -5px;
}

.footer-7 {
  position: relative;
  z-index: 1;
}
.footer-7 .footer-widgets {
  padding-top: 300px !important;
  padding-bottom: 50px !important;
}
.footer-7 .single-footer-wid p {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 30px;
  color: #5F637A;
}
.footer-7 .single-footer-wid .social-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
}
.footer-7 .single-footer-wid .social-link a {
  width: 40px;
  height: 40px;
  border-radius: 500px;
  border: 1px solid rgba(211, 50, 101, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
}
.footer-7 .single-footer-wid .social-link a i {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(84, 23, 172, 0.4)), to(rgba(205, 48, 105, 0.4)));
  background: linear-gradient(180deg, rgba(84, 23, 172, 0.4) 0%, rgba(205, 48, 105, 0.4) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
.footer-7 .single-footer-wid .social-link a:hover {
  background: linear-gradient(132.63deg, #5116AD 15.86%, #E1345D 91.61%);
}
.footer-7 .single-footer-wid .social-link a:hover i {
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(252, 252, 252)), to(rgb(254, 254, 254)));
  background: linear-gradient(180deg, rgb(252, 252, 252) 0%, rgb(254, 254, 254) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
.footer-7 .single-footer-wid ul > li {
  margin: 10px 0px;
  padding-left: 20px !important;
}
.footer-7 .single-footer-wid ul > li::before {
  line-height: 1;
  bottom: 3px;
}
.footer-7 .single-footer-wid ul > li a {
  margin-left: 5px;
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  color: #6C7593;
}
.footer-7 .single-footer-wid ul > li a:hover {
  color: #6C7593 !important;
  padding-left: 0px !important;
  text-decoration: underline;
}
.footer-7 .single-footer-wid .address > li {
  margin: 10px 0px;
  padding-left: 0 !important;
}
.footer-7 .single-footer-wid .address > li::before {
  display: none !important;
}
.footer-7 .single-footer-wid .address > li strong {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #0E1871;
}
.footer-7 .single-footer-wid .address > li strong a {
  margin-left: 5px;
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  color: #6C7593;
}
.footer-7 .single-footer-wid .address > li strong a:hover {
  text-decoration: underline;
}
.footer-7 .single-footer-wid .wid-title h4 {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: #0E1871;
}
.footer-7 .footer-bottom-content {
  color: #5F637A;
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 17px;
}
.footer-7 .footer-bottom-content a {
  background: -webkit-gradient(linear, left top, right top, color-stop(19.92%, #5116AD), color-stop(41.21%, #E2345C));
  background: linear-gradient(90deg, #5116AD 19.92%, #E2345C 41.21%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
.footer-7 hr {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 1px;
  color: rgba(14, 24, 113, 0.1) !important;
}
.footer-7 .bootom_right a {
  color: #5F637A !important;
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 17px;
}
.footer-7 .bootom_right a:hover {
  text-decoration: underline;
}
.footer-7 .fotter_element {
  z-index: -1;
  position: absolute;
  bottom: 0;
  right: 0%;
}
.footer-7 .fotter_element_2 {
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0%;
}
.footer-7::after {
  top: 0;
  left: 0;
  z-index: -1;
  width: 100px;
  height: 100px;
  background: linear-gradient(195.24deg, #FB5155 -100.72%, #4212B4 153.05%);
  -webkit-filter: blur(150px);
          filter: blur(150px);
  content: "";
  position: absolute;
}
.footer-7::before {
  top: 0;
  right: 0%;
  z-index: -1;
  width: 100px;
  height: 100px;
  background: linear-gradient(195.24deg, #FB5155 -100.72%, #4212B4 153.05%);
  -webkit-filter: blur(150px);
          filter: blur(150px);
  content: "";
  position: absolute;
}

.footer_top {
  margin-bottom: -200px;
  position: relative;
  z-index: 5;
}
.footer_top .footer_top_widget {
  position: relative;
  border-radius: 10px;
}
.footer_top .footer_top_widget .content h2 {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  color: #FFFFFF;
  margin-bottom: 15px;
}
.footer_top .footer_top_widget .content p {
  font-family: "Syne", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 30px;
  text-align: center;
  color: #FFFFFF;
}
.footer_top .footer_top_widget .man_element {
  position: absolute;
  top: -10%;
  left: 0px;
}
.footer_top .newsletter_box {
  margin-top: 30px;
}
.footer_top .newsletter_box form {
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.footer_top .newsletter_box form input {
  width: 100%;
  margin-right: 10px;
  color: #FFF;
  font-size: 16px;
  line-height: 19px;
  background: transparent;
  padding: 0px 100px 0px 20px;
  border: 2px solid #D9D8E6;
  border-radius: 4px;
}
.footer_top .newsletter_box form input::-webkit-input-placeholder {
  color: #FFF;
}
.footer_top .newsletter_box form input::-moz-placeholder {
  color: #FFF;
}
.footer_top .newsletter_box form input:-ms-input-placeholder {
  color: #FFF;
}
.footer_top .newsletter_box form input::-ms-input-placeholder {
  color: #FFF;
}
.footer_top .newsletter_box form input::placeholder {
  color: #FFF;
}
.footer_top .newsletter_box form button {
  border: 0;
  right: 20px;
  padding: 12px 20px;
  font-family: "Syne", sans-serif;
  display: inline-block;
  position: absolute;
  border-radius: 2px;
  top: calc(40% - 17px);
  background-color: #0E1871;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.footer_top .newsletter_box form button i {
  font-size: 16px;
  line-height: 1;
  color: #FFF;
  font-weight: 500;
  margin-left: 0;
  padding-left: 0;
}
@media (max-width: 500px) {
  .footer_top .newsletter_box form button {
    padding: 10px !important;
  }
}
.footer_top .newsletter_box form button:hover {
  color: #FFF;
  background-color: #0E1871;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  max-width: 400px;
  width: 80%;
  text-align: center;
}
.modal-image {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

/* reg btn */
.btn {
  padding: 10px 20px;
  font-size: 16px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.btn-primary {
  background-color: #8A038C;
  color: #fff;
}

.btn-secondary {
  background-color: #6c757d;
  color: #fff;
}
